@import '../../assets/scss/variables.scss';
@import '../../assets/scss/components.scss';


.custom-swiper-4 {
  position: relative;

  padding-bottom: 100px;
  border-bottom: 1px solid $gray;

  @media (min-width: $xl) {
    max-width: 92.7vw !important;
    padding-bottom: 81px;
    
  }

  @media (min-width: $md) and (max-width: $lg) {
    margin-top: 100px;
    max-width: 86.5vw !important;
    padding-bottom: 100px;
    border-bottom: 1px solid $gray;
  }
  @media (min-width: $sm) and (max-width: $md) {
    margin-top: 81px;
    padding-bottom: 80px;
    max-width: 92vw !important;
  }
  @media (min-width: $xs) and (max-width: $sm) {
    margin-top: -0.5px;
    padding-bottom: 32px;

    width: 104%;
    max-width: 91.8vw !important;

  }
  @media (max-width: $xs) {
    top: -7px;
    width: 121%;
    max-width: 92vw !important;

    padding-bottom: 32px;
    margin-top: 39px;
  }

  .custom-slide {
    border-radius: 16px;
    overflow: hidden;

    @media (max-width: $xs) {
      height: 50.8vw;
    }


    img {
      // aspect-ratio: 1 / 1.25;
      object-fit: contain;

      height: 100%;
    }

    .card {
      @media (min-width: $xl) {
        height: 966px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        height: 42vw;
      }
      @media (min-width: $md) and (max-width: $lg) {
        // height: 560px;
        height: 38.9vw;
      }
      @media (min-width: 884px) and (max-width: $md) {
        // height: 560px;
        height: 54.5vw;
      }
      @media (min-width: $sm) and (max-width: 884px) {
        // height: 560px;
        height: 46.5vw;
      }
      @media (min-width: $xs) and (max-width: $sm) {
        // height: 356px;
        height: 46.5vw;
      }
      @media (max-width: $xs) {
        height: 184px;
      }

    }
  }
  
  .swiper-button-prev4, .swiper-button-next4 {
    @extend %swiperButtonInsideArrow;
  }

  .swiper-pagination-bullet {
    margin: 0 6px !important;
    // background-color: rgba(19, 19, 19, 1);
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    &.swiper-pagination-bullet-active {
      background-color: $green;
    }
  }
}

.swiper-button-prev4, .swiper-button-next4 {
  @extend %swiperButtonOutsideArrow;

  @media (min-width: $md) and (max-width: $lg) {
    top: 37.5%;
  }
  @media (min-width: $sm) and (max-width: $md) {
    top: 38.8%;
    width: 60px !important;
    height: 60px !important;
  }
}

.swiper-pagination4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2vw;
  bottom: -30px;

  @media (min-width: ($md - 1px)) {
    display: none;
  }
  @media (min-width: $xs) and (max-width: $sm) {
    margin-top: 4.3vw;
    margin-left: -1.8vw;
  }
  @media (max-width: $xs) {
    margin-top: 7vw;
    margin-left: -2vw;
  }
  .swiper-pagination-bullet {
    @media (max-width: $xs) {
      margin: 0 8px !important;
    }
  }
}

.swiper-button-prev4 {
  @extend %swiperButtonPrev;

  @media (min-width: $md) and (max-width: $lg) {
    left: -28px !important;
  }
}

.swiper-button-next4 {
  @extend %swiperButtonNext;
}
  