@use "../../assets/scss/vars" as *;
@use "../../assets/scss/components1";
@use "../../assets/scss/modules_try/mixins";

.about {
    margin-block: 200px;
    @media(max-width: 1440px) {
        margin-block: 100px;
    }
    @media(max-width: 1024px) {
        margin-block: 80px;
    }
    @media(max-width: 884px) {
        margin-block: 64px;
    }
    @media (max-width: 448px) {
        margin-block: 32px;
    }
    .cards {
        gap: 16px;

        &.inarow {
            --gap: 30px;

            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: end;

            gap: var(--gap);
            margin-inline: 100px;

            @media(max-width: $MYlg) {
                --gap: 10px;
            }
            @media(max-width: $MYmd) {
                margin-inline: 50px;
                justify-content: space-between;
            }
            @media(max-width: $MYsm) {
                --gap: 14px;
                flex-direction: row;
                justify-content: start;
                flex-wrap: wrap;
                margin-inline: 32px;
            }
            @media (max-width: $MYxs) {
                flex-direction: column;
                align-items: stretch;
                flex-wrap: nowrap;
                margin-inline: 16px;
            }

            .card {
                width: 100%;
                flex: 0 1 33%;

                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;
                gap: 50px;
                padding: 0;
                max-width: 567px;
                @media(max-width: $MYlg) {
                    max-width: 394px;
                    gap: 0;
                }
                @media (max-width: $MYmd) {
                    max-width: calc(32.9% - var(--gap) / 2);
                }
                @media (max-width: $MYsm) {
                    flex-basis: 50%;
                    max-width: calc(49.9% - var(--gap) / 2);
                    flex-direction: column;
                    justify-content: start;
                }
                @media (max-width: $MYxs) {
                    max-width: unset;
                    flex-basis: auto;
                }

                img {
                    border-radius: 20px;
                    width: 100%;
                    aspect-ratio: calc(567px / 334px);
                    object-fit: cover;
                    max-height: 334px;
                    @media(max-width: $MYlg) {
                        max-height: 191px;
                        aspect-ratio: calc(324px / 191px);
                        margin-block-end: 30px;
                    }
                    @media (max-width: $MYsm) {
                        width: 100%;
                        margin-block-end: 32px;
                    }
                }
                > p {
                    &[class] {
                        font-size: 64px;
                        margin-block: 0;
                        @media (min-width: calc($MYxl + 1px)) {
                            font-size: 128px;
                            margin-block: calc(50px - 32px);
                        }
                        @media (max-width: $MYlg) {
                            margin-block: 30px 18px;
                        }
                        @media (max-width: $MYmd) {
                            font-size: 48px;
                            margin-block: 20px 27px;
                        }
                        @media (max-width: $MYsm) {
                            margin-block: 32px 16px;
                        }
                    }
                    &:not([class]) {
                        margin-block: 0;
                        font-size: 24px;
                        @media (min-width: calc($MYxl + 1px)) {
                            font-size: 40px;
                        }
                        @media(max-width: $MYlg) {
                            font-size: 16px;
                        }
                        @media(max-width: $MYsm) {
                            font-size: 14px;
                            max-width: 253px;
                        }
                    }
                }
                .chips {
                    margin: 0;
                    @media (max-width: $MYsm) {
                        padding: 5px 10px;
                    }
                }
                a {
                    margin-block-start: 50px;
                    flex-grow: 1;
                    display: flex;
                    align-items: flex-end;
                    font-size: 32px;
                    text-decoration: none !important;

                    @media(max-width: $MYxl) {
                        font-size: 24px;
                    }
                    @media(max-width: $MYlg) {
                        margin-block-start: 30px;
                        font-size: 16px;
                    }
                    @media(max-width: $MYsm) {
                        margin-block-start: 27px;
                        width: 100%;
                    }
                    @media (max-width: $MYxs) {
                        margin-block-start: 16px;
                    }
                    p.arrow {
                        color: black;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        margin-block: 0;
                        @media (max-width: $MYsm) {
                            text-transform: uppercase;
                            color: green;
                            padding: 10px;
                            border: 1px solid var(--color-button-primary);
                            border-radius: 100px;
                        }
                        &:after {
                            content: "";
                            background: url("../../assets/img/link-arrow.svg") center / contain no-repeat;
                            width: 57px;
                            height: 57px;
                            flex-shrink: 0;
                            @media(max-width: 1440px) {
                                width: 30px;
                                height: 30px;
                            }
                            margin-inline-start: 50px;
                            @media(max-width: 1440px) {
                                margin-inline-start: 30px;
                            }
                            @media(max-width: 1024px) {}
                            @media(max-width: 884px) {
                                margin-inline-start: 10px;
                            }
                            @media(max-width: 768px) {}
                            @media (max-width: 448px) {}
                            @media(max-width: 376px) {}
                        }
                    }
                }
            }
        }

        &-container {
            display: flex;
            flex-direction: row;
            align-items: start;
            justify-content: space-between;
            @media(max-width: 1440px) {}
            @media(max-width: 1024px) {}
            @media(max-width: 884px) {
                flex-direction: column;
                align-items: stretch;
                justify-content: start;
            }
            @media (max-width: 488px) {}
            @media(max-width: 376px) {}
        }
    }
    .cards.inarow {
        @media (min-width: 884px) {
            justify-content: end;
            flex-wrap: nowrap;   
        }
        @media (max-width: 768px) {
            padding-bottom: 32px;
            border-bottom: 2px solid var(--color-separator);
        }

        .card {
            width: 25%;
            flex-direction: column;
            @media (max-width: 884px) {
                width: 48%;
                gap: 8px;
            }
            @media (max-width: 768px) {
                width: 100%;
            }
            img {
                width: 100%;
            }
        }
    }
}
