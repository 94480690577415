@use '../../../assets/scss/vars' as *;
@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/components.scss';

.custom-swiper-1 {
  position: relative;

  @media (min-width: $md) and (max-width: $lg) {
    // margin-top: 6px;
    margin-top: -50px;
  }
  @media (min-width: $sm) and (max-width: $md) {
    // margin-top: 5px;
    margin-top: -40px;
  }
  @media (max-width: $sm) {
    // width: 104%;
    // width: 101%;
    margin-top: -19px;
  }
  @media (max-width: $xs) {
    top: -7px;
  }

  .custom-slide {
    border-radius: 16px;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: contain;
      @media (max-width: $MYxs) {
        max-height: 362px;
        object-fit: cover;
      }
    }
    @media (max-width: $MYxs) {
      // width: calc(100% - 2 * 16px);
      height: max-content;
    }
  }

  //.swiper-button-next
  
  .swiper-button-prev2, .swiper-button-next2 {
    @extend %swiperButtonInsideArrow;
  }
}

.swiper-button-prev2, .swiper-button-next2 {
  @extend %swiperButtonOutsideArrow;
}

.swiper-button-prev2 {
  @extend %swiperButtonPrev;
}

.swiper-button-next2 {
  @extend %swiperButtonNext;
}
 
.swiper-pagination2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2vw;
  bottom: -30px;

  @media (min-width: ($md - 1px)) {
    display: none;
  }

  @media (min-width: $xs) and (max-width: $sm) {
    margin-top: 3.2vw;
    margin-left: -1.8vw;
  }
  @media (max-width: $xs) {
    margin-top: 8.59vw;
  }

}
.swiper-pagination-bullet {
  margin: 0 6px !important;
  background-color: rgba(19, 19, 19, 1);
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: $green;
}
