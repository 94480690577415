@import './variables.scss';

%advertisingBgHeight {
  @media (min-width: $xl) {
    height: 523px;
  }
  @media (min-width: $lg) and (max-width: $xl) {
    height: 523px;
  }
  @media (min-width: $md) and (max-width: $lg) {
    height: 300px;
  }
  @media (min-width: $sm) and (max-width: $md) {
    height: 272px;
  }
  // @media (min-width: $xs) and (max-width: $sm) {
  //   height: 255px;
  // }
  @media (min-width: ($sm - $sm/8)) and (max-width: $sm) {
    height: 255px;
    // height: 300px;
  }
  @media (min-width: ($sm - $sm/4)) and (max-width: ($sm - $sm/8)) {
    height: 300px;
  }
  @media (min-width: $xs) and (max-width: ($sm - $sm/4)) {
    height: 444px;
  }
  @media (max-width: $xs) {
    height: 444px;
  }
}

%link {
  display: flex;
  flex-direction: row;
  align-items: center;

  .link-text {
    font-family: 'Manrope';
    font-weight: 400;
    
    @media (min-width: $xl) {
      font-size: 40px;
      line-height: 52px;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      font-size: 28px;
      line-height: 36px;
    }
    @media (min-width: $sm) and (max-width: $lg) {
      font-size: 16px;
      line-height: 21px;
    }
    @media (max-width: $sm) {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
    }
  }

  svg {
    @media (min-width: $lg) and (max-width: $xl) {
      width: 45px;
      height: 45px;
    }
    @media (max-width: $lg) {
      width: 30px;
      height: 30px;
    }
  }
}