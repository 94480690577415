@use "../../../assets/scss/vars" as *;

%eventInfo {
    text-transform: uppercase;
    font: 24px/80% "Cakra", sans-serif;
    color: white;
    @media (max-width: $MYxl) {
        max-width: 50%;
    }
    @media (max-width: $MYlg) {
        max-width: unset;
    }
    @media (max-width: $MYsm) {
        padding: 0 32px;
    }
    @media (max-width: $MYxs) {
        font-size: 16px;
        color: black;
        padding-block-end: 16px;
    }
    p {
        margin-block: 10px;
    }
    [data-info="title"] {
        margin-block-start: 0;
        font-size: 24px; line-height: 100%;
        @media (max-width: $MYxs) {
            font-size: 16px;
        }
    }
    [data-info="constraints"] {
        color: #A6A6A6;
        font-size: 16px;
        @media (max-width: $MYxs) {
            font-size: 12px;
        }
    }
    pre {
        margin: 0;
        font: 600 16px/100% "Manrope";
        @media (max-width: $MYlg) {
            font-size: 14px;
        }
        @media (max-width: $MYsm) {
            font-size: 12px;
            margin-block-end: 1em;
        }
    }
    section {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1em;
        @media (max-width: 1440px) {
            flex-direction: column;
            justify-content: flex-start;
        }
    }
    button {
        margin-block-start: 50px;
        text-transform: inherit;
        @media (max-width: $MYxs) {
            margin-block-start: 0;
            width: 100%;
        }
    }
}
