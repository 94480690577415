.d-hidden {
    display: none !important;
}
.mobile-only {
    display: none !important;
    @media (max-width: 884px) {
        display: unset !important;
    }
}
.mobile {
    &-hidden {
        @media (max-width: 884px) {
            display: none !important;
        }
    }
    &-vertical-hidden {
        @media (max-width: 448px) {
            display: none !important;
        }
    }
}

.ant-modal-content:has(#menu-item-details, #order-rules) {
    max-width: 824px;
    padding: 0;
    margin-inline: auto;
    .ant-modal-body {
        padding: 0;
    }
}
