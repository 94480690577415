@use '../../assets/scss/vars' as *;
@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/components.scss';
@import '../../assets/scss/templates.scss';

.main {
  .gutsh-form-item-fix-uh38q3560 { // добрее надо быть
    #phone {
      min-width: unset;
    }
  }
  @media (max-width: $MYxxs) {
    margin-top: 32px;
  }
  @media (max-width: $xs) {
    padding-bottom: 32px;
  }

  .container {
    @extend %container;
  }

  .marquee {
    margin-block-end: 0;
  }

  .submenu {
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 4em;
    @media (min-width: $xl) {
      margin-inline: 134px;
      gap: 13px;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      margin-inline: 111px;
      gap: 12px;
    }
    @media (min-width: $md) and (max-width: $lg) {
      margin-inline: 89px;
      gap: 11px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      margin: 40px 0 0 49px;
      width: 160px;
      gap: 11px;
    }
    @media (min-width: $xs) and (max-width: $sm) {
      flex-wrap: wrap;
      max-width: 150px;
      gap: 4px;
    }
    @media (max-width: $xs) {
      margin-top: 0;
      gap: 3.5px;
    }
    @media (max-width: $xs) {
      display: flex;
      flex-direction: column;
    }
  
    .item {
      background-color: #131313;
      color: #FFFFFF;
      font-weight: 600;
      // height: 47px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: max-content;
      cursor: pointer;
  
      @media (min-width: $xl) {
        border-radius: 32px;
        font-size: 24px;
        padding: 8px 27px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        border-radius: 32px;
        font-size: 24px;
        padding: 7px 21px;
      }
      @media (min-width: $md) and (max-width: $lg) {  
        border-radius: 20px;
        font-size: 14px;
        padding: 6px 15px;
      }
      @media (min-width: $sm) and (max-width: $md) {
        border-radius: 20px;
        font-size: 14px;
        padding: 5px 15px;
      }
      @media (max-width: $sm) {
        border-radius: 20px;
        font-size: 12px;
        padding: 2px 11px;
      }
      @media (max-width: $xs) {
        padding: 2px 10.5px;
      }
    }
  }

  .area {
    height: max-content;
    width: 100%;

    @media (min-width: 2001px) {
      max-height: 1100px;
    }
    @media (max-width: 2000px) {
      max-height: 950px;
    }
    @media (max-width: $lg) {
      max-height: 600px;
    }
    @media (max-width: $md) {
      max-height: 500px;
    }
    @media (max-width: 768px) {
      min-height: 600px;
    }
    @media (max-width: 376px) {
      min-height: unset;
    }
  }

  .area2 {
    height: 454px;
    width: 100%;
    @include areaMedia(142px);
    @media (min-width: ($xl - $xl/8)) and (max-width: ($xl - $xl/40)) {
      height: 355px;
    }

    @media (min-width: $lg) and (max-width: ($xl - $xl/8)) {
      height: 300px;
    }


    @media (min-width: $sm) and (max-width: $md) {
      height: 120.4px;
    }
    @media (min-width: $xs) and (max-width: $sm) {
      height: 103.8px;
    }
    @media (max-width: $MYxs) {
      height: 0;
      // margin-top: -7px;
    }
  }

  .block {
    // margin-top: 100px;
    @include marginTopMedia(100px);
  }

  .margin-pomenshe {
    @include marginTopMedia(50px);
  }

  .wrapperColumns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .firstColumn {
      width: 25%;
      @media (max-width: $sm) {
        width: 50%;
      }
    }

    .secondColumn {
      width: 75%;
      @media (max-width: $sm) {
        width: 100%;
      }

      .ant-form {
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: -16px;

        @media (min-width: $md) and (max-width: $lg) {
          gap: 21px;
        }

        input {
          box-sizing: border-box;
          width: 100%;
        }

        .ant-form-item {
          height: 60px;
          margin-bottom: 0;

          @media (min-width: $xl) {
            height: 130px;
          }
          @media (min-width: $lg) and (max-width: $xl) {
            height: 95px;
          }
          @media (min-width: $md) and (max-width: $lg) {
            height: 60px;
          }


          .ant-checkbox-wrapper {
            margin: 24px 0;

            @media (min-width: $sm) and (max-width: $lg) {
              margin: 5px 0;
            }
            @media (max-width: $sm) {
              margin: 14px 0;
              max-width: 96%;
              line-height: 18.5px;
            }
            @media (max-width: $xs) {
              margin: 10px 0;
            }



            a {
              color: $green;
              font-style: italic;

              &:hover, &:active {
                color: $light-green;
              }
            }

            .ant-checkbox {

              &:hover {
                // border: 1px solid green;
                // outline: #41B64D;
              }

            }
            .ant-checkbox+span {
              // @media (max-width: $sm) {
              @media (min-width: $xs) and (max-width: $sm) {
                padding-inline-start: 14px;
              }
              @media (max-width: $xs) {
                padding-inline-start: 17px;
              }
            }

            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #41B64D;
              border-color: transparent;
              &:hover {
                background-color: #64c96e;
              }
            }

            .ant-checkbox-inner {
              // @media (min-width: $xs) and (max-width: $sm) {
              @media (max-width: $sm) {
                width: 20px;
                height: 20px;
              }
              // @media (max-width: $xs) {
              //   width: 18px;
              //   height: 18px;
              // }

            }
          }
        }

        .ant-btn {
          @extend %greenButton;

          @media (min-width: $sm) and (max-width: $lg) {
            width: 50%;
          }
        }

        .ant-input {
          @extend %formElement;
        }
        @extend %label;

        textarea {
          padding: 10px 15px;
        }
        @extend %select;

        //Checkbox
        @extend %checkbox;
      }
    }
  }

  .titleSection {
    @extend %title;
    margin-block: 0;
  }

  .white-button {
    .ant-btn {
      @extend %whiteButton;
    }
  }

  .block-with-cards {
    @media (min-width: $xl) {
      // margin-top: 185px;
      margin-top: 165px;
    }

    @media (min-width: $md) and (max-width: $lg) {
      margin-top: 97px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      margin-top: 76px;
    }

    @media (max-width: $sm) {
      border-top: 1px solid rgb(217, 217, 217);
    }
    @media (min-width: $xs) and (max-width: $sm) {
      // margin-top: 20px;
      margin-top: 33px;
      padding-top: 29px;  
    }
    @media (max-width: $xs) {
      // margin-top: 25px;
      margin-top: 6.6vw;
      padding-top: 7.6vw;
    }

    .firstColumn {
      @media (min-width: $md) and (max-width: $lg) {
        margin-top: -6px;
      }
      @media (min-width: $sm) and (max-width: $md) {
        margin-top: 16px;
      }
    }
  }

  .wrapperColumns {
    display: flex;
    flex-direction: row;

    @media (max-width: $sm) {
      flex-direction: column;
      gap: 30px
    }
    @media (max-width: $xs) {
      gap: 19px;
    }

    .firstColumn {
      width: 25%;

      @media (min-width: $xs) and (max-width: $sm) {
        width: 100%;
      }
    }

    .cards {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);
      gap: 4em 0;
      width: 75%;

      @media (max-width: $xl) {
        gap: 2em 0;
      }
      @media (max-width: $lg) {
        gap: 1em 0;
      }
      @media (max-width: $sm) {
        width: 100%;
        gap: 2em;
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        grid-template-rows: fit-content(40%);
      }
      @media (max-width: $xs) {
        margin-top: 11px;
      }
    }
  }

  .block-with-grid-images-1 {
    @media (max-width: $sm) {
      border-top: 1px solid rgb(217, 217, 217);
      margin-top: 31px;
      padding-top: 31px;
    }
    @media (min-width: $xs) and (max-width: $sm) {
      margin-top: 34px;
    }
  }

  .subscribe-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 15px;
    
    @media (min-width: $xl) {
      margin-top: 48px;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      margin-top: 40px;
    }
    @media (min-width: $md) and (max-width: $lg) {
      margin-top: 34px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      margin-top: 30px;
    }

    .subscribe {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      @media (min-width: $xl) {
        gap: 60px;
        margin-right: 9px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        gap: 44px;

      }
      @media (min-width: $md) and (max-width: $lg) {
        gap: 28px;
      }
      @media (max-width: $md) {
        gap: 30px;
      }
      @media (max-width: $xs) {
        margin-top: 11px;
      }

      .social {
        @extend %social;

        @media (max-width: $sm) {
          width: 30px;
          height: 30px;

          svg {
            width: 30px;
            height: 30px;
          }
        }
      }

      &-text {
        font-family: 'Manrope';
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;

        @media (min-width: $xl) {
          font-size: 32px;
          line-height: 42px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          font-size: 24px;
          line-height: 33px;
        }
        @media (min-width: $md) and (max-width: $lg) {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
  }

  .formWrapperColumn {
    flex-direction: row;
    @media (min-width: $xl) {
      margin-top: 185px;

      .secondColumn {
        .ant-form {
          gap: 42px;
          width: 100%;

          .ant-form-item {
            &:nth-child(5n+4) {
              margin-top: -32px;
            }
          }

          button {
            margin: -25px 0 0 0 !important;
            width: 50%;
          }
        }
      }
    }
    @media (min-width: $lg) and (max-width: $xl) {
      margin-top: 80px;
    }
    @media (max-width: $lg) {
      margin-top: 40px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      // margin-top: 97px;
      margin-top: 103px;
    }
    @media (max-width: $sm) {
      border-top: 1px solid rgb(217, 217, 217);

    }
    @media (min-width: $xs) and (max-width: $sm) {
      // margin-top: 29px;
      margin-top: 33px;
      padding-top: 54px;
    }
    @media (max-width: $MYsm) {
      margin-top: 27px;
      padding-top: 28px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
    }

    .firstColumn {
      @media (min-width: $md) and (max-width: $lg) {
        width: 24%;
      }

      @media (min-width: $sm) and (max-width: $md) {
        margin-top: -24px;
        width: 24%;
      }
      @media (max-width: $MYxxs) {
        width: auto;
      }
    }

    .secondColumn {
      width: 60%;
      margin-top: 20px;
      @media (min-width: 1441px) {
        margin-top: 45px;
      }
      @media (min-width: $sm) and (max-width: ($sm + $sm/4)) {
        width: 50%;
      }
      @media (max-width: $MYmd) {
        margin-top: 0;
      }
      @media (max-width: 768px) {
        margin-top: 20px;
        width: 100%;
      }
      @media (max-width: 576px) {
        margin-top: 0;
      }

      input {
        min-width: unset;
      }
      .ant-form {
        @media (min-width: $sm) and (max-width: $md) {
          gap: 21px;
        }
        @media (min-width: 575px) and (max-width: $sm) {
        // @media (min-width: $xs) and (max-width: $sm) {
          gap: 20px;

          //.main .ant-row .ant-form-item-control-input input 

          input {
            min-width: 200px;
          }
        }
        @media (max-width: $xs) {
          // margin-top: 8px !important;
          margin-top: 18px !important;
          gap: 31px !important;
        }
      }

      @media (max-width: 575px) {
        .ant-form {
          margin-top: 15px;
          gap: 35px;

          .ant-form-item {
            margin-bottom: -10px;
          }
        }
      }
    }
  }

  .flamingo-wrapper {
    left: 35.5vw;
    top: -4vw;

    @media (min-width: ($xxl - $xxl/28)) {
      top: -6.5vw;
      left: 43.5vw;
    }
    @media (min-width: ($xxl - $xxl/12)) and (max-width: ($xxl - $xxl/28)) {
      top: -6.5vw;
      left: 40.5vw;
    }
    @media (min-width: ($xxl - $xxl/8)) and (max-width: ($xxl - $xxl/12)) {
      top: -6.5vw;
      left: 37vw;
    }
    @media (min-width: ($xxl - $xxl/7)) and (max-width: ($xxl - $xxl/8)) {
      top: -7vw;
      left: 35vw;
    }
    @media (min-width: ($xxl - $xxl/5.5)) and (max-width: ($xxl - $xxl/7)) {
      top: -7.5vw;
      left: 33vw;
    }
    @media (min-width: $xl) and (max-width: ($xxl - $xxl/5.5)) {
      top: -8vw;
      left: 31vw;
    }

    @media (min-width: ($xl - $xl/8)) and (max-width: $xl) {
      top: -3vw;
      left: 34vw;
    }

    @media (min-width: ($xl - $xl/4)) and (max-width: ($xl - $xl/8)) {
      left: 29vw;
      top: -8vw;
    }

    @media (min-width: $lg) and (max-width: ($xl - $xl/4)) {//
      // left: 27.5vw;
      top: -8.5vw;
      left: 23.5vw;
    }
    @media (min-width: ($lg - $lg/8)) and (max-width: $lg) {
      left: 35.7vw;
      top: -7.7vw;

    }
    @media (min-width: ($lg - $lg/6)) and (max-width: ($lg - $lg/8)) {
      left: 27vw;
      top: -6vw;
    }
    @media (min-width: ($lg - $lg/4)) and (max-width: ($lg - $lg/6)) {
      left: 24vw;
      top: -7vw;
    }

    @media (min-width: $md) and (max-width: ($lg - $lg/4)) {//
      left: 19vw;
      top: -7vw;
    }


    @media (min-width: ($md - $md/14)) and (max-width: $md) {
      top: -50px;
      left: 38vw;
    }

    @media (min-width: ($md - $md/8)) and (max-width: ($md - $md/14)) {
      top: -50px;
      left: 34vw;
    }
    @media (min-width: ($md - $md/4)) and (max-width: ($md - $md/8)) {
      top: -60px;
      left: 29vw;
    }

    @media (min-width: $sm) and (max-width: ($md - $md/5)) {
      top: -60px;
      // left: 27vw;
      left: 24vw;

    }
    @media (min-width: ($sm - $sm/24)) and (max-width: $sm) {
      left: 41.2vw;
      top: -2.7vw !important;
    }
    @media (min-width: ($sm - $sm/8)) and (max-width: ($sm - $sm/24)) {
      left: 35.2vw;
      top: -2.7vw !important;
    }
    @media (min-width: ($sm - $sm/6)) and (max-width: ($sm - $sm/8)) {
      top: 0vw;
      left: 27vw;
    }

    @media (min-width: ($sm - $sm/4)) and (max-width: ($sm - $sm/6)) {
      top: 0vw;
      // left: 27vw;
      left: 24vw;
    }
    @media (min-width: ($sm - $sm/4 - $sm/8)) and (max-width: ($sm - $sm/4)) {
      top: -2vw;
      // left: 8vw;
      left: 17vw;
    }
    @media (min-width: ($sm - $sm/4 - $sm/8 - $sm/16)) and (max-width: ($sm - $sm/4 - $sm/8)) {
      top: -2vw;
      // left: -5vw;
      left: 2vw;

    }
    @media (min-width: $xs) and (max-width: ($sm - $sm/4 - $sm/8 - $sm/16)) {
      top: -2vw;
      // left: -10vw;
      left: 0;
    }
    @media (min-width: ($xs - $xs/8)) and (max-width: $xs) {
      top: -13.5vw;
      left: 0vw;
    }
    // @media (min-width: ($xs - $xs/4)) and (max-width: ($xs - $xs/8)) {
    @media (min-width: ($xs - $xs/4)) and (max-width: ($xs - $xs/8)) {
      // top: -19.5vw;
      top: -16.5vw;

      left: -10vw;
    }
    @media (max-width: ($xs - $xs/4)) {
      top: -19.5vw;
      left: -10vw;
    }

    
  }
  .flamingo-bg {
    @media (min-width: $xl) {
      width: 1267px;
      height: 1052.86px;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      width: 1000px;
      height: 833px;
      // width: 1267px;
      // height: 1052.86px;
    }
    @media (min-width: $md) and (max-width: $lg) {
      // width: 654.57px;
      // height: 605.98px;
      width: 745.57px;
      height: 623.98px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      width: 531.7px;
      height: 441.25px;
    }
    // @media (min-width: $xs) and (max-width: $sm) {
    @media (min-width: 450px) and (max-width: $sm) {
      width: 409.7px;
      height: 340px;
    }
    @media (min-width: $xs) and (max-width: 450px) {
      width: 409.7px*0.93;
      height: 340px*0.93;
    }
    @media (max-width: $xs) {
      // width: 435px;
      // height: 361px;
      // width: 95vw;
      // height: 78.838vw;
      width: 409.7px*1.058;
      height: 340px*1.058;
    }
    @media (max-width: ($xs - $xs/8)) {
      width: 116vw;
      height: 96.838vw;
    }



  }
  .all-background {
    background-color: rgb(134, 98, 98);
    top: -60px;

    @media (min-width: $xl) {
      left: 1.4vw;
    }
    @media (max-width: $xs) {
      top: -55px;
    }

    .leaf-wrapper {
      left: -80px;
      top: 100px;
      z-index: 10;

      @media (min-width: $xl) {
        // left: -115px;
        // top: 240px;

        left: -229px;
        top: 127px;
        transform: rotate(13deg);
      }

      @media (min-width: $lg) and (max-width: $xl) {
        left: -154px;
        top: 172px;
      }

      @media (min-width: $md) and (max-width: $lg) {
        left: -150px;
        top: 130px;
      }

      @media (min-width: $sm) and (max-width: $md) {
        top: 175px;
      }
      @media (min-width: ($sm - $sm/4)) and (max-width: $sm) {
        // top: 185px;
        // top: 100px !important;
        top: 100px;
      }
      @media (min-width: ($sm - $sm/4 - $xs/8)) and (max-width: ($sm - $sm/4)) {
        top: 195px;
        left: -90px;

      }
      @media (min-width: $xs) and (max-width: ($sm - $sm/4 - $xs/8)) {
        top: 235px;
        left: -100px;

      }
      // @media (max-width: $xs) {}

    }

    .leaf-bg {
      z-index: 100;

      @media (min-width: $xl) {
        width: 581px;
        height: 585px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        width: 581px;
        height: 585px;
      }
      @media (min-width: $md) and (max-width: $lg) {
        width: 344.95px;
        height: 347.2px;
      }
      @media (min-width: $sm) and (max-width: $md) {
        width: 201px;
        height: 202px;
      }
      @media (min-width: $xs) and (max-width: $sm) {
        width: 159px;
        height: 159px;
      }
      @media (max-width: $xs) {
        display: none;
      }
    }

    .titles {
      font-family: 'Cakra';
      z-index: 10;
      @media (min-width: $xl) {
        top: 21px;
      }

      @media (min-width: $lg) and (max-width: $xl) {
        top: 200px;
        left: 25px;
      }

      @media (min-width: $md) and (max-width: $lg) {
        top: 50px;
        left: -12px;
      }

      @media (min-width: $sm) and (max-width: $md) {
        // top: 142px;
        top: 90px;
        left: 0;
      }

      @media (min-width: ($sm - $sm/8)) and (max-width: $sm) {
        top: 112px;

      }
      @media (min-width: 475px) and (max-width: ($sm - $sm/8)) {
        top: 207px;
      }

      @media (min-width: $xs) and (max-width: 475px) {
        top: 255px;
      }

      @media (min-width: ($xs - $xs/8)) and (max-width: $xs) {
        top: 200px;
        top: 312px;
        // top: 275px;

      }
      @media (min-width: ($xs - $xs/4)) and (max-width: ($xs - $xs/8)) {
        // top: 200px;
        // top: 312px;
        top: 275px;
        top: 100vw;

      }
      
      @media (max-width: ($xs - $xs/4)) {
        // top: 165px;
        // top: 205px;
        // top: 250px;
        top: 95vw;

      }

      .title1 {
        color: $green;
        margin-top: -30px;

        @media (min-width: $sm) {
          color: $pink;
        }
      }
      .title2 {
        color: $green;
        margin-top: -135px;
        @media (min-width: $xl) {
          margin-top: -240px;
        }

        @media (min-width: $lg) and (max-width: $xl) {
          margin-top: -190px;
        }
        
        @media (min-width: $md) and (max-width: $lg) {
          margin-top: -144px;
        }
        
        @media (min-width: $sm) and (max-width: $md) {
          margin-top: -94px;
        }

        @media (min-width: 475px) and (max-width: $sm) {
          margin-top: -57px;
        }

        @media (max-width: 475px) {
          margin-top: -45px;
        }
        @media (max-width: $xs) {
          margin-top: -56px;

        }

        @media (max-width: ($xs - $xs/8)) {
          margin-top: -35px;
        }


      }
      .title1, .title2 {
        font-weight: 400; 
        text-transform: uppercase;

        @media (min-width: $xl) {
          font-size: 420px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          font-size: 315px;
        }
        @media (min-width: $md) and (max-width: $lg) {
          font-size: 250px;
        }
        @media (min-width: $sm) and (max-width: $md) {
          font-size: 150px;
        }
        @media (min-width: ($xs - $xs/8)) and (max-width: $sm) {
          font-size: 96px;
        }
        @media (min-width: ($xs - $xs/4)) and (max-width: ($xs - $xs/8)) {
          font-size: 72px;
        }
        @media (max-width: ($xs - $xs/4)) {
          font-size: 60px;
        }
      }
    }
  }

  .gridParent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px 10px;

    @media (min-width: $xl) {
      margin-top: 134px;
      gap: 25px 25px;
    }
    @media (min-width: $md) and (max-width: $lg) {
      margin-top: -28px;
      gap: 9px 9px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      margin-top: -55px;
      gap: 7px 10px;
    }
    @media (min-width: $xs) and (max-width: $sm) {
      gap: 16px 16px;
    }
    @media (max-width: $xs) {
      gap: 14px 14px;
    }
    
    @media (min-width: $xs) and (max-width: $sm) {
      grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    }
    @media (max-width: $xs) {
      grid-template-columns: repeat(2, 1fr);
    }

    .grid-image-wrapper {
      position: relative;
      overflow: hidden;
      padding-top: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }

    .gridChild {
      background-color: gray;
      border-radius: 20px;
      
      @media (max-width: $lg) {
        border-radius: 10px;
      }

    }

    .gridChild-0 {
      grid-column-start: span 2;
      grid-row-start: span 2;

      @media (max-width: $sm) {
        display: none;
      }
    }

    @media (max-width: $xs) {
      .gridChild-7, .gridChild-8 {
        display: none;
      }
    }
  }

  .link-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    @media (min-width: $xl) {
      gap: 30px;
    }
    // @media (min-width: $xs) and (max-width: 450px) {
    @media (min-width: 450px) and (max-width: $sm) {
      gap: 16px;
    }

    // @media (max-width: $xs) {
    @media (max-width: 450px) {

      flex-direction: column;
      gap: 25px;
    }

    @media (max-width: $xs) {
      gap: 43.5px;
    }
    .link-card {
      width: 100%;
      height: 25.5vw;
      // height: 26.5vw;
  
      // @media (max-width: $xs) {
      @media (max-width: 450px) {
        height: 80vw;
      }

      &-title {
        @media (min-width: ($lg + 1px)) and (max-width: $xl) {
          font-size: 82px;
        }

        @media (min-width: $md) and (max-width: ($lg - $lg/5)) {
          font-size: 52px;
        }
        @media (min-width: ($md - $md/5)) and (max-width: $md) {
          font-size: 48px;
        }
        @media (min-width: $sm) and (max-width: ($md - $md/5)) {
          font-size: 42px;
        }
      }
  
  
      .black-left-bg, .black-right-bg {
        width: 100%;
        border-radius: 10px;
        height: 25.5vw;
        // height: 26.5vw;

        @media (min-width: ($xl + $xl/4)) {
          height: 27.2vw;
        }
        // @media (max-width: $xs) {
        @media (max-width: 450px) {
          height: 54.16vw;
        }
      }
  
      .black-left-bg {
        background: linear-gradient(to left, rgba(24, 28, 38, 1),
        rgba(47, 54, 73, 1));
      }
      .black-right-bg {
        background: linear-gradient(to right, rgba(24, 28, 38, 1),
        rgba(47, 54, 73, 1));
        @media (max-width: 449px) {
          height: 52vw;
        }
        @media (max-width: 376px) {
          height: 56vw;
        }
      }
  
      .reserved-image {
        // top: 40px;
        top: 2.8vw;
        // @media (max-width: $xs) {
        @media (max-width: 450px) {
          top: 20px;
        }
  
        img {
          width: 44vw;
          // @media (max-width: $xs) {
          @media (max-width: 450px) {
            width: 100%;
          }
  
        }
      }
  
      .two-plates-image {
        img {
          // max-width: 1162px;
          width: 100%;
          aspect-ratio: 1162 / 916;
          max-height: 36vw;
          @media (max-width: 2500px) {
            max-height: 34vw;
          }
          @media (max-width: $MYlg) {
            width: auto;
            height: calc(1.8 * 25.5vw);
            object-fit: cover;
          }
          @media (max-width: 450px) {
            width: 100%;
            max-width: 100vw;
            max-height: none;
            height: 100%;
            min-height: 50vw;
            object-fit: contain;
          }
          @media (max-width: 448px) {
            width: 100%;
            height: 60vw;
            max-height: unset;
          }
          @media (max-width: 376px) {
            height: 65vw;
          }
        }
      }
  
      .link-card-body {
        z-index: 100;  
        top: 31.2vw;

        @media (min-width: $xl) {
          margin-top: 86px;
        }

        @media (min-width: $sm) and (max-width: $md) {
          top: 33.4vw;
        }
        @media (min-width: 450px) and (max-width: $sm) {
          top: 36.5vw;
        }

        // @media (max-width: $xs) {
        @media (max-width: 450px) {
          // top: 70.16vw;
          top: 64vw;
        }
  
        .link {
          @extend %link;

          margin-top: 27px;
          display: flex;
          flex-direction: row;
          gap: 30px;

          @media (min-width: $xl) {
            margin-top: 109px;
          }

          @media (min-width: $lg) and (max-width: $xl) {
            margin-top: 35px;
          }

          
          @media (min-width: $md) and (max-width: $lg) {
            margin-top: 25px;
          }

          @media (min-width: $sm) and (max-width: $md) {
            margin-top: 24px;
          }



          .go {
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: center;
            align-items: center;
            height: 40px;
            border: 1px solid transparent;
            border-radius: 30px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;


            &:hover, &:active {
              background-color: #daf9dd;
              color: black;
            }

            @media (min-width: $lg) and (max-width: $xl) {
              gap: 40px;
            }
            
            @media (min-width: $sm) and (max-width: $lg) {
              padding: 4px 0px;
              gap: 30px;
            }


          }
        }

        @media (max-width: $sm) {
          width: 100%;

          .titleSection {
            font-family: 'Manrope';
            background-color: #41B64D;
            color: white;
            border: 1px solid transparent;
            border-radius: 100px;
            height: 52px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-family: 'Manrope';
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            text-transform: uppercase;
            width: 100%;
          
            &:hover {
              background-color: #64c96e;
              border: 1px solid transparent;
            }
          }

          .link-hidden {
            display: none;
          }
        }
      }

      .link-card-body-first {
        // @media (max-width: $xs) {
        @media (max-width: 450px) {

          top: 60vw;
        }
        @media (max-width: $xs) {
          top: 69.8vw;
        }

      }
    }
  }
  .mobile-apps-wrapper {
    margin-block-start: 40px;
    margin-inline: 100px;
    @media(max-width: $MYlg) {
      margin-block-start: 30px;
    }
    @media(max-width: $MYmd) {
      margin-block-start: 20px;
      margin-inline: 50px;
    }
    @media(max-width: $MYsm) {
      margin-block-start: 32px;
      margin-inline: 32px;
    }
    @media(max-width: $MYxs) {
      margin-inline: 16px;
    }
    @media(max-width: 376px) {
      max-height: 444px;
    }
    img {
      display: block;
      width: 100%;
      @media(max-width: 376px) {
        height: 100%; aspect-ratio: 343 / 444;
      }
    }
  }
  .ant-select {
    @extend %formElement;
    &-selection-item {
      @media (min-width: 2000px) {
        line-height: 100% !important;
      }
    }
    &:not(.ant-select-disabled) {
      .ant-select-selector {
        background: transparent !important;
      }
    }
  }
}

[data-id="phone-try"] {
  @extend %prefixPhone;
  #phone {
    border-bottom-left-radius: 0;
    border-left: none;
  }
}

#video {
  width: 100%; border: none;
  height: 900px;
  @media(max-width: $MYxxl) {
    height: 760px;
  }
  @media(max-width: $MYxl) {
    height: 600px;
  }
  @media(max-width: $MYlg) {
    height: 480px;
  }
  @media(max-width: $MYmd) {
    height: 360px;
  }
}
