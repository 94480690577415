@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/components.scss';

.counter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @extend %formElement;
  padding: 0 10px;
  width: 100%;

  @media (min-width: $xl) {
    font-size: 32px;
    padding: 0px 45px;
  }
  @media (min-width: $lg) and (max-width: $xl) {
    font-size: 24px;
    padding: 0px 15px;
  }
  @media (max-width: $md) {
    font-size: 20px;
  }

  @media (min-width: $sm) and (max-width: $md) {
    padding: 0 22px;
  }
  @media (min-width: $xs) and (max-width: $sm) {
    padding: 0 18px;
  }
  @media (max-width: $xs) {
    padding: 0 19px;
  }

  .number {
    width: 20px;
    margin: 0 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: $md) and (max-width: $lg) {

      font-size: 20px;
    }
  }

  .whiteButton {
    .ant-btn {
      @extend %whiteButton;
      height: 32px;
      width: 30px;
    }
  }

  .reduce, .increase {
    .ant-btn {
      border-radius: 200px;
      border: 1px solid $green;

      // @media (min-width: $lg) and (max-width: $xl) {}
      
      @media (min-width: $md) and (max-width: $lg) {
        
        padding: 4px 15px;
        margin: 0 8px;

      }
      @media (max-width: $md) {
        padding: 0 ;
      }


      
      span {
        margin: 0 -4px;
        //
      }
    }


    @media (min-width: $xl) {
      // font-size: 32px !important;
      // padding: 30px 30px !important;
      width: 66px;
      height: 66px;

      .ant-btn {
        width: inherit;
        height: inherit;
      }
    }
    @media (min-width: $lg) and (max-width: $xl) {
      width: 44px;
      height: 44px;


      .ant-btn {
        width: inherit;
        height: inherit;
      }
    }
  }

  .disabled {
    position: relative;

    .ant-btn {
      opacity: 0.3;
    }
  }

  .disabled-area-show {
    position: absolute;
    width: 32px;
    height: 32px;
    z-index: 10;

    @media (min-width: $xl) {
      width: 66px;
      height: 66px;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      width: 44px;
      height: 44px;
    }
  }
}