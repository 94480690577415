@use '../../../assets/scss/vars' as *;
@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/components.scss';

.header {
  z-index: 100;
  position: sticky;
  top: 0;
  @media (max-width: $MYxxs) {
    z-index: 100;
  }
  .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    // width: max-content;
    padding: 0 25px;
    margin-block: 23px 5px;
  
    border-radius: 72px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px 0px #000a, 0px 0px 0px 4px transparent;

    margin-inline: 100px;
    height: max-content;
    padding-block: .5em;
  
    @media (min-width: $xl) {
      padding-inline: 70px;
    }
    @media (min-width: ($lg + $lg/8)) and (max-width: $xl) {
      padding-inline: 48px;
    }
    @media (min-width: $lg) and (max-width: ($lg + $lg/8)) {
      padding-inline: 43px;
    }
    @media (max-width: $lg) {
      padding-block: .5em;
      padding-inline: 42px;
      margin-inline: 60px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      margin-inline: 50px;
      padding-inline: 29px;
    }
    @media (max-width: $sm) {
      padding-inline: 16px;
      margin: 16px 16px -4px;
    }

    .other-left {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 10px;

      @media (min-width: $xl) {
        gap: 45px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        gap: 20px;
      }
      @media (min-width: $md) and (max-width: $lg) {
        gap: 20px;
      }
      @media (min-width: $xs) and (max-width: $sm) {
        padding-left: 8px;
        gap: 20px;
      }

      @media (max-width: 476px) {
        width: 80%;
      }

      .header-select-wrapper {
        max-width: 245px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media (min-width: ($xl + $xl/6)) {
          // width: max-content;
          max-width: 100%;
        }
        @media (max-width: 2570px) {
          max-width: 500px;
        }
        @media (max-width: 2510px) {
          max-width: 400px;
        }
        @media (max-width: 2400px) {
          max-width: 300px;
        }
        @media (min-width: 1261px) and (max-width: 1600px) {
          max-width: 200px;
        }
        @media (max-width: 1260px) {
          max-width: 216px;
        }
        @media (min-width: ($md - $md/8)) and (max-width: ($md - $md/14)) {
          max-width: 196px;
        }
        // @media (min-width: 840px) and (max-width: ($md - $md/8)) {
        @media (min-width: ($md - $md/5)) and (max-width: ($md - $md/8)) {
          max-width: 160px;
        }
        @media (min-width: $sm) and (max-width: ($md - $md/5)) {
        // @media (min-width: $sm) and (max-width: ($md - $md/5)) {
          max-width: 248px;
        }
        @media (min-width: $xs) and (max-width: $sm) {
          max-width: 255px;
        }

        // @media (min-width: ($sm - $sm/8)) and (max-width: $sm) {}
        @media (min-width: ($sm - $sm/8)) and (max-width: ($sm - $sm/14)) {
          max-width: 220px;
        }
        @media (min-width: ($sm - $sm/5)) and (max-width: ($sm - $sm/8)) {
          max-width: 180px;
        }

        @media (max-width: 476px) {
          margin-inline-start: auto;
        }

        :global(.ant-select) {
          max-width: 100%;
          min-width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 476px) {
            display: none;
          }

          :global(.ant-select-focused) {
            .ant-select-selector {
              background-color: white !important;
              box-shadow: 0 0 0 2px rgb(38 173 48 / 15%) !important;
            }
          }
  
          :global(.ant-select-selector) {
            background-color: rgba(250, 250, 250, 1);
            height: 40px;
            border-radius: 40px;
            font-family: 'Manrope';
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            padding: 0 23px;

            @media (min-width: $xl) {
              height: 72px;
              border-radius: 71.82px;
              line-height: 24px;
              padding: 0 33px;
            }
            @media (min-width: ($xl + $xl/8)) {
              font-size: 24px;
            }
            @media (min-width: ($xl + $xl/16)) and (max-width: ($xl + $xl/8)) {
              font-size: 23px;
            }
            @media (min-width: $xl) and (max-width: ($xl + $xl/16)) {
              font-size: 21px;
            }
            @media (min-width: ($lg + $lg/4)) and (max-width: $xl) {
              font-size: 19px;
              line-height: 19px;
            }
            @media (min-width: ($lg + $lg/8)) and (max-width: ($lg + $lg/4)) {
              font-size: 18px;
              line-height: 18px;
            }
            @media (min-width: $lg) and (max-width: ($lg + $lg/8)) {
              font-size: 16px;
              line-height: 16px;
            }
            @media (min-width: $md) and (max-width: $lg) {
              // width: 260px;
            }
            @media (min-width: $sm) and (max-width: $md) {
              // width: 252px
            }
            @media (min-width: $xs) and (max-width: $sm) {
              // width: 252px;
            }
  
            .ant-select-selection-search {
              display: flex;
              justify-content: center;
              align-items: center;
            }
  
          }
  
          :global(.ant-select-arrow) {
            margin-top: -4px;
            inset-inline-end: 7px;

            @media (min-width: ($xl + $xl/8)) {
              // inset-inline-end: 48px;
              inset-inline-end: 27px;

              height: 13px;
              margin-top: -4px;
            }

            @media (min-width: $xl) and (max-width: ($xl + $xl/6)) {
              inset-inline-end: 11px;
              height: 13px;
              margin-top: -4px;
            }
            @media (min-width: $lg) and (max-width: $xl) {
              // inset-inline-end: 34px;
              inset-inline-end: 10px;

              height: 13px;
              margin-top: -5px;
            }
            @media (min-width: $md) and (max-width: $lg) {
              // inset-inline-end: 19px;
              height: 13px;
              // margin-top: -7px;

              inset-inline-end: 9px;
              margin-top: -5px;
            }
            @media (min-width: $xs) and (max-width: $sm) {
              inset-inline-end: 14px;
            }
            @media (min-width: ($sm - $sm/5)) and (max-width: ($sm - $sm/16)) {
              inset-inline-end: 9px;
            }

            // @media (max-width: $xs) {}

            svg {

              @media (min-width: $xl) {
                width: 20px;
                height: 20px;
              }
          
            }
          }
  
          :global(.ant-select-selection-item) {
            line-height: 14px;
            display: flex;
            align-items: center;

            @media (min-width: $xl) {
              line-height: 26px;
            }
            @media (min-width: $lg) and (max-width: $xl) {
              line-height: 21px;
            }
          }
        }

        .mob {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: end;
          gap: 10px;

          padding: 4px 8px;

          border-radius: 10px;
          border: 1px solid gray;
          background-color: rgba(250, 250, 250, 1);
          box-shadow: 0 0 0 2px rgb(38 173 48 / 15%);

          span {
            font-size: 12px;
          }
          svg {
            width: 32px; height: 32px;
          }
          @media (min-width: 477px) {
            display: none;
          }
        }
      }
    }
  
    .logo {
      // @media (min-width: $xl) {}
      // @media (min-width: $lg) and (max-width: $xl) {}
      @media (min-width: $sm) and (max-width: $lg) {
        svg {
          width: 83px;
          height: 30px;
        }
      }
      @media (max-width: $sm) {
        svg {
          width: 89px;
          height: 32px;
        }
      }
      @media (min-width: $xs) and (max-width: $sm) {
        padding-left: 8px;
      }

    }
    .other-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      // @media (min-width: $xl) {
      @media (min-width: $md) {
        gap: 33px;
      }
      @media (min-width: ($lg - $lg/8)) and (max-width: ($lg - $lg/14)) {
        gap: 17px;
      }
      @media (min-width: $sm) and (max-width: $md) {
        gap: 21px;
      }
      @media (min-width: $sm) and (max-width: ($md - $md/14)) {
        gap: 15px;
      }
      @media (min-width: $xs) and (max-width: $sm) {
        // gap: 14px;
        // margin-left: 13px;
        gap: 5px;
        margin-left: -15px;
      }

      .phone {
        font-weight: 600;
        width: max-content;
        margin-left: 8px;

        @media (min-width: $xl) {
          font-size: 32px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          font-size: 20px;
        }
        @media (min-width: $sm) and (max-width: $lg) {
          font-size: 16px;
        }
        @media (min-width: $sm) and (max-width: ($md - $md/14)) {
          font-size: 14px;
        }

        @media (min-width: $xs) and (max-width: $sm) {
          margin-left: 0;
        }        
      }
      .socials {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 4px;

        @media (min-width: $xl) {
          gap: 15px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          gap: 12px
        }

        @media (min-width: $md) and (max-width: $lg) {
          gap: 10px
        }
        @media (min-width: $sm) and (max-width: $md) {
          gap: 10px;
        }
  
        .social {
          @extend %social;
          @media (max-width: $MYxl) {
            svg {
              width: 30px !important;
              height: 30px !important;
            }
          }
          
          @media (max-width: $xs) {
            display: none;
          }
        }
      }
    }
  
    .menuButton {
      padding: 10px;
      border-radius: 15px;
  
      // @media (min-width: $sm) {
      @media (min-width: ($md - $md/5)) {
        display: none;
      }
      // @media (max-width: $sm) {
      @media (max-width: ($md - $md/5)) {

        &:hover {
          background-color: #f5f5f5;
          transition: all 0.3s ease-out;
        }
  
      }
    }
  
    .menu-links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1.8%;
  
      @media (min-width: ($xxl - $xxl/8)) {
        // padding-right: 228px;
        // gap: 5.8%;
        // gap: 3.8%;
        padding-right: 88px;
        gap: 3.2%;
      }
  
      @media (min-width: $xl) and (max-width: ($xxl - $xxl/8)) {
        padding-right: 135px;
        gap: 3%;
      }
  
      @media (max-width: $xl) {
        padding-right: 60px;
        gap: 3.5%;
      }
      @media (max-width: $lg) {
        padding-right: 42px;
        // gap: 1.8%;
        gap: 2.5%;
      }

      @media (min-width: $sm) and (max-width: ($lg - $lg/8)) {
        display: grid;
        // grid-template-columns: repeat(3, 1fr);
        grid-template-columns: 2fr 3fr 3fr;

        grid-template-rows: repeat(2, 2fr);
        // gap: 20% 2%;
        margin-right: 3px;
        padding-right: 0px;
        
        margin-top: -4px;
        gap: 12px 0px;
        margin-left: 12px;

        > .link {
          justify-content: center;
          // align-self: center;
          justify-self: center;
  
          &:nth-child(3n + 1) {
            justify-self: start;
            // padding-left: 26px;
            padding-left: 8px;
          }
        }
      }
      @media (min-width: $sm) and (max-width: ($md - $md/14)) {
        margin-left: 0;
      }

      // @media (max-width: $sm) {
      @media (max-width: ($md - $md/5)) {
        display: none;
      }
      :global(.gutsh-dd) {
        left: -.25em;
        @media (max-width: $MYlg) {
          top: -3px;
        }
        @media (max-width: 1300px) {
          top: -4px;
          left: 1em;
        }
      }
    }
  
    .link {
      @extend %link;
      @media (min-width: ($xl + $xl/8)) {
        font-size: 29px;
      }
      @media (min-width: ($xl + $xl/16)) and (max-width: ($xl + $xl/8)) {
        font-size: 26px;
      }
      @media (min-width: $xl) and (max-width: ($xl + $xl/16)) {
        font-size: 23px;
      }
      @media (min-width: ($lg + $lg/8)) and (max-width: ($lg + $lg/4)) {
        font-size: 18px;
      }
      @media (min-width: $lg) and (max-width: ($lg + $lg/8)) {
        font-size: 16px;
      }
      // @media (min-width: $lg) and (max-width: $xl) {}
      // @media (min-width: $lg) and (max-width: $xl) {}
      @media (min-width: $md) and (max-width: ($lg - $lg/8)) {
        font-size: 14px;
      }
    }
  
    .header__navLinkActive {
      color: $green !important;
    }
  }
}
.header-custom-option {
  display: flex;
  flex-direction: row;
  gap: 5px;

  span {
    &:last-child {
      color: $gray;
    }
  }
}

:global(.ant-select) {
  :global(.ant-select-selector) {
    color: #FAFAFA !important;
    border: 1px solid transparent !important;
    color: black !important;

    @media (min-width: $xs) and (max-width: $sm) {
      // padding: 0 16px;
      padding: 0px 22px 0 15px;
    }

  }
}

:global(.ant-menu) {
  :global(.ant-menu-item) {
    @media (min-width: $sm) and (max-width: $md) {
      padding-inline: 8px !important;
    }
    @media (min-width: $xs) and (max-width: $sm) {
      padding-inline: 5px !important;
    }
// @media (max-width: $xs) {}
  }
  :global(.ant-menu-title-content) {
    font-weight: 500;

    @media (min-width: 2000px) {
      font-size: 32px;
    }

    @media (min-width: 1440px) and (max-width: 2000px) {
      font-size: 24px;
    }
    @media (min-width: 1024px) and (max-width: 1440px) {
      font-size: 16px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 14px;
    }
    // @media (min-width: 375px) and (min-width: 768px) {

    // }

  }
}

// это типа .inner
[data-inner="true"] { // чтобы прокидывать стили для футера
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: $sm) {
    flex-direction: column;
    gap: 7px;
  }
  .logo {
    svg {
      @media (min-width: $xl) {
        width: 169px;
        height: 61px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        width: 169px*0.75;
        height: 61px*0.75;
      }
      @media (min-width: $sm) and (max-width: $lg) {
        // width: 83px;
        // height: 30px;
        width: 105px;
        height: 38px;
      }
      @media (max-width: $sm) {
        width: 89px;
        height: 32px;
      }
    }
    @media (max-width: $sm) {
      display: none;
    }
  }
  .other-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  
    @media (min-width: $xl) {
      gap: 60px;
    }
    @media (min-width: $xs) and (max-width: $sm) {
      width: 100%;
      justify-content: space-between;
  
      .socials {
        gap: 10px;
      }
    }
    // @media (max-width: $xs) {
    @media (max-width: 475px) {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 100%;
      margin-top: 15px;
  
      .socials {
        gap: 10px;
      }
    }
    @media (max-width: $xs) {
      margin-top: 22px;
      gap: 27px;
  
      .socials {
        margin-left: 3px;
        gap: 16px !important;
      }
    }
    .phone {
      font-weight: 600;
      width: max-content;
      margin-left: 3px;
  
      @media (min-width: $xl) {
        font-size: 32px;
      }
  
      @media (min-width: $lg) and (max-width: $xl) {
        font-size: 24px;
      }
      @media (min-width: $sm) and (max-width: $lg) {
        font-size: 16px;
      }
      @media (max-width: $sm) {
        font-size: 32px;
        font-weight: 600;
        line-height: 32px;
  
        margin-left: 0;
        margin-top: -7px;
      }
    }
    .socials {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 3px;
      
      @media (min-width: $xl) {
        gap: 20px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        gap: 15px;
      }
      @media (min-width: $sm) and (max-width: $lg) {
        gap: 10px;
      }
      @media (min-width: $xs) and (max-width: $sm) {
        gap: 16px
      }
  
      .social {
        @extend %social;
        @media (max-width: $sm) {
          width: 64px;
          height: 64px;
        }
  
        svg {
          @media (min-width: $xl) {
            width: 60px;
            height: 60px;
          }
  
          @media (max-width: $sm) {
            width: 64px;
            height: 64px;
          }
        }
      }
    }
  }
  .menu-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.8%;
  
    @media (min-width: ($xxl - $xxl/8)) {
      // padding-right: 228px;
      // margin-left: auto;
      gap: 6.5%;
    }
    @media (min-width: $xl) and (max-width: ($xxl - $xxl/8)) {
      padding-right: 135px;
      gap: 3%;
    }
    @media (min-width: ($xl - $xl/8)) and (max-width: $xl) {
      padding-right: 60px;
      gap: 5.5%;
    }
    @media (min-width: $lg) and (max-width: ($xl - $xl/8)) {
      padding-right: 60px;
      gap: 2.5%;
    }
    @media (min-width: $md) and (max-width: $lg) {
      // padding-right: 42px;
      // margin-left: 23vw;
      padding-right: 0;
      gap: 5%;
    }
    @media (min-width: $sm) and (max-width: $md) {
      margin-left: -27px;
      gap: 9%;
    }
    @media (max-width: $sm) {
      // display: none;
      flex-direction: column;
      align-items: start;
      // gap: 20px;
      gap: 22px;
      width: 100%;
    }
    // @media (min-width: $xs) and (max-width: $sm) {}
    //    gap: 22px;
  }
  .link {
    font-family: 'Manrope';
    font-weight: 500;
    color: rgba(19, 19, 19, 1);
    text-decoration: none;
    transition: all 0.4s ease-out;
  
    @media (min-width: $xl) {
      font-size: 32px;
      line-height: 32px;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      font-size: 24px;
      line-height: 24px;
    }
    @media (min-width: $md) and (max-width: $lg) {
      font-size: 16px;
      line-height: 16px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      font-size: 14px;
      line-height: 14px;
    }
  
    &:hover {
      color: $green;
    }
  }
}

// @media (min-width: 2000px) {}
// @media (min-width: 1440px) and (max-width: 2000px) {}
// @media (min-width: 1024px) and (max-width: 1440px) {}
// @media (min-width: 768px) and (max-width: 1024px) {}
// @media (min-width: 375px) and (min-width: 768px) {}

// модал для zузких
dialog {
	border: none;
	background-color: #FFF;
  &.menu-mobile {
    // min-width: 342px;
    border-radius: 20px;
    
    top: 0; bottom: 0;
    left: 0; right: 0;
    margin-block: 16px auto;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 36px 80px 0px rgba(0, 0, 0, 0.10), 0px 145px 145px 0px rgba(0, 0, 0, 0.09), 0px 327px 196px 0px rgba(0, 0, 0, 0.05), 0px 581px 232px 0px rgba(0, 0, 0, 0.01), 0px 908px 254px 0px rgba(0, 0, 0, 0.00);
    width: 90%;
    margin-inline-start: auto;

    a {
      text-decoration: none;
      &.active {
        li {
          color: var(--color-button-primary);
        }
      }
    }
    nav {
      margin-block: 30px;
    }
    ul {
      margin-block: 0;
      padding-inline-start: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      gap: 10px;
      li {
        @extend %link;
        padding-block-end: 10px;
        border-block-end: 2px solid var(--color-separator);
      }
    }

    .head {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      .logo {
        display: contents;
      }
      .logo svg {
        width: 150px;
        height: 54px;
        flex-shrink: 0;
        @media screen and (max-width: 1440px) {
          width: 83px;
          height: 30px;
          min-width: 82px;
          max-height: 39px;
        }
      }
    }
    .phone {
      @extend %link;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      margin-block-end: 30px;
    }
    .socials {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      .social {
        // flex-shrink: 0;
        width: 30px; height: 30px;
      }
    }
    button {
      padding: 0;
      background-color: transparent;
    }
  }
	&::backdrop {
		background-color: #131313CC;
	}
}

.mobile-city-picker-dropdown {
  position: fixed;
  background-color: white;
  padding: 16px;
  border: 1px solid var(--color-separator);
  box-shadow: inset;
  border-radius: 20px;
  top: 96px;
  right: 5%; left: 5%;
  hr {
    margin-block: 8px;
  }
  .header-custom-option {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    &.active {
      color: var(--color-button-primary);
    }
  }
}
