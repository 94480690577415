@import '../../assets/scss/variables.scss';
@import '../../assets/scss/components.scss';


.custom-swiper {
  position: relative;

  @media (min-width: $xl) {
    max-width: 92.7vw !important;
  }

  @media (min-width: $md) and (max-width: $lg) {
    margin-top: 6px;
    max-width: 87.2vw !important;
  }
  @media (min-width: $sm) and (max-width: $md) {
    margin-top: 5px;
    max-width: 92vw !important;

  }
  @media (min-width: $xs) and (max-width: $sm) {
    margin-top: -10px;
    width: 104%;
    max-width: 93.3vw !important;

  }
  @media (max-width: $xs) {
    top: -7px;
    width: 121%;
    max-width: 92vw !important;

  }

  .custom-slide {
    border-radius: 16px;
    overflow: hidden;

    img {
      width: 100%;
      // aspect-ratio: 1 / 1.25;
      object-fit: contain;
    }
  }
  .custom-slide {
    @media (max-width: $xs) {
      // height: 362px;
      height: 96.6vw;
    }
  }

  //.swiper-button-next
  
  .swiper-button-prev3, .swiper-button-next3 {
    @extend %swiperButtonInsideArrow;
  }
}

.swiper-button-prev3, .swiper-button-next3 {
  @extend %swiperButtonOutsideArrow;

  @media (min-width: $sm) and (max-width: $md) {
    width: 60px !important;
    height: 60px !important;
  }

}

  
.swiper-pagination3 {
  display: none;
}
.swiper-pagination-bullet {
  margin: 0 6px !important;
  background-color: rgba(19, 19, 19, 1);
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: $green;
}

.swiper-button-prev3 {
  @extend %swiperButtonPrev;

  @media (min-width: $md) and (max-width: $lg) {
    left: -16px !important;
  }
  // @media (min-width: $sm) and (max-width: $md) {}
  // @media (min-width: $xs) and (max-width: $sm) {}
  // @media (max-width: $xs) {}

}

.swiper-button-next3 {
  @extend %swiperButtonNext;
}

  