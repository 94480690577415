@use "../../assets/scss/vars" as *;
@use "../../assets/scss/components1";

.hero-karaoke {
    background: url("../../assets/img/bar/karaoke.jpeg") center/cover no-repeat;
    border-radius: 10px;
    padding: 112px;
    @media(max-width: $MYlg) {
        padding: 60px;
    }
    @media(max-width: 1024px) {
        padding: 30px;
    }
    @media(max-width: 768px) {}
    @media(max-width: 376px) {
        padding-inline: 15px;
    }
    p:first-of-type {
        color: white;
        margin-block-end: 244px;
        @media(max-width: 1440px) {
            margin-block-end: 130px;
        }
        @media(max-width: 1024px) {}
        @media(max-width: 768px) {
            margin-block-end: 150px;
        }
        @media(max-width: 376px) {
            margin-block-end: 270px;
        }
    }
}
section.description {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    gap: 200px;
    margin-block: 200px;
    @media(max-width: 1440px) {
        margin-block: 100px;
        gap: 100px;
    }
    @media(max-width: 1024px) {
        margin-block: 80px;
        gap: 80px;
    }
    @media(max-width: 768px) {
        flex-direction: column;
        justify-content: start;
        gap: 32px;
        margin-block: 64px 0;
        padding-bottom: 32px;
        border-bottom: 2px solid var(--color-separator);
    }
    @media(max-width: 376px) {}
    p:first-of-type {
        flex: 50% 0 0;
        @media(max-width: 1440px) {}
        @media(max-width: 1024px) {}
        @media(max-width: 768px) {
            flex: auto 1 0;
        }
        @media(max-width: 376px) {}
    }
}
// .events {
//     .slider-cards {
//         margin-block: 100px;
//         @media(max-width: 1440px) {
//             margin-block-end: 50px;
//         }
//         @media(max-width: 1024px) {
//             margin-block-start: 80px;
//         }
//         @media(max-width: 884px) {
//             margin-block: 32px;
//         }
//         @media(max-width: 768px) {}
//         @media (max-width: 448px) {}
//         @media(max-width: 376px) {}
//         .button-prev, .button-next {
//             top: 30%;
//         }
//     }
//     .slick-dots {
//         margin-block-start: 32px;
//     }
//     .event {
//         margin-inline: 10px;
//         font-family: "Cakra";
//         img {
//             width: 100%;
//             border-radius: 20px;
//             @media(max-width: 1440px) {
//                 border-radius: 10px;
//             }
//             @media(max-width: 1024px) {}
//             @media(max-width: 884px) {
//                 max-height: 375px;
//             }
//             @media (max-width: 448px) {}
//             @media(max-width: 376px) {}
//             object-fit: contain;
//         }
//         .details {
//             display: flex;
//             flex-direction: column;
//             align-items: stretch;
//             justify-content: space-between;
//             margin-block-start: 50px;
//             @media(max-width: 1440px) {
//                 margin-block-start: 32px;
//             }
//             @media(max-width: 1024px) {
//                 margin-block-start: 30px;
//             }
//             @media(max-width: 884px) {}
//             @media(max-width: 768px) {}
//             @media (max-width: 448px) {}
//             @media(max-width: 376px) {}
//             .title {
//                 font-size: 32px;
//                 @media(max-width: 1440px) {
//                     font-size: 16px;
//                 }
//                 @media(max-width: 1024px) {}
//                 @media(max-width: 884px) {}
//                 @media(max-width: 768px) {}
//                 @media (max-width: 448px) {}
//                 @media(max-width: 376px) {}
//             }
//             .time {
//                 font-size: 48px;
//                 margin-block: 30px;
//                 @media(max-width: 1440px) {
//                     font-size: 24px;
//                     margin-block: 10px;
//                 }
//                 @media(max-width: 1024px) {}
//                 @media(max-width: 884px) {}
//                 @media(max-width: 768px) {}
//                 @media (max-width: 448px) {}
//                 @media(max-width: 376px) {}
//             }
//             .info {
//                 color: var(--color-separator);
//                 font-size: 32px;
//                 @media(max-width: 1440px) {
//                     font-size: 24px;
//                 }
//                 @media(max-width: 1024px) {}
//                 @media(max-width: 884px) {}
//                 @media(max-width: 768px) {}
//                 @media (max-width: 448px) {}
//                 @media(max-width: 376px) {}
//             }
//         }
//     }
// }
section.about {
    margin-block: 200px;
    @media(max-width: 1440px) {
        margin-block: 100px;
    }
    @media(max-width: 1024px) {
        margin-block: 80px;
    }
    @media(max-width: 884px) {
        margin-block: 64px;
    }
    @media(max-width: 768px) {}
    @media (max-width: 448px) {}
    @media(max-width: 376px) {}
    .cards.inarow {
        @media (min-width: 884px) {
            justify-content: end;
            flex-wrap: nowrap;   
        }
        @media (max-width: 768px) {
            padding-bottom: 32px;
            border-bottom: 2px solid var(--color-separator);
        }

        .card {
            width: 25%;
            flex-direction: column;
            @media (max-width: 884px) {
                width: 48%;
                gap: 8px;
            }
            @media (max-width: 768px) {
                width: 100%;
            }
            .details {
                
            }
            img {
                width: 100%;
            }
        }
    }
}
