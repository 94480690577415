#order-rules {
    padding: 40px 30px 40px 40px;
    width: 100%;
    max-width: 695px;
    @media (max-width: 884px) {
        max-width: unset;
        margin: auto 0 0 0;
        padding: 32px 32px 90px;
    }
    h3 {
        font-size: 40px;
        margin-block-end: 70px;
        @media (max-width: 448px) {
            margin-block-end: 32px;
        }
    }
    ul {
        li {
            display: flex;
            flex-direction: row;
            align-items: start;
            justify-content: space-between;
            gap: 30px;

            .num {
                font-size: 40px;
                color: var(--color-button-primary);
            }
            .desc {
                font-size: 16px;
                line-height: 130%;
            }
        }
        hr {
            margin-block: 30px;
            height: 1px;
            background-color: var(--color-separator);
        }
    }
}
