@import '../../../assets/scss/variables.scss';
@import '../../../assets//scss/components.scss';

.ant-form-item {
  // height: 60px;
  // margin-bottom: 0;

  // @media (min-width: $xl) {
  //   height: 130px;
  // }
  // @media (min-width: $lg) and (max-width: $xl) {
  //   height: 95px;
  // }
  // @media (min-width: $md) and (max-width: $lg) {
  //   height: 60px;
  // }


  .ant-checkbox-wrapper {
    margin: 24px 0;

    @media (min-width: $sm) and (max-width: $lg) {
      margin: 5px 0;
    }
    @media (max-width: $sm) {
      margin: 14px 0;
      max-width: 96%;
      line-height: 18.5px;
    }
    @media (max-width: $xs) {
      margin: 10px 0;
    }



    a {
      color: $green;
      font-style: italic;

      &:hover, &:active {
        color: $light-green;
      }
    }

    .ant-checkbox {

      &:hover {
        // border: 1px solid green;
        // outline: #41B64D;
      }

    }
    .ant-checkbox+span {
      // @media (max-width: $sm) {
      @media (min-width: $xs) and (max-width: $sm) {
        padding-inline-start: 14px;
      }
      @media (max-width: $xs) {
        padding-inline-start: 17px;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #41B64D;
      border-color: transparent;
      &:hover {
        background-color: #64c96e;
      }
    }

    .ant-checkbox-inner {
      // @media (min-width: $xs) and (max-width: $sm) {
      @media (max-width: $sm) {
        width: 20px;
        height: 20px;
      }
      // @media (max-width: $xs) {
      //   width: 18px;
      //   height: 18px;
      // }

    }
  }
}