@use '../../../assets/scss/vars' as *;
@import '../../../assets/scss/variables.scss';

.ant-modal {
  width: auto !important;
  max-width: max-content;

  .ant-modal-close {
    top: -11px;
    inset-inline-end: 35px;
    width: 30px;
    height: 30px;
    background-color: $green;
    border: 1px solid rgba(65, 182, 77, 1);
    border-radius: 100px;
    color: white !important;
    transition: all 0.3s ease-out;

    &:hover {
      background-color: $light-green;
      transform: scale(1.1);
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .ant-modal-header {
    padding: 0 10px;
    margin-bottom: 25px;

    .ant-modal-title {
      font-family: 'Cakra';
      font-size: 40px;
      font-weight: 400;
      line-height: 32px;

      margin: 10px;
      @media (min-width: $xs) and (max-width: $sm) {
        font-size: 32px;
      }
      @media (max-width: $xs) {
        font-size: 28px;
        margin: 0;
      }
    }
  }

  .ant-modal-body {
    padding: 0 10px;

    @media (max-width: $sm) {
      padding: 0;
    }

    .rules-modal {
      margin-top: 55px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      @media (min-width: $xs) and (max-width: $sm) {
        margin-top: 45px;
        gap: 25px;
      }
      @media (max-width: $xs) {
        margin-top: 30px;
        gap: 20px;
      }

      .rule {
        display: flex;
        flex-direction: row;
        gap: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(217, 217, 217, 1);

        &:last-child {
          padding-bottom: 10px;
          border-bottom: none;
        }

        // @media (min-width: $sm) and (max-width: $md) {}
        @media (min-width: $xs) and (max-width: $sm) {
          gap: 22px;
          padding-bottom: 22px;
        }
        @media (max-width: $xs) {
          gap: 12px;
          padding-bottom: 15px;
        }

        .number {
          // align-self: center;
          // justify-self: center;
  
          font-family: 'Cakra';
          font-size: 40px;
          font-weight: 400;
          line-height: 32px;
          color: rgba(65, 182, 77, 1);
          padding: 0 10px;
          p {
            margin-block: 0;
          }
        }
        .text {
          // align-self: center;
          // justify-self: center;
          // background-color: gray;
          font-family: 'Manrope';
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;

          @media (max-width: $xs) {
            font-size: 14px;
          }

        }
      }


      // .gridParent {
      //   display: grid;
      //   grid-template-columns: 1fr 10fr;
      //   grid-template-rows: repeat(4, minmax(100px, max-content));
      //   gap: 10px 10px;
    
      //   .gridChild {
      //     border-radius: 20px;
    
      //   }
  
      //   .number {
      //     align-self: center;
      //     justify-self: center;
  
      //     font-family: 'Cakra';
      //     font-size: 40px;
      //     font-weight: 400;
      //     line-height: 32px;
      //     color: rgba(65, 182, 77, 1);
  
      //   }
  
      //   .text {
      //     align-self: center;

      //     justify-self: center;
      //     // background-color: gray;
      //     font-family: 'Manrope';
      //     font-size: 16px;
      //     font-weight: 400;
      //     line-height: 21px;
          
      //   }
    
      // }
    }

    .request-modal {
      font-family: 'Cakra';
      font-weight: 400;
      font-size: 40px;
      line-height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;

      .reservtaion-pending {
        font-family: 'Cakra';
        font-weight: 400;
        font-size: 40px;
        line-height: 32px;
      }
      
      .good {
        .body {
          line-height: 36px;
           
          span {
            color: $green;
          }
        }
      }

      .bad {
        color: rgba(255, 58, 68, 1);
        .frame.django,
        .frame.user,
        #summary,
        #traceback_area {
          overflow-x: scroll;
        }
        #traceback_area {
          max-width: 100%;
        }
      }

      .good, .bad {
        max-width: 435px;

        @media (min-width: 450px) and (max-width: $sm) {
          font-size: 32px;
        }

        @media (max-width: 450px) {
          font-size: 25px;
        }
      }
      .preorder-link {
        color: var(--color-button-primary);
        // text-decoration: underline 2px double green;
        margin-block-end: 10px;
        @media (min-width: $MYmd) {
          white-space: nowrap;
        }
        &:hover {
          // text-decoration: none;
          color: green;
        }
      }
    }
  }
}
