@use './assets/scss/vars' as *;
@import './assets/scss/variables.scss';
@import './assets/scss/components.scss';

* {
  box-sizing: border-box;
}
:root {
  --color: #131313;
	--color-button-primary: #41B64D;
	--color-separator: #D9D9D9;
    --link-text-color: var(--color);
}

@font-face {
  font-family: 'Cakra';
  // src: url("../fonts/Proxima Nova Regular.otf") format("opentype");
  src: url("./assets/fonts/cakra/Cakra-Normal.otf");
}
@font-face {
  font-family: 'Mont';
  // src: url("./assets/fonts/Mont-Semibold.ttf");
  src: url("https://db.onlinewebfonts.com/t/303ff8c2975f30a5d6de8572b6b3fd15.ttf") format("truetype");
}
body {
  margin: 0;
  font-family: 'Manrope', sans-serif;

  body {
    font-family: 'Cakrd';
  }

  .ant-select-dropdown {
    // width: auto !important;

    // // не работает, antd мать его за ногу (у меня так дед говорит)
    // max-height: none; /* Allow the dropdown to expand */
    // height: auto !important; /* Set height to auto to accommodate any number of options */
    // overflow-y: auto; /* Enable vertical scrolling if the options go beyond the available height */

    @media (min-width: $lg) and (max-width: $xl) {
      .ant-select-item  {
        height: 38px;
        font-size: 20px;
        // line-height: 20px;
        display: flex;
        // justify-content: center;
        align-items: center;
      }
    }

    @media (min-width: $xl) {
      // width: 700px !important;

      .ant-select-item {
        height: 50px;
        font-size: 24px;
        line-height: 24px;
        display: flex;
        // justify-content: center;
        align-items: center;
      }
    }

    .ant-select-item {
      &-option-selected {
        // background-color: rgb(176 242 180 / 26%) !important;
        background-color: rgb(176 242 180 / 26%) !important;
      }

      .header-custom-option {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // background-color: red;

        span {
          &:last-child {
            color: $gray;
          }
        }
      }
    }
  }

  input {
    @media (min-width: $xl) {
      font-size: 32px !important;
      // padding: 0px 30px !important;
      padding: 0px 55px !important;

    }
    @media (min-width: $lg) and (max-width: $xl) {
      font-size: 24px !important;
      padding: 0px 30px !important;

    }
    @media (min-width: $md) and (max-width: $lg) {
      // font-size: 24px !important;
      padding: 0px 24px !important;
    }
    @media (min-width: $sm) and (max-width: $md) {
      padding: 4px 25px !important;
    }
    // @media (min-width: $xs) and (max-width: $sm) {}
    // @media (max-width: $xs) {}

  }

  textarea {
    @media (min-width: $xl) {
      font-size: 32px !important;
      padding: 43px 54px !important;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      font-size: 24px !important;
      padding: 30px 30px !important;

    }
    @media (min-width: $md) and (max-width: $lg) {
      padding: 18px 24px !important;

    }
    @media (min-width: $sm) and (max-width: $md) {
      padding: 19px 24px !important;
    }
    @media (min-width: $xs) and (max-width: $sm) {
      // padding: 16px 25px;
      padding: 17px 25px !important;
    }
    @media (max-width: $xs) {
      padding: 17px 23px !important;
    }
  }




  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

:root {
  // --swiper-theme-color: #007aff;
  --swiper-theme-color: $green;
}

.ant-picker-dropdown {
  @media (min-width: $xl) {
    font-size: 32px;
  }


  .ant-picker-today-btn {
    color: $green;

    &:hover {
      color: $light-green;
    }
  }

  .ant-picker-time-panel {
    .ant-picker-content {
      @media (min-width: $xl) {
        height: 410px !important;
      }

      .ant-picker-cell-today {
        .ant-picker-cell-inner {
          &::before {
            border: 1px solid $green;
          }
        }
      } 
      .ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background-color: $green;
        }
      } 

      .ant-picker-time-panel-column {
        @media (min-width: $xl) {
          width: 75px;
        }

        .ant-picker-time-panel-cell-inner {
          @media (min-width: $xl) {
            height: 50px !important;
            width: 65px !important;
            padding-top: 8px !important;
          }
        }
  
        .ant-picker-time-panel-cell-selected {
          .ant-picker-time-panel-cell-inner {
            background: #cbf6cf !important;
            background: #b6e8ba73 !important;
          }
        }
      }
    }
  }
  .ant-picker-footer {
    .ant-picker-ranges {
      line-height: 100%;
      // .ant-picker-now {
      //   .ant-picker-now-btn {
      //     color: $green;
      //     &:hover {
      //       color: $light-green;
      //     }
      //   }
      // }
      margin-block-start: 0;
      .ant-picker-ok {
        margin-inline-start: 0;
        flex-basis: 100%;

        .ant-btn {
          @extend %greenButton;
          // height: 24px;
          // font-size: 14px;
          // font-weight: 400;
          border-radius: 4px;
          width: 100%;
          padding: 0px 12px !important;
        }
      }
    }

  }
.ant-picker-time-panel-column::after {
  height: 100px !important;
}
  //datapicker

  .ant-picker-date-panel {
    @media (min-width: $xl) {
      // width: 400px;
      width: 445px;
    }

    .ant-picker-header {
      @media (min-width: $xl) {
        padding: 10px 25px;
      }
      .ant-picker-header-super-prev-btn, .ant-picker-header-prev-btn,
      .ant-picker-header-super-next-btn, .ant-picker-header-next-btn {
        @media (min-width: $xl) {
          min-width: 2.6em;
        }

        .ant-picker-super-prev-icon, .ant-picker-prev-icon,
        .ant-picker-super-next-icon, .ant-picker-next-icon {
          width: 10px;
          height: 10px;
          
          &::after, &::before {
            width: 10px;
            height: 10px;
          }
          &::after {
            top: 6px;
            left: 6px;
          }
        }
      }


    }
    .ant-picker-body {

      .ant-picker-content {

        @media (min-width: $xl) {
          width: 100%;
        }


        .ant-picker-cell-inner {

          @media (min-width: $xl) {
            min-width: 45px;
            height: 43px;
            padding-top: 7px;
          }

        }
      }

    }
  }

  .ant-picker-footer {
    @media (min-width: $xl) {
      padding: 15px 0;
    }
  }


}

