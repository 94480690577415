@use "../../assets/scss/vars" as *;

.banner {
    border-radius: 10px;
    padding: 112.68px 116.59px 114.31px; // &ellipsis;
    background-size: cover;
    background-position: center;
    margin: 60px 100px 50px;

    @media(max-width: $MYlg) {
        padding: 60px;
        margin-top: 50px;
    }
    @media(max-width: $MYmd) {
        padding: 30px;
        margin-inline: 50px;
    }
    @media(max-width: $MYsm) {
        margin: 32px 32px 64px;
        .banner-button {
            display: block;
            margin-top: 10px; // Задает отступ сверху для всех кнопок, кроме первой
            margin-right: 0; // Убирает отступ справа для всех кнопок
            min-width: 260px;
            &:first-child {
              margin-top: 0; // Убирает отступ сверху для первой кнопки
            }
        }
    }
    @media(max-width: $MYxs) {
        margin-inline: 17px;
        margin-block-start: 20px;
        padding-inline: 15px;
        .banner-button {
            display: block;
            width: 100%; // Меняет ширину кнопок на 100% при размере экрана 768px
            margin-top: 10px; // Задает отступ сверху для всех кнопок, кроме первой
            margin-right: 0; // Убирает отступ справа для всех кнопок
      
            &:first-child {
              margin-top: 0; // Убирает отступ сверху для первой кнопки
            }
        }
    }
    p {
        font-family: Cakra;
        font-size: 128px;
        font-weight: 400;
        line-height: 80%;
        // letter-spacing: 0px;
        text-align: left;

        color: white;
        margin-block-start: 0;
        margin-block-end: 244.56px;

        @media(min-width: calc($MYlg + 1px)) {
            font-size: 96px;
        }
        @media(max-width: $MYlg) {
            font-size: 64px;
            margin-block-end: 129px;
        }
        @media(max-width: $MYmd) {
            margin-block-end: 143px;
        }
        @media(max-width: $MYsm) {
            margin-block-end: 152px;
        }
        @media(max-width: $MYxxs) {
            margin-block-end: 272px;
        }
    }
    .button-primary {
        background-color: var(--color-button-primary); 
        color: white;
        text-transform: uppercase;
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
        @media (max-width: $MYxs) {
            width: 100%;
        }
    }
    .button-primary:hover {
        background-color: #52d563;
        cursor: pointer;
    }
    &-description {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: space-between;
        // width: 100%;
        gap: 200px;
        margin: 197px 100px 245px;
        @media(max-width: $MYxl) {
            margin: 100px;
            gap: 100px;
        }
        @media(max-width: $MYmd) {
            margin: 80px 50px;
            gap: 80px;
        }
        @media(max-width: $MYsm) {
            flex-direction: column;
            justify-content: start;
            align-items: stretch;
            gap: 24px;
            margin-block: 0; // Обнуляем отступ сверху и снизу
            padding-bottom: 32px;
            border-bottom: 1px solid var(--color-separator);
        }
        @media(max-width: $MYxs) {
            margin-inline: 17px;
        }
        @media(max-width: $MYxxs) {
            gap: 16px; // ...
        }
        p {margin: 0}
        .firstParagraph {
            width: 42%;
            box-sizing: border-box;
            min-height: 100px;

            font-family: Cakra;
            font-size: 96px;
            font-weight: 400;
            line-height: 80%;
            letter-spacing: 0px;
            text-align: left;

            @media (max-width: $MYxl) {
                font-size: 48px;
            }
            
            @media (max-width: $MYmd) {
                font-size: 40px;
            }
            
            @media (max-width: $MYsm) {
                width: 100%;
                font-size: 32px;
            }
            
            @media (max-width: $MYxxs) {
                font-size: 32px;
            }
        }

        .secondParagraph {
            width: 58%;
            max-width: 1165px;
            box-sizing: border-box;
            min-height: 100px;
            font-family: Manrope;
            font-size: 24px;
            font-weight: 400;
            line-height: 130%;
            letter-spacing: 0px;
            text-align: left;

            @media (min-width: calc($MYxl + 1px)) {
                font-size: 40px;
            }
            @media (max-width: $MYlg) {
                font-size: 16px;
            }
            @media (max-width: $MYmd) {
                width: 50%;
            }
            @media (max-width: $MYsm) {
                width: 100%;
            }
        }
    }
}
