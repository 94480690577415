@use '../../assets/scss/vars' as *;
@use '../../assets/scss/components1';

header {
    @media(max-width: $MYsm) {
        margin-block-end: 20px;
    }
}
.hero-menu {
    @extend %hero;
    padding: 100px 32px;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    border-radius: 10px;

    --bg-url: url("../../assets/img/Menu_tablet.png");

    background: var(--bg-url) right 0 top 50%/cover no-repeat, linear-gradient(268deg, #181C26 0.65%, #2F3649 96.08%);

    @media (min-width: $MYlg) {
        --bg-url: url("../../assets/img/Menu_desktop.png");
    }
    @media (max-width: $MYxs) {
        --bg-url: url("../../assets/img/Menu_mob.png");
    }

    font-family: 'Cakra';
    line-height: 80%;

    margin-inline: 100px;
    
    @media(min-width: calc($MYlg + 1px)) {
        border-radius: 20px;
    }
    @media(min-width: calc($MYxl + 1px)) {
        padding: 203px 88px 99.55px;
    }
    @media(max-width: $MYlg) {
        padding: 73px 45px 84px;
    }
    @media(max-width: $MYmd) {
        position: relative;
        margin-inline: 50px;
        padding: 92px 30px 30px;
    }
    @media(max-width: $MYsm) {
        margin-inline: 32px;
        margin-block-start: 20px;
        padding: 32px;
        --bg-url: url("../../assets/img/menu-hero-bg-tablet.png");
    }
    @media(max-width: $MYxs) {
        margin-inline: 16px;
        padding: 30px 15px 135px;
        --bg-url: url("../../assets/img/menu-hero-bg-mobile.png");
        background: var(--bg-url) center/cover no-repeat, linear-gradient(268deg, #181C26 0.65%, #2F3649 96.08%);
    }
    > h1 p {
        color: var(--color-button-primary);
        margin-block: 0 49.56px; // уже не удивляет
        font-size: 128px;
        font-family: 'Cakra';
        line-height: 80%;
        @media(max-width: $MYlg) {
            font-size: 64px;
            margin-block-end: 30px;
        }
        @media(max-width: $MYsm) {
            margin-block-end: 94px;
        }
        @media(max-width: $MYxs) {
            margin-block-end: 105px;
        }
        @media(max-width: $MYxxs) {}
    }
    button {
        width: 577px;
        @media(max-width: 1440px) {
            width: 302px;
        }
        @media (max-width: 448px) {
            width: 100%;
        }

        &.calculator {
            margin-block-end: 19.98px;
            @media(max-width: 1440px) {
                margin-block-end: 10px;
            }
        }
    }
}

#lunch-special, #kitchen-special {
    font: 600 32px/100% "Manrope";
    color: white;
    text-transform: uppercase;
    margin-inline: 100px;
    position: relative;
    @media (min-width: calc($MYlg + 1px)) {
        margin-block: 60px;
    }
    @media (max-width: $MYlg) {
        margin-block: 50px;
        font-size: 24px;
    }
    @media (max-width: $MYmd) {
        font-size: 16px;
        text-shadow: #181C26;
        margin-inline: 50px;
    }
    @media (max-width: $MYsm) {
        margin-block: 20px 0;
        margin-inline: 32px;
    }
    @media (max-width: 678px) {
        font-size: 12px;
        color: black;
    }
    @media (max-width: $MYxs) {
        margin-inline: 16px;
    }
    img {
        width: 100%;
        border-radius: 10px;
    }
    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1.5em;
        position: absolute; top: 2em; left: 2em;
        width: 40%;

        @media (max-width: 678px) {
            width: 100%;
            position: static; top: unset; left: unset;
            margin-block-start: 2em;
        }

        p {
            width: 100%;
            margin: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .price {
                color: var(--color-button-primary);
                font-size: 1.5em;
            }
        }
    }
}

#bar-special {
    margin-inline: 100px;
    @media (min-width: calc($MYlg + 1px)) {
        margin-block: 60px;
    }
    @media (max-width: $MYlg) {
        margin-block: 50px;
    }
    @media (max-width: $MYmd) {
        margin-inline: 50px;
    }
    @media (max-width: $MYsm) {
        margin-block: 20px 0;
        margin-inline: 32px;
    }
    @media (max-width: $MYxs) {
        margin-inline: 16px;
    }
    img {
        width: 100%;
        border-radius: 10px;
    }
}