.gutsh-dd {
    --chevron-size: .5em;
    --chevron-offset: -.75em;

    width: max-content; height: max-content;
    position: relative;
    padding-inline-end: calc(var(--chevron-size) + var(--chevron-offset));

    .chevron {
        position: absolute;
        width: var(--chevron-size);
        top: 50%; right: var(--chevron-offset);
        transition: transform .25s;
    }
    .dd {
        position: absolute;
        left: -1em;
        width: max-content;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        gap: 1em; padding: 1em; margin-block: 0;
        display: none;
        background-color: white; box-shadow: 1px 1px 2px var(--color-separator);
        border-radius: 10px;
    }
    &:is(.active) {
        .chevron {
            transform: rotate(180deg);
        }
        .dd {
            display: flex;
        }
    }
    @media (hover: hover) {
        &:hover {
            .dd {
                display: flex;
            }
        }
    }
}
