@use '../../assets/scss/vars' as *;
@use '../../assets/scss/components1';
@use '../../assets/scss/modules_try/components';
@use '../../assets/scss/photoGrid';

.hero-events {
    // @extend %hero;
    position: relative;
    z-index: 0;
    margin-block-start: 140px;
    margin-inline: 100px;

    @media(max-width: $MYlg) {
        margin-block-start: 100px;
    }
    @media(max-width: $MYmd) {
        margin-inline: 50px;
    }
    @media(max-width: $MYsm) {
        margin-inline: 32px;
    }
    @media(max-width: $MYxs) {
        margin-inline: 16px;
    }
    @media(max-width: $MYxxs) {
        margin-block-start: 120px;
    }

    p {
        position: relative;
        z-index: 0;

        margin-block: 0;

        @media (max-width: $MYmd) {
            margin-inline: -50px;
            padding-inline: 50px;
        }
        @media (max-width: $MYsm) {
            margin-inline: -32px;
            padding-inline: 32px;
        }
        @media (max-width: $MYxs) {
            margin-inline: -16px;
            padding-inline: 16px;
        }

        img {
            position: absolute;
            z-index: 1;
        }
        img[src*='hero-star.'] {
            top: 0; left: -24px;
            height: 255px;
            aspect-ratio: calc(211 / 255);
            @media (max-width: $MYxl) {
                height: 180px;
            }
            @media(max-width: $MYlg) {
                top: 24px;
                height: 121px;
                aspect-ratio: calc(100 / 121);
            }
            @media(max-width: $MYmd) {
                top: 50px; left: 16px;
            }
            @media(max-width: 768px) {
                top: -9px; left: 15%;
                height: 61px;
                aspect-ratio: calc(50 / 61);
            }
            @media (max-width: $MYxs) {
                top: -10px; left: 100px;
            }
        }
        img[src*='hero-heart.'] {
            top: 0; left: 30%;
            height: 177px;
            aspect-ratio: calc(193 / 177);
            @media (min-width: ($MYxl - 1px)) {
                left: 516.89px;
            }
            @media(max-width: $MYlg) {
                left: 23%;
                height: 94px;
                aspect-ratio: calc(103 / 94);
            }
            @media(max-width: $MYmd) {
                top: -25px; left: 26%;
            }
            @media(max-width: 768px) {
                top: unset; left: unset;
                right: 15%; bottom: -20px;
                height: 67px;
                aspect-ratio: calc(73 / 67);
            }
            @media (max-width: $MYxs) {
                right: 10px; bottom: -20px;
            }
        }
        img[src*='hero-smile.'] {
            top: -112px; left: 50%;
            height: 212px;
            aspect-ratio: (224 / 212);
            @media(max-width: $MYxl) {
                top: -83px;
                height: 166px;
            }
            @media(max-width: $MYlg) {
                top: -40px;
                left: unset; right: 39%;
                height: 113px;
                aspect-ratio: (119 / 113);
            }
            @media (max-width: 768px) {
                top: -17px; right: 33%;
                height: 59px;
                aspect-ratio: (62 / 59);
            }
            @media(max-width: $MYxs) {
                top: 100px; right: unset; left: 38px;
            }
        }
        img[src*='hero-star-2.'] {
            bottom: -93.24px; right: 30%;
            height: 186px;
            aspect-ratio: (184 / 186);
            @media(max-width: $MYlg) {
                bottom: -45px;
                height: 99px;
                aspect-ratio: (98 / 99);
            }
            @media(max-width: $MYmd) {
                right: 25%;
            }
            @media(max-width: 768px) {
                bottom: -10px; right: unset; left: 40%;
                height: 53px;
                aspect-ratio: (52 / 53);
            }
            @media (max-width: $MYxs) {
                display: none;
            }
        }
        img[src*='hero-star-3.'] {
            z-index: -1;
            top: -120.89px; right: -49.34px;
            height: 334px;
            aspect-ratio: calc(382 / 334);
            @media (max-width: $MYxl) {
                height: 280px;
            }
            @media (max-width: $MYlg) {
                top: -80px; right: -76px;
                height: 220px;
                aspect-ratio: calc(252 / 220);
            }
            @media (max-width: $MYmd) {
                top: -70px; right: -5px;
                height: 171px;
                aspect-ratio: calc(196 / 171);
            }
            @media (max-width: 768px) {
                top: -84px; right: -37px;
                height: 158px;
                aspect-ratio: calc(177 / 158);
            }
            @media (max-width: 560px) {
                height: 120px;
            }
            @media (max-width: $MYxs) {
                top: -110px; right: -45px;
                height: 232px;
                aspect-ratio: calc(261 / 232);
                object-position: top -20px right -40px;
            }
        }
        span {
            font-size: calc(100vw / 4.759);
            line-height: 80%;
            @media (max-width: $MYlg) {
                font-size: calc(100vw / 5.03);
            }
            @media (max-width: $MYmd) {
                font-size: calc(100vw / 4.85);
            }
            @media (max-width: $MYxs) {
                font-size: 128px;
            }
        }
        br {
            @media (min-width: calc($MYxs + 1px)) {
                display: none;
            }
        }
    }
    button {
        margin-block-start: 93.11px;
        width: 100%;
        // @media (min-width: calc($MYlg + 1px)) {
        //     width: 50%;
        // }
        @media(max-width: $MYlg) {
            margin-block-start: 50px;
        }
        @media(max-width: $MYsm) {
            margin-block-start: 32px;
        }
        // @media (max-width: $MYxs) {
        //     width: 100%;
        // }
    }
}

.events, .galery {
    margin-block: 200px;
    margin-inline: 100px;

    @media(max-width: $MYlg) {
        margin-block: 100px;
    }
    @media(max-width: $MYmd) {
        margin-block: 80px;
        margin-inline: 50px;
    }
    @media(max-width: $MYsm) {
        margin-block: 64px 32px;
        margin-inline: 32px;
    }
    @media(max-width: $MYxxs) {
        margin-inline: 16px;
    }
}
.events {
    > button {
        width: 100%;
        margin-block-start: 100px;
        @media (max-width: $MYlg) {
            margin-block-start: 50px;
        }
        @media (max-width: $MYmd) {
            display: none;
        }
    }
}
.galery {
    p:first-of-type {
        font-size: 96px;
        margin-block: 0 100px;
        @media(max-width: $MYlg) {
            font-size: 64px;
        }
        @media(max-width: $MYmd) {
            font-size: 48px;
        }
        @media(max-width: $MYsm) {
            margin-block-end: 30px;
        }
    }
    .social {
        width: auto; height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;

        margin-block-start: 50px;
        margin-inline-start: auto;
        @media(max-width: $MYlg) {
            margin-block-start: 38px;
        }
        @media(max-width: $MYmd) {
            margin-block-start: 30px;
        }
        @media(max-width: $MYsm) {
            margin-block-start: 16px;
        }
        @media (max-width: $MYxs) {
            margin-block-start: 30px;
        }

        svg {
            width: 57px; height: 57px;
            margin-inline-start: 60px;
            @media(max-width: $MYlg) {
                width: 30px; height: 30px;
                margin-inline-start: 30px;
            }
        }
    }
}

.reservation-form-modal {
    :global(.ant-modal-content) {
        padding: 32px;
        @media (max-width: $MYsm) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    :global(.ant-modal-body) {
        p {
            font-size: 40px;
            margin-block-start: 0;
        }
    }
}

body:has(.hero-events) {
    :global(.ant-modal) {
        @media (min-width: 2001px) {
            max-width: 2000px;
        }
        @media (max-width: 2000px) {
            width: 80% !important;
            margin-inline: auto;
            max-width: unset;   
        }
        @media (max-width: $MYsm) {
            padding-bottom: 0;
            margin: auto 0 0 !important;
            width: 100% !important;
        }
    }
}
