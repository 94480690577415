@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/components.scss';

.locations {
  @media (min-width: $xl) {
    gap: 47px 30px !important;
  }
  @media (min-width: $md) and (max-width: $lg) {
    gap: 15px 45px !important;
    // margin-left: -99px;
  }
  @media (min-width: $sm) and (max-width: $md) {
    gap: 15px 10px !important;
  }

  @media (min-width: $sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px 10px;
    transition: all 0.3s ease-out;
  }

  .parent {
    display: block;
    position: relative;
  }
  
  .child {
    z-index: 0;
  }

  .whiteButton {
    :global(.ant-btn) {
      @extend %whiteButton;
      width: 100%;
    }

  }
}

.mobile-collapse-block {
  &:not(.isLocked) {
    .mobile-collapse-wrapper {
      height: min-content;
      transition: height .4s ease-in-out;
    }
  }
  &.isLocked {
    .mobile-collapse-wrapper {
      height: calc(626px + 2 * 58px);
    }
  }

  .mobile-collapse-wrapper {
    width: 100%;
    overflow: hidden;

    &.child {
      min-height: auto;
    }

    :global(.ant-collapse-header) {
      @media (max-width: $xs) {
        padding: 13.2px 0;

        .location-wrapper {
          gap: 30px;
          display: flex;
          flex-direction: row;
          align-items: center;

          .location-icon {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .city {
            font-family: 'Cakra';
            font-weight: 400;
            font-size: 24px;
            line-height: 19.2px;
          }
        }
      }
    }
  }

  .collapse-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -100px;
      height: 120px;
      width: 100%;
      background: linear-gradient(to top, #fafafa, transparent);

      @media (max-width: $xs) {
        // top: calc(100% - 413px);
        // height: 413px;
        top: calc(100% - 210px);
        height: 210px;
      }
    }
    &.gradient-hide {
      margin-block-start: 24px;
    }
    &.gradient-hide::before {
      display: none;
    }
  }
}
.mobile-location-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .location-wrapper {
    .location-icon {
      margin-top: 0;
    }
  }
}
.location-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (min-width: $xl) {
    margin-bottom: 0;
    gap: 100px;
  }
  @media (min-width: $lg) and (max-width: $xl) {
    gap: 91px;
  }

  @media (min-width: $md) and (max-width: $lg) {
    width: 372px;
    gap: 82px;
  }
  @media (min-width: $md) and (max-width: ($lg - $lg/8)) {
    gap: 48px;
    // width: 372px;
    width: 320px;
  }

  @media (min-width: $sm) and (max-width: $md) {
    width: 302px;
    // gap: 41px;
    gap: 32px;
  }

  @media (min-width: ($md - $md/8)) and (max-width: ($md - $md/14)) {
    gap: 17px;
    width: 280px;
  }
  @media (min-width: $sm) and (max-width: ($md - $md/8)) {
    gap: 10px;
    width: 230px;
  }



  .location-icon {
    margin-top: 12px;

    svg {
      width: 24px;
      height: 24px;

      @media (min-width: $xl) {
        // width: 28.67px;
        // height: 35.83px;
        width: 43px;
        height: 43px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        // width: 28.67px;
        // height: 35.83px;
        width: 43px;
        height: 43px;
      }

    }

  }

  .full-address {
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media (min-width: ($xl + $xl/6)) {
      // width: 74%;
      width: 65%;
    }

    @media (min-width: ($xl + $xl/8)) and (max-width: ($xl + $xl/6)) {
      width: 74%;
      // width: 65%;
    }

    @media (min-width: $xl) and (max-width: ($xl + $xl/8)) {
      width: 83%;
      // width: 65%;
    }
    @media (min-width: ($md - $md/8)) and (max-width: $md) {
      // max-width: 200px;
      max-width: 234px;
    }
    @media (min-width: $sm) and (max-width: ($md - $md/8)) {
      max-width: 200px;
      // max-width: 234px;
    }

    .wrapper-city {
      display: flex;
      flex-direction: row;

      .arrow-down-svg {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: $xl) {
          margin-left: 34px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          margin-left: 32px;
        }
        @media (min-width: $sm) and (max-width: $lg) {
          margin-left: 30px;
        }


        svg {
          @media (min-width: $xl) {
            width: 43px;
            height: 43px;
          }

        }
      }
    }
    .city {
      font-family: Cakra;
      font-weight: 400;
      text-transform: uppercase;
      margin: 10px 0;

      @media (min-width: $xl) {
        font-size: 64px;
        line-height: 51px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        font-size: 48px;
        line-height: 39px;
      }
      @media (min-width: $sm) and (max-width: $lg) {
        font-size: 32px;
        line-height: 26px;
      }
      @media (min-width: ($md - $md/8)) and (max-width: ($md - $md/14)) {
        font-size: 30px;
      }
      @media (min-width: ($md - $md/5)) and (max-width: ($md - $md/8)) {
        font-size: 28px;
      }
      @media (min-width: $sm) and (max-width: ($md - $md/8)) {
        font-size: 26px;
      }


      @media (min-width: $xs) and (max-width: $sm) {
        font-size: 24px;
        line-height: 19px;
      }
      @media (max-width: $xs) {
        font-size: 32px;
        line-height: 26px;
      }

      &-active {
        color: $green;
      }


    }
    .address {
      font-family: 'Manrope';
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;

      @media (min-width: $xl) {
        font-size: 28px;
        line-height: 37px;
        width: 71%;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .phone {
      font-family: 'Manrope';
      font-weight: 400;

      font-size: 20px;
      line-height: 26px;

      @media (min-width: $xl) {
        font-size: 36px;
        line-height: 46px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        font-size: 28px;
        line-height: 46px;
      }


    }
  }
}

:global(.ant-collapse) {
  border: 1px solid transparent;
  background-color: transparent;

  :global(.ant-collapse-item) {
    
    :global(.ant-collapse-header) {
      font-family: 'Cakra';
      font-weight: 400;

      @media (min-width: $xs) and (max-width: $sm) {
        font-size: 24px;
        line-height: 19px;
        padding: 16px 0;

        .location-wrapper {
          gap: 31px;
        }

      }

      :global(.ant-collapse-expand-icon) {
        display: none !important;
      }
    }

    :global(.ant-collapse-content) {
      border-top: 1px solid transparent;
    }
  }

  :global(.ant-collapse-item-active) {
    @media (min-width: $xs) and (max-width: $sm) {
      padding-bottom: 15px;
    }

    :global(.ant-collapse-header) {
      @media (max-width: $sm) {
        font-size: 32px !important;
        // line-height: 19px;
        padding: 0;
        color: $green;

        .city {
          font-size: 32px !important;
        }

        .location-icon {
          display: none !important;
        }
      }

      :global(.ant-collapse-header-text) {
        margin-top: 15px;

        .arrow-down-svg {
          svg {
            // transform: rotateY(180deg);
            transform: rotate(180deg);

            
            path {
              fill: $green;
            }
          }
        }
      }
    }

    :global(.ant-collapse-content) {
      :global(.ant-collapse-content-box) {
        @media (min-width: $xs) and (max-width: $sm) {
          padding: 0;
          margin-top: 15px;
        }
        @media (max-width: $xs) {
          // padding: 0;
          // margin-top: 15px;
        }

        .full-address {
          font-family: Manrope;
          font-weight: 400;

          .address {
            @media (max-width: $sm) {
              font-size: 16px !important;
              line-height: 21px !important;
            }
          }
          .phone {
            @media (max-width: $sm) {
              font-size: 20px !important;
              line-height: 26px !important;
            }
          }
        }
      }
    }
  }

}

.map-mobile {
  height: 448px;
  margin-block-start: 32px;
}