@import './assets/scss/variables.scss';
@import './assets/scss/mixins.scss';
@import './assets/scss/components.scss';
@import './assets/scss/templates.scss';


// .yandex-food-button {
//   .ant-btn {
//     @extend %yandexButton;
//   }
// }

.parent {
  display: block;
  position: relative;
}

.child {
  position: absolute;
  /* display: block; */
  z-index: 0;
}

.childBody {
  /* display: block; */
  z-index: 10;
  display: block;
  position: relative;

  // @media (max-width: ($xs - $xs/4)) {
  //   // top: -38px;
  //   top: -65px;

  // }
}

h1.meta {
  display: contents;
}
#rest-microdata {
  display: none;
}
