
@import '../../../assets/scss/variables.scss';
.tag {
  font-family: 'Manrope';
  background-color: #131313;
  color: #FFFFFF;
  font-weight: 600;
  // height: 47px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin-block-end: 2em;
  font-size: 30px;
  border-radius: 30px;
  padding: .5em 1em;

  @media (max-width: $xl) {
    border-radius: 25px;
    font-size: 24px;
  }
  @media (max-width: $lg) {
    margin-block-end: 1em;
    border-radius: 20px;
    font-size: 16px;
    border-radius: 20px;
    font-size: 12px;
  }
  @media (max-width: $md) {
    font-weight: 500;
  }
  @media (max-width: $sm) {
    margin-block-end: 0;
  }
}