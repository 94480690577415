@use "../../assets/scss/vars" as *;
@use "../../assets/scss/aux";
@use "../../assets/scss/components";
@use "../../assets/scss/components1";
@use "../../assets/scss/orderRules";

#order-contents {
    width: 100%;
    max-width: 1000px;

    @media(max-width: 884px) {
        margin-block-end: 0;
    }
    hr {
        margin-block: 30px 20px;
    }
    p {
        margin-block: 0;
    }
    h3 {
        font-size: 40px;
        margin-block-end: 30px;
        @media (max-width: $MYsm) {
            margin-block: 0;
            font-size: 24px;
        }
    }
    .items {
        padding-inline-start: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: start;
        gap: 10px;
        @media (max-width: 884px) {
            gap: 0;
        }
        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 884px) {
                border-bottom: 1px solid var(--color-separator);
                align-items: start;
                padding-block: 20px;
            }
            .name {
                font-size: 16px;
                line-height: 100%;
            }
            img {
                height: 60px;
                margin-inline-end: 30px;
                aspect-ratio: calc(90.9 / 60);
                object-fit: cover;
                border-radius: 10px;
                @media (max-width: 884px) {
                    height: 44px;
                    aspect-ratio: calc(66 / 44);
                    margin-inline-end: 16px;
                }
            }
            .cont {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                @media (max-width: 884px) {
                    flex-direction: column;
                    align-items: start;
                    justify-content: start;
                    gap: 16px;
                }
                .aligner {
                    flex: 0 1 50%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    // gap: 163px;
                    @media(max-width: 884px) {
                        flex-direction: column;
                        align-items: start;
                        justify-content: start;
                        gap: 16px;
                    }
                    .order-count {
                        gap: 20px;
                        svg {
                            width: 30px; height: 30px;
                        }
                    }
                    .price {
                        font-size: 24px;
                    }
                }
            }
        }
    }
    .totals {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: start;
        gap: 30px;
        
        @media (max-width: 884px) {
            gap: 24px;
            margin-block: 32px;
        }
        p {
            display: flex;
            flex-direction: row;
            align-items: end;
            justify-content: space-between;

            &.total {
                .price {
                    font-size: 32px;
                }
            }
        }
        span:not([class]) {
            font-size: 16px;
            line-height: 100%;
        }
        .price {
            font-size: 24px;
        }
    }
    .guest-info {
        @extend %label;
        --item-height: 3em;
        .ant-form-item {
            label {
                font: 400 12px/100% "Manrope" !important;
                @media (max-width: 768px) {
                    font-size: 8px;
                }
            }
            font: 400 24px/100% "Manrope" !important;
            @media (max-width: 768px) {
                font-size: 12px;
            }
            .ant-input, .ant-picker-input input {
                height: var(--item-height) !important;
                font: inherit !important;
            }
            .ant-input:hover {
                border-color: var(--color-button-primary);
            }
        }
        .ant-picker {
            width: 100%;
            padding-block: 0;
            &:hover {
                border-color: var(--color-button-primary);
            }
        }
        .ant-select {
            height: var(--item-height);
            border: 1px solid var(--color-separator);
            border-radius: 10px;
            &-selector {
                background: transparent;
            }
            &:hover {
                border-color: var(--color-button-primary);
            }
        }
        .ant-input-wrapper {
            .ant-input-group-addon {
                background: transparent !important;
                color: var(--color-separator);
                & + input {
                    border-inline-start: 0;
                }
                
            }
            &:hover {
                .ant-input-group-addon {
                    border-color: var(--color-button-primary);
                    & + input {
                        &:hover {
                            border-color: var(--color-button-primary);
                        }
                    }
                }
            }
        }
        .row {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            > * {
                flex: 1 1 50%;
                // &:has(.ant-select) {
                //     max-width: 42.5%;
                // }
            }
            @media (max-width: 768px) {
                > * {
                    flex: 1 0 100%;
                }
            }
        }
        .phone-prefix {
            background: transparent;
        }
    }
    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        @media (max-width: 884px) {
            flex-direction: column;
            align-items: stretch;
            justify-content: start;
            gap: 8px;
        }

        button {
            flex-grow: 1;
            @media (min-width: 1441px) {
                padding: 15px 30px;
            }
            @media (max-width: 884px) {
                flex: 0 0 auto;
            }
        }
        .share {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            @media (min-width: calc($MYmd + 1px)) {
                display: none;
            }
            @media (max-width: 884px) {
                margin-block: 20px;
            }

            span {
                margin-inline-end: 10px;
                @media (min-width: 1441px) {
                    margin-inline-end: 0;
                    font-size: 20px;
                }
            }
            .icons-cont {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                svg {
                    width: 40px; height: 40px;
                }
            }
        }
    }
    .explain {
        border-top: 1px solid var(--color-separator);
        padding-block: 2em;
        text-align: center;
        font: 600 16px/100% "Manrope";
    }
    .footnote {
        font-size: 14px;
        color: #ff3f3f;
        margin-block-start: 20px;
    }
}

.hero-preorder {
    @extend %hero;
    // margin: 32px;    //Добавляет отступы снаружи элемента, отделяющие его от других (да ладно)
    background: linear-gradient(268deg, #181C26 0.65%, #2F3649 96.08%);
    border-radius: 10px;

    padding: 123px 0 123.55px 88px;
    margin-inline: 100px;
    position: relative;

    @media(min-width: calc($MYlg + 1px)) {
        border-radius: 20px;
    }
    @media(max-width: $MYlg) {
        border-radius: 10px;
        padding: 73px 0 65px 50px;
    }
    @media(max-width: $MYmd) {
        margin-inline: 50px;
        padding: 86px 0 30px 32px;
    }
    @media(max-width: $MYsm) {
        margin-inline: 32px;
        padding: 32px;
        margin-block-end: 32px;
    }
    @media(max-width: $MYxs) {
        margin-inline: 16px;
        padding: 30px 15px;
        height: 90vw;
    }
    @media(max-width: $MYxxs) {
        margin-block-end: 32px;
        margin-inline: 8px;
        height: 100vw;
    }
    @media(max-width: calc($MYxxs - 40px)) {
        height: 110vw;
    }

    p {
        color: var(--color-button-primary);
        font-size: 128px;
        margin-block: 0 50px;
        max-width: 1145px;
        @media(max-width: 1440px) {
            max-width: 513px;
            font-size: 64px;
            margin-block-end: 30px;
        }
        @media(max-width: 1024px) {
            font-size: 48px;
        }
        @media(max-width: $MYsm) {
            &.mobile-only {
                display: block !important;
            }
            font-size: 40px;
            margin-block-end: 130px;
        }
        @media(max-width: $MYxs) {
            margin-block-end: 32px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: start;
        gap: 50px;
        width: 577px;
        @media(max-width: 1440px) {
            gap: 20px;
            width: 302px;
        }
        @media(max-width: 1024px) {}
        @media(max-width: $MYsm) {
            gap: 16px;
            width: 252px;
        }
        @media(max-width: $MYxs) {
            width: 100% !important;
        }
        @media(max-width: $MYxxs) {
            width: 100%;
            gap: 32px;
        }
    }

    img {
        position: absolute;
        z-index: 0;
        top: 222px; right: 136px;
        width: 40%;
        object-fit: cover;
        @media(max-width: 2000px) {
            max-width: 1129px; max-height: 720px;
        }
        @media (max-width: 1700px) {
            top: unset; bottom: -25%;
        }
        @media(max-width: $MYlg) {
            right: 104px;
            bottom: -30%;
        }
        @media(max-width: $MYmd) {
            width: 50%;
            right: 64px;
            bottom: -40%;
        }
        @media(max-width: $MYsm) {
            max-width: unset;
            height: auto;
            right: 24px;
        }
        @media(max-width: 664px) {
            bottom: unset; top: 0;
        }
        @media(max-width: 560px) {
            top: 16%;
        }
        @media(max-width: $MYxs) {
            width: 100%;
            object-position: 20px 0;
            top: unset; bottom: -40%;
            left: 0; right: unset;
        }
    }
}

.order-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.menu-grid {
    .item {
        .price {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .order-count {
            gap: 14px;

            svg {
                width: 50px; height: 50px;
                @media(max-width: $MYxl) {
                    width: 30px; height: 30px;
                }
                @media(max-width: $MYsm) {
                    width: 24px; height: 24px;
                }
            }
            .count {
                font-family: Manrope;
                font-size: 32px;
                font-weight: 400;
                @media(max-width: $MYxl) {
                    font-size: 20px;
                }
            }
        }
    }
}

.order-preview {
    position: fixed;
    z-index: 10;
    bottom: 10px;
    --adjustment: 40px;
    --body-gutter: 100px;
    --item-width: 23%;
    right: var(--body-gutter);
    left: calc(var(--body-gutter) + var(--item-width));

    border-radius: 10px;
    border: 1px solid #D9D9D9;
    background: #FAFAFA;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px -7px 16px 0px rgba(0, 0, 0, 0.10), 0px -28px 28px 0px rgba(0, 0, 0, 0.09), 0px -64px 38px 0px rgba(0, 0, 0, 0.05), 0px -113px 45px 0px rgba(0, 0, 0, 0.01), 0px -177px 50px 0px rgba(0, 0, 0, 0.00);

    font-size: 12px;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;

    padding: 10px 28px 11px 33px;
    @media(min-width: calc($MYxl + 1px)) {
        --item-width: 19%;
    }
    @media(max-width: $MYlg) {
        --item-width: 22%;
        padding-inline: 10px;
    }
    @media(max-width: $MYmd) {
        --body-gutter: 50px;
        --item-width: 18%;
    }
    @media(max-width: $MYsm) {
        right: 0; left: 0;
        bottom: 0;
        display: block;
    }

    > * {
        flex-shrink: 0;
    }
    .food, .drinks {
        border-left: 1px solid var(--color-separator);
        padding-inline-start: 20px;
        margin-inline-end: 20px;
        @media (max-width: 1500px) {
            margin-inline-end: 10px;
        }
        @media (min-width: 885px) and (max-width: 1399px) {
            padding-inline-start: 10px;
        }
    }

    p {
        margin-block: 0;
    }
    // ambiguous
    .title, .below {
        color: #A6A6A6;
        line-height: 100%;
    }
    .title {
        margin-block-start: 10px;
        @media (max-width: $MYlg) {
            margin-block-start: 5px;
        }
    }
    .below {
        margin-block-end: 10px;
        @media (max-width: $MYlg) {
            margin-block-end: 5px;
        }
    }
    .amount {
        margin-block: 14px;
        font-size: 20px;
        line-height: 80%;
        @media (max-width: $MYlg) {
            margin-block: 7px 9px;
            font-size: 16px;
        }
        @media (max-width: $MYmd) {
            font-size: 12px;
        }
    }
    // section:not(.total) {
    //     border-right: 1px solid var(--color-separator);
    // }
    .guests {
        margin-inline-end: 20px;
        @media (max-width: 1500px) {
            margin-inline-end: 10px;
        }
        .title {
            margin-block-end: 6px;
        }
        .order-count {
            justify-content: flex-start;
            gap: 10px;
            svg {
                width: 30px; height: 30px;
            }
        }
    }

    .total {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;
        border-left: 1px solid var(--color-separator);
        padding-inline-start: 20px;
        @media (max-width: 1400px) {
            gap: 10px;
        }
        @media (min-width: 885px) and (max-width: 1399px) {
            padding-inline-start: 10px;
        }
        @media (min-width: 885px) and (max-width: 1120px) {
            flex-direction: column;
            align-items: stretch;
        }
        @media (max-width: $MYsm) {
            flex-direction: column;
            align-items: stretch;
            justify-content: start;
            border-left: none;
            padding-inline-start: 0;
        }
        .sum {
            align-self: flex-start;
            .title {
                margin-block-end: 10px;
                @media (min-width: 885px) and (max-width: 1119px) {
                    margin-block-end: 0;
                }
            }
            .price {
                font-size: 32px;
                text-wrap: nowrap;
                @media (max-width: $MYlg) {
                    font-size: 20px;
                }
            }
            @media (max-width: 1120px) {
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: start;
                justify-content: space-between;
            }
            @media (max-width: 884px) {
                align-items: center;
                width: 100%;
            }
        }
        button {
            margin-block-start: 4px;
            font-size: 16px;
            font-weight: normal;
            padding: 20px 30px;
            @media (min-width: calc($MYlg + 1px)) and (max-width: 1500px) {
                font-size: 14px;
                padding: 10px 20px;
            }
            &.online-solutions {
                @media (min-width: 885px) and (max-width: 1399px) {
                    padding: 0 8px;
                }
                @media (min-width: 885px) and (max-width: 1120px) {
                    width: 100%
                }
            }
            svg {
                height: 56px;
                @media (max-width: 1120px) {
                    height: 40px;
                }
            }
        }
    }
}
