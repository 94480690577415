@use '../../../assets/scss/modules_try/components.scss';
@use '../../../assets/scss/modules_try/variables.scss' as *;
@use '../../../assets/scss/vars' as *;


.our-bars {
  background-color: rgba(250, 250, 250, 1);
  padding: 50px 0;

  @media (min-width: $xl) {
    padding: 190px 0 100px;
  }
  @media (min-width: $md) and (max-width: $lg) {
    padding: 125px 0 75px 0;
  }

  @media (min-width: $md) and (max-width: $lg) {
    padding: 88px 0 50px 0;
  }
  @media (min-width: $sm) and (max-width: $md) {
    padding: 68px 0 41px 0;
  }
  @media (max-width: $sm) {
    padding: 0;
  }

  .container {
    @extend %container;

    @media (min-width: $md) and (max-width: ($lg - $lg/6)) {
      margin: auto 72px;
    }
  }
  :global(.ant-collapse-content) {
    background-color: rgba(250, 250, 250, 1);
  }

  .map {
    margin-bottom: 35px;
    overflow: hidden;
  }

  .block-with-locations {
    // margin-top: 35px;
    padding-top: 35px;

    border-top: 1px solid rgba(217, 217, 217, 1);
    
    @media (min-width: $xl) {
      margin-top: 148px !important;
      padding-top: 142px;
    }

    @media (min-width: $lg) and (max-width: $xl) {
      margin-top: 100px !important;
      padding-top: 88px;
    }

    @media (min-width: $md) and (max-width: $lg) {
      margin-top: 75px !important;
      padding-top: 38px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      margin-top: 55px !important;
      padding-top: 23px;
    }
    @media (min-width: $xs) and (max-width: $sm) {
      margin-top: 34px !important;
      padding-top: 28px;
    }
    @media (max-width: $xs) {
      margin-top: 33px !important;
      padding-top: 28px;
      // gap: 18px;
      gap: 17px !important;
    }


    @media(max-width: 450px) {
      border-top: 1px solid transparent;
    }

    
    .firstColumn {
      flex-shrink: 0;
      @media (min-width: $xl) {
        margin-top: -8px;
        .franchisee-big-button {
          margin-top: 77px !important;
        }
      }

      @media (min-width: $md) and (max-width: $lg) {
        margin-top: 9px;
        .franchisee-big-button {
          margin-top: 49px !important;
        }
      }
      @media (min-width: $sm) and (max-width: $md) {
        margin-top: 14px;
  
        .franchisee-big-button {
          margin-top: 61px !important;
        }
      }

    }
  }
  .location-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
  
    @media (min-width: $xl) {
      margin-bottom: 0;
      gap: 100px;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      gap: 91px;
    }
  
    @media (min-width: $md) and (max-width: $lg) {
      width: 372px;
      gap: 82px;
    }
    @media (min-width: $md) and (max-width: ($lg - $lg/8)) {
      gap: 48px;
      // width: 372px;
      width: 320px;
    }
  
    @media (min-width: $sm) and (max-width: $md) {
      width: 302px;
      // gap: 41px;
      gap: 32px;
    }
  
    @media (min-width: ($md - $md/8)) and (max-width: ($md - $md/14)) {
      gap: 17px;
      width: 280px;
    }
    @media (min-width: $sm) and (max-width: ($md - $md/8)) {
      gap: 10px;
      width: 230px;
    }
  
  
  
    .location-icon {
      margin-top: 12px;
  
      svg {
        width: 24px;
        height: 24px;
  
        @media (min-width: $xl) {
          // width: 28.67px;
          // height: 35.83px;
          width: 43px;
          height: 43px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          // width: 28.67px;
          // height: 35.83px;
          width: 43px;
          height: 43px;
        }
  
      }
  
    }
  
    .full-address {
      display: flex;
      flex-direction: column;
      gap: 5px;
  
      @media (min-width: ($xl + $xl/6)) {
        // width: 74%;
        width: 65%;
      }
  
      @media (min-width: ($xl + $xl/8)) and (max-width: ($xl + $xl/6)) {
        width: 74%;
        // width: 65%;
      }
  
      @media (min-width: $xl) and (max-width: ($xl + $xl/8)) {
        width: 83%;
        // width: 65%;
      }
      @media (min-width: ($md - $md/8)) and (max-width: $md) {
        // max-width: 200px;
        max-width: 234px;
      }
      @media (min-width: $sm) and (max-width: ($md - $md/8)) {
        max-width: 200px;
        // max-width: 234px;
      }
  
      .wrapper-city {
        display: flex;
        flex-direction: row;
  
        .arrow-down-svg {
          display: flex;
          justify-content: center;
          align-items: center;
  
          @media (min-width: $xl) {
            margin-left: 34px;
          }
          @media (min-width: $lg) and (max-width: $xl) {
            margin-left: 32px;
          }
          @media (min-width: $sm) and (max-width: $lg) {
            margin-left: 30px;
          }
  
          svg {
            @media (min-width: $xl) {
              width: 43px;
              height: 43px;
            }
  
          }
        }
      }
      .city {
        font-family: Cakra;
        font-weight: 400;
        text-transform: uppercase;
        margin: 10px 0;
  
        @media (min-width: $xl) {
          font-size: 64px;
          line-height: 51px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          font-size: 48px;
          line-height: 39px;
        }
        @media (min-width: $sm) and (max-width: $lg) {
          font-size: 32px;
          line-height: 26px;
        }
        @media (min-width: ($md - $md/8)) and (max-width: ($md - $md/14)) {
          font-size: 30px;
        }
        @media (min-width: ($md - $md/5)) and (max-width: ($md - $md/8)) {
          font-size: 28px;
        }
        @media (min-width: $sm) and (max-width: ($md - $md/8)) {
          font-size: 26px;
        }
  
  
        @media (min-width: $xs) and (max-width: $sm) {
          font-size: 24px;
          line-height: 19px;
        }
        @media (max-width: $xs) {
          font-size: 32px;
          line-height: 26px;
        }
  
        &-active {
          color: $green;
        }
  
  
      }
      .address {
        font-family: 'Manrope';
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
  
        @media (min-width: $xl) {
          font-size: 28px;
          line-height: 37px;
          width: 71%;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          font-size: 20px;
          line-height: 30px;
        }
      }
      .phone {
        font-family: 'Manrope';
        font-weight: 400;
  
        font-size: 20px;
        line-height: 26px;
  
        @media (min-width: $xl) {
          font-size: 36px;
          line-height: 46px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          font-size: 28px;
          line-height: 46px;
        }
  
  
      }
    }
  }

  .location-wrapper-map {
    @media (min-width: $xl) {
      margin-bottom: 68px;
      // margin-bottom: 0;
    }
    // @media (min-width: $lg) and (max-width: $xl) {}
    // @media (min-width: $md) and (max-width: $lg) {}
    // @media (min-width: $sm) and (max-width: $lg) {
    @media (min-width: $sm) {
      gap: 0;
      width: 100%;
    }
  
    .arrow-down-svg {
      @media (min-width: 768px) {
        display: none;
      }
      @media (min-width: $xl) {
        svg {
          // width: 35.83px;
          // height: 35.83px;
  
          width: 43px;
          height: 43px;
        }
      }
      @media (min-width: $lg) and (max-width: $xl) {
        svg {
          width: 35.83px;
          height: 35.83px;
        }
      }
  
      @media (min-width: $sm) {
        padding-top: 12px;
        margin-left: -9px;
      }
    }
  
    .city {
      color: $green;
    }
  }

  .franchisee-big-button {
    margin-top: 15px;

    @media (min-width: $xl) {
      margin-top: 73px;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      margin-top: 67px;
    }
    @media (min-width: $md) and (max-width: $lg) {
      margin-top: 60px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      margin-top: 74px;
      // width: max-content;
    }

    // button {
    :global(.ant-btn) {
      width: 100%;
    }
    @media (max-width: $sm) {
      display: none;
    }
  }
  .franchisee-small-button {
    @media (min-width: $sm) {
      display: none;
    }
  }
  .main-locations {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (min-width: $xl) {
      width: 66.25% !important;
      gap: 97px;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      gap: 72px;

      // .locations {
      //   width: 66.25% !important;
      // }
    }

    @media (min-width: $md) and (max-width: $lg) {
      gap: 50px;

      .locations {
        width: 66.25% !important;
      }

    }
    @media (min-width: $sm) and (max-width: $md) {
      gap: 12px;
    }

    @media (min-width: $xs) and (max-width: $sm) {
      gap: 10px;
      margin-bottom: 30px;
    }
    @media (max-width: $xs) {
      gap: 5px;
      margin-bottom: 30px;
    }

    .yandex-img-wrapper {
      svg {
        @extend %yandexSvg;
      }
    }

    @media (max-width: $sm) {
      .main-locations-buttons {
        display: flex;
        width: 100%;
        border-top: 1px solid rgba(217, 217, 217, 1);
        padding-top: 35px;
        margin-top: 35px;


        .franchisee-small-button, .yandex-food-button {
          width: 100%;
          :global(.ant-btn) {
            width: 100%;
          }
        }
      }
    }

    .yandex-food-button {
      :global(.ant-btn) {
      // .ant-btn {

        background: rgba(249, 146, 0, 1);
        border-radius: 10px;

        @media (min-width: $xl) {
          border-radius: 20px;
          height: 178px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          height: 134px;
        }

        @media (min-width: $sm) and (max-width: $lg) {
          height: 100px;
        }

      }
    }

    .main-locations-buttons {
      @media (min-width: $xs) and (max-width: $sm) {
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        padding-top: 31px;
        margin-top: 23px;
      }
      @media (max-width: $xs) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // gap: 10px;
        gap: 32px;
        padding-top: 32px;
        margin-top: 26px;
      }

    }
  }
  .wrapperColumns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .firstColumn {
      width: 25%;
      @media (max-width: $sm) {
        width: 50%;
      }
      @media (min-width: $sm) and (max-width: $md) {
        width: 26.5% !important;
      }
    }

    .secondColumn {
      width: 75%;
      &.main-locations {
        width: auto;
        padding-inline-start: 10%;
        @media (max-width: 768px) {
          padding-inline-start: unset;
        }
      }
      @media (min-width: $sm) and (max-width: $md) {
        width: 66.5% !important;
      }
      .form-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: end;

        @media (min-width: ($md + 1px)) and (max-width: $lg) {
          margin-top: 26px;
        }


        @media (max-width: $sm) {
          justify-content: center;
        }

        form {
          display: flex;
          flex-direction: column;
          gap: 32px;
          margin-top: -16px;

          @media (min-width: ($md + 1px)) and (max-width: $lg) {
            margin-top: 10px;
            gap: 21px;
          }


          input {
            box-sizing: border-box;
            width: 100%;
          }

          :global(.ant-form-item) {
            :global(.ant-checkbox-wrapper) {
              margin: 24px 0;

              :global(.ant-checkbox) {

                &:hover {
                  // border: 1px solid green;
                  // outline: #41B64D;
                }
                // &:focus-visible {}

              }
              // .ant-checkbox-input:focus-visible {}
              :global(.ant-checkbox-checked) :global(.ant-checkbox-inner) {
                background-color: #41B64D;
                border-color: transparent;
                &:hover {
                  background-color: #64c96e;
                }
              }
            }
          }

          :global(.ant-btn) {
            // @extend %greenButton;
            margin: 30px 0;
          }
        }
      }
    }

  }
  .wrapperColumns {
    display: flex;
    flex-direction: row;
    &.block-with-locations {
      justify-content: flex-start;
    }

    @media (max-width: $sm) {
      flex-direction: column;
      gap: 30px
    }
    @media (min-width: $xs) and (max-width: $sm) {
      gap: 16px;
    }


    .firstColumn {
      width: 25%;

      @media (min-width: $xs) and (max-width: $sm) {
        width: 45%;
      }
    }
  }

  .current-city-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 15px;

    @media (min-width: $lg) and (max-width: $xl) {
      gap: 20px;
    }

  @media (min-width: $xl) {
    .greenButton, .whiteButton {
      button {
        width: 90%;
      }
    }

    .whiteButton {
      button {
        margin-top: 22px;
      }
    }
  }
  @media (min-width: $md) and (max-width: $lg) {
    gap: 12px;

    .greenButton {
      // margin-top: 31px;
      margin-top: 26px;
    }

    .greenButton, .whiteButton {
      button {
        padding: 10px 28px;
      }
    }
  }
  .greenButton {
    @media (min-width: $md) and (max-width: ($lg - $lg/6)) {
      width: 100% !important;
    }
    
    button {
      @media (min-width: $md) and (max-width: ($lg - $lg/6)) {
        width: 100% !important;
      }
    }
  }

  .greenButton, .whiteButton {
    @media (max-width: $MYlg) {
      width: 100% !important;
    }
  }

  @media (min-width: $sm) and (max-width: $md) {
    gap: 10px;
  }
  }

  .wrapper-city-image {
    width: 100%;
    height: 100%;
    margin-top: 15px;

    @media (max-width: $sm) {
      height: 447px;
      // margin-top: 24px;
      margin-top: 27px;
      margin-bottom: 16.5px !important;
    }
  }
  

  .titleSection {
    @extend %title;
  }

  .greenButton {
    button {


      @extend %greenButton;
      display: flex;
      flex-direction: row;
      gap: 5px;
      @media (min-width: ($sm + $sm/4)) and (max-width: $md) {
        // padding: 18px 3px;
        width: 100%;
        margin-top: 29px;


      }
      @media (min-width: $sm) and (max-width: ($sm + $sm/4)) {
        height: max-content;
        width: 167px;
        padding: 18px 3px;
        flex-direction: column;
      }


      // @media (min-width: $sm) and (max-width: $md) {
      //   height: max-content;
      //   width: 167px;
      //   padding: 18px 3px;
      //   flex-direction: column;
      // }
    }
  }

  .whiteButton {
    button {
      @extend %whiteButton;
    }
  }

  .yandex-food-button {
    :global(.ant-btn) {
      @extend %yandexButton;
    }
  }
}
