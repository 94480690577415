@use './vars' as *;
@import './variables.scss';
@import './mixins.scss';

%greenButton { // .ant-btn
  font-family: 'Manrope';
  background-color: #41B64D;
  color: white;
  border: 1px solid transparent;
  border-radius: 100px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;

  &:hover {
    background-color: #64c96e !important;
    border: 1px solid transparent !important;
    color: white !important;
  }

  @media (min-width: $xl) {
    border-radius: 190.02px;
    height: 100px;
    font-size: 32px;
    line-height: 32px;
  }
  @media (min-width: $lg) and (max-width: $xl) {
    font-size: 22px;
    &:not([data-magic="правим-уродства"]) {
      padding: 32px 30px;
    }
  }
  @media (min-width: $md) and (max-width: $lg) {
    padding: 10px 28px;
  }
  @media (min-width: $sm) and (max-width: $md) {
    padding: 10px 30px;
  }
}

%whiteButton {
  font-family: 'Manrope';
  background-color: white;
  color: $green;
  border: 1px solid $green;
  border-radius: 100px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;


  &:hover {
    background-color: #64c96e;
    color: white;
    border: 1px solid transparent;
  }

  @media (min-width: $xl) {
    border-radius: 190.02px;
    height: 100px;
    font-size: 32px;
    line-height: 32px;
  }
  @media (min-width: $lg) and (max-width: $xl) {
    font-size: 22px;
  }
  @media (min-width: $md) and (max-width: $lg) {
    padding: 10px 28px;
  }
  @media (min-width: $sm) and (max-width: $md) {
    padding: 10px 30px;
  }

}

%yandexButton {
  width: 100%;
  background-color: rgba(249, 146, 0, 1);
  border-radius: 10px;

  &:hover, &:active {
    background-color: rgb(255, 171, 52);
    border: 1px solid transparent;
  }

  @media (min-width: $xl) {
    height: 178px;
    border-radius: 20px;
  }
  @media (min-width: $md) and (max-width: $xl) {
    height: 100px;
  }
  @media (max-width: $md) {
    height: 52px;
  }
}

%title {
  font-family: Cakra;
  font-weight: 400;
  line-height: 102px;
  text-transform: uppercase;
  
  @media (min-width: ($xl + $xl/12)) {
    font-size: 128px;
  }
  @media (min-width: $xl) and (max-width: ($xl + $xl/12)) {
    font-size: 115px;
  }

  @media (min-width: $lg) and (max-width: $xl) {
    font-size: 92px;
  }
  @media (min-width: $md) and (max-width: $lg) {
    font-size: 64px;
    line-height: 51px;
  }
  @media (min-width: $md) and (max-width: ($lg - $lg/6)) {
    font-size: 51px;
    line-height: 51px;
  }
  //font-size: 48px;
  @media (max-width: $md) {
    font-size: 48px;
    line-height: 38px;
  }
}

%link {
  font-family: 'Manrope';
  font-weight: 500;
  color: rgba(19, 19, 19, 1);
  text-decoration: none;
  transition: all 0.4s ease-out;

  &:hover {
    color: $green;
  }

  @media (min-width: ($xl + $xl/4)) {
    font-size: 32px;
    line-height: 32px;
  }
  @media (min-width: $xl) and (max-width: ($xl + $xl/4)) {
    font-size: 29px;
    line-height: 29px;
  }
  @media (min-width: $lg) and (max-width: ($xl - $xl/4)) {
    font-size: 27px;
    line-height: 27px;
  }
  @media (min-width: $lg) and (max-width: $xl) {
    font-size: 24px;
    line-height: 24px;
  }
  @media (min-width: $md) and (max-width: $lg) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (min-width: $sm) and (max-width: $md) {
    font-size: 14px;
    line-height: 14px;
  }

}

%yandexSvg {
  @media (min-width: $xl) {
    height: 77px;
    width: 348px;
  }
  @media (min-width: $lg) and (max-width: $xl) {
    height: 77px;
    width: 348px;
  }
  @media (min-width: $sm) and (max-width: $lg) {
    height: 43.12px;
    width: 196px;
  }
  @media (min-width: $xs) and (max-width: $sm) {
    height: 37.49px;
    width: 101.15px;
  }
  @media (max-width: $xs) {
    height: 21.48px;
    width: 97.64px;
  }
}

%social {
  // background-color: red;
  cursor: pointer;
  svg {
    width: 20px;
  }
  @media (min-width: $xl) {
    width: 54px;
    height: 54px;
    svg {
      width: 54px;
      height: 54px;
    }
  }
  
  @media (max-width: $MYxl) {
    width: 30px;
    height: 30px;
  }
}

%pickerIcon {
  width: 24px;
  height: 24px;

  svg {
    width: inherit;
    height: inherit;
  }

  @media (min-width: $xl) {
    width: 50px;
    height: 50px;

  }
  @media (min-width: $lg) and (max-width: $xl) {
    width: 37px;
    height: 37px;
  }
}

%container {
  @media (min-width: $xl) {
    margin: 0 $containerXxl;
  }
  @media (min-width: $lg) and (max-width: $xl) {
    margin: 0 $containerXl;
  }
  @media (min-width: $md) and (max-width: $lg) {
    // margin: 0 $containerLg;
    margin: 0px 100px;

  }
  @media (min-width: $sm) and (max-width: $md) {
    margin: 0 $containerMd;

  }
  @media (min-width: $xs) and (max-width: $sm) {
    margin: 0 $containerSm;

  }
  // @media (min-width: 320px) and (max-width: $xs) {
  @media (max-width: $xs) {
    margin: 0 $containerXs;
  }
  // @media (max-width: 320px) {
  //   margin: 0 7px;
  // }
}

%formElement { // .ant-form .ant-select/.ant-input
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 10px;
  height: 60px;
  font-size: 16px;

  @media (min-width: $xl) {
    height: 130px;
    border-radius: 20px;
    font-size: 24px;
  }
  @media (min-width: $xl) {
    font-size: 32px;
  }

  @media (min-width: $lg) and (max-width: $xl) {
    height: 95px;
    border-radius: 15px;
  }
  @media (min-width: $md) and (max-width: $lg) {
    height: 60px;
    border-radius: 10px;
  }
  @media (min-width: $xs) and (max-width: $sm) {
    padding: 4px 25px;
  }

  @media (max-width: $xs) {
    padding: 4px 23px;
  }
}

%label { //.ant-form
  .ant-form-item-row {
    position: relative;

    .ant-form-item-label {
      position: absolute;
      z-index: 10;
      top: -12px;
      left: 16px;

      @media (min-width: $xl) {
        top: -14px;
        left: 47px;
      }


      label {
        font-family: 'Manrope';
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        height: 15px;
        padding-left: 8px;
        background-color: white;
        color: rgba(166, 166, 166, 1);
      
        @media (min-width: $xl) {
          font-size: 24px;
          line-height: 31px;
          height: 30px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          font-size: 18px;
          line-height: 22px;
          height: 25px;
        }   
        @media (min-width: $md) and (max-width: $lg) {
          font-size: 12px;
          line-height: 16px;
          height: 15px;
        }

      }
    }
  }
        
}

%checkbox { //.ant-form
      //Checkbox
  .ant-checkbox-wrapper {


    &:hover {
      .ant-checkbox {

        .ant-checkbox-inner {
          &:hover {
            // background-color: $light-green !important;
            background: $light-green ;
          }
        }
      }
    }

    span {
      font-family: 'Manrope';
      font-weight: 400;

      @media (min-width: $xl) {
        font-size: 32px;
        line-height: 42px;            
      }
      @media (min-width: $lg) and (max-width: $xl) {
        font-size: 24px;
        line-height: 24px;
      }
      
      
      &:nth-of-type(2n+2) {
        @media (min-width: $xl) {
          margin-left: 14px;
        }
        @media (min-width: $sm) and (max-width: $md) {
          padding-inline-start: 11px;
        }
        @media (min-width: $xs) and (max-width: $sm) {
          padding-inline-start: 12px;
        }
        @media (max-width: $xs) {
          padding-inline-start: 10.5px;
        } 


      }
      // :last-child {
      //   @media (min-width: $xl) {
      //     // padding-left: 37px;
      //   }

      // }

    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        // background-color: $green;
        background: $green !important;
        border-color: $green;

        @media (min-width: $xl) {
          width: 42px;
          height: 42px;
          margin: 7px;
          border-radius: 5px;
        }
        @media (min-width: $sm) and (max-width: $md) {
          width: 18px;
          height: 18px;
          border-radius: 3px;
          // margin: 4px 0 0 4px;
          // margin-top: 0px;
        }
        @media (max-width: $sm) {
          width: 18px;
          height: 18px;
          border-radius: 3px;
          margin: 4px 0 0 4px;

          margin: 4px;
          // margin-top: 0px;
        }
        // @media (max-width: $xs) {}


      }
    }
    .ant-checkbox {
      &:hover {

        .ant-checkbox-inner {
          border-color: $light-green !important;
        }
      }


      @media (min-width: $xl) {
        // width: 40px;
        // height: 40px;

        width: 52px;
        height: 52px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        width: 25px;
        height: 25px;
      }

      input {
        @media (min-width: $xl) {
          // width: 40px;
          // height: 40px;

          width: 52px;
          height: 52px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          width: 25px;
          height: 25px;
        }
        @media (max-width: $md) {
          // width: 18px;
          // height: 18px;
          width: 24px;
          height: 24px;
          margin-top: -2px;
        }

      }
      &-inner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 8px;


        @media (min-width: $xl) {
          // width: 40px;
          // height: 40px;

          width: 52px;
          height: 52px;
          top: -5px;

          &::after {
            inset-inline-start: 39.5%;
          }
        }
        @media (min-width: $lg) and (max-width: $xl) {
          width: 25px;
          height: 25px;
          &::after {
            inset-inline-start: 32.5%;
          }
        }
        @media (min-width: $sm) and (max-width: $md) {
          border-radius: 4px;

        }
        @media (max-width: $md) {
          // width: 18px;
          // height: 18px;

          width: 24px;
          height: 24px;
          margin-top: -2px;
          border-radius: 4px;
        }
        @media (max-width: $sm) {
          border-radius: 3px;
        }

      }

    }
  }
}

%select {
  .ant-select {
    width: 100%;
    padding-inline: 0 !important;
    .ant-select-selector {
      border-radius: 10px;
      @media (min-width: $xl) {
        padding: 0 54px;
        font-size: 34px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        padding: 0 40px;
        font-size: 25px;
      }
      @media (min-width: $md) and (max-width: $lg) {
        padding: 0 24px;
        font-size: 16px;
      }
      @media (min-width: $sm) and (max-width: $md) {
        padding: 0 23px;
      }
      // ну как же надоело
      // @media (max-width: $xs) {
      //   padding: 0;
      // }

      .ant-select-selection-search {
        @media (min-width: $lg) {
          border-radius: 20px;
          // padding: 0 30px !important;
        }

        input {
          @media (min-width: $xl) {
            font-size: 32px;
          }
          @media (min-width: $lg) and (max-width: $xl) {
            font-size: 24px;
          }
        }
      }

      .ant-select-selection-item {
        @media (min-width: $sm) and (max-width: $md) {
          font-size: 18px !important;
        }
      }
    }
    .arrow-down-svg {
      @media (min-width: $xl) {
        margin-right: 37px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        margin-right: 20px;
      }
      @media (min-width: $md) and (max-width: $lg) {
        margin-right: 8px;
      }
      @media (min-width: $sm) and (max-width: $md) {
        margin-right: 8px;
      }
      @media (min-width: $xs) and (max-width: $sm) {
        margin-right: 8px;
      }
      @media (max-width: $xs) {
        margin-right: 8px;
      }

      svg, img {
        width: 24px;
        height: 24px;

        @media (min-width: $xl) {
          width: 50px;
          height: 50px;
        }

        @media (min-width: $lg) and (max-width: $xl) {
          width: 37px;
          height: 37px;
        }
      }
    }
  }
}

%prefixPhone {
  .ant-input-group-addon {
    background-color: white;
    color: $gray;
    border-radius: 10px 0 0 10px;

    @media (min-width: $xl) {
      padding-left: 44px;
    }
    input {
      padding-inline-start: 16px !important;
    }
    #prefix {
      font-family: 'Manrope';

      @media (min-width: $xl) {
        font-size: 32px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        font-size: 24px;
        padding-left: 1.5vw;
      }
      @media (max-width: $lg) {
        font-size: 16px;
        padding-left: 25px;
      }
      // @media (min-width: $sm) and (max-width: $md) {
      //   font-size: 16px;
      //   padding-left: 1vw;
      // }
      // @media (min-width: $xs) and (max-width: $sm) {}
      @media (max-width: $xs) {
        padding-left: 13px;
      }
    }
  }
}

// .swiper-button-prev2, .swiper-button-next2 !!!
//1) Наполнение .swiper-button-prev2, .swiper-button-next2 (внутри блока .custom-swiper-1)
%swiperButtonInsideArrow {
  background-color: #fff;
  // background-color: black;
  color: $green;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09);

  @media (min-width: ($xl + $xl/4)) {
    width: 113px;
    height: 113px;
  }

  @media (min-width: $xl) and (max-width: ($xl + $xl/4)) {
    width: 113px*0.7;
    height: 113px*0.7;
  }

  @media (min-width: $lg) and (max-width: $xl) {
    width: 113px*0.6;
    height: 113px*0.6;
  }

  @media (min-width: $md) and (max-width: $lg) {
    width: 60px;
    height: 60px;
  }
  @media (min-width: $sm) and (max-width: $md) {
    width: 42px;
    height: 42px;
  }
}

//2) Наполнение .swiper-button-prev2, .swiper-button-next2 (снаружи блока .custom-swiper-1)
%swiperButtonOutsideArrow {
  position: absolute;
  z-index: 100;
  top: 45%;
  // top: 42.6%;

  @media (min-width: ($xl + $xl/4)) {
    top: 42.3%;
  }
  @media (min-width: $md) and (max-width: ($xl + $xl/4)) {
    top: 42.5%;
  }
  @media (min-width: $sm) and (max-width: $md) {
    top: 42.6%;
  }


  &::after {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 7px;

    @media (min-width: $xl) {
      margin-top: 0px !important;
      padding-top: 2px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      padding-top: 9px;
    }


  }
}

//3) Разделение. Разные значения для next и prev стрелки
//3.1 Наполнение .swiper-button-prev2 (вне? блока, чтобы было везде)
%swiperButtonPrev {
  @media (min-width: ($xl + $xl/4)) {
    left: -47px;
  }

  @media (min-width: $md) and (max-width: ($xl + $xl/4)) {
    left: -31px !important;
  }

  @media (min-width: ($md - 2)) and (max-width: $xl) {
    left: -23px;
  }

  @media (max-width: ($md - 2)) {
    display: none;
  }


  &::after {
    @media (min-width: $xl) {
      margin-top: 6px;
      // content: url("../../../assets/images/arrowLeft45.png");
      content: url("../images/arrowLeft45.png");
    }
    @media (min-width: ($md - 1)) and (max-width: $xl) {
      margin-top: -6px;
      // content: url("../../../assets/images/arrowLeft24.png");  
      content: url("../images/arrowLeft24.png");  
    }
  }
}
//3.2 Наполнение .swiper-button-next2 (вне? блока, чтобы было везде)
%swiperButtonNext {
  @media (min-width: ($xl + $xl/4)) {
    right: -56px;
  }

  @media (min-width: $md) and (max-width: ($xl + $xl/4)) {
    right: -31px !important;
  }

  @media (min-width: ($md - 2)) and (max-width: $xl) {
    right: -20px;
  }

  @media (max-width: ($md - 2)) {
    display: none;
  }

  &::after {
    @media (min-width: $xl) {
      margin-top: 6px;
      // content: url("../../../assets/images/arrowRight45.png");
      content: url("../images/arrowRight45.png");
    }
    @media (min-width: ($md - 1)) and (max-width: $xl) {    
      margin-top: -6px;
      // content: url("../../../assets/images/arrowRight24.png");
      content: url("../images/arrowRight24.png");
    }
  }
}
//todo: тоже самое для буллетов пагинации

.ant-modal:has(.modal-mobile-closer):not([data-modal="event-details"])
, .ant-modal-root:has(.modal-mobile-closer):not([data-modal="event-details"]) {
  bottom: 0;
  // top: unset;
  // position: absolute;
  .ant-modal-close {
    display: contents;
  }
}
.modal-mobile-closer {
    --width: 25%;
    width: var(--width); height: 4px;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: -12px; left: calc(50% - var(--width) / 2);
}
