.geolocation-request {
    section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            gap: 1em;
        }
    }
}
