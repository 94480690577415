@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/components.scss';

.footer {
  border-top: 1px solid rgba(217, 217, 217, 1);
  background-color: rgba(250, 250, 250, 1);

  display: flex;
  flex-direction: column;
  // background-color: #FFFFFF;
  // width: 100% !important;
  width: 100%;
  // margin: 35px 5.2% 5px;
  padding-top: 35px;
  padding-bottom: 5px;

  @media (min-width: $xl) {
    padding-top: 9px;
    padding-bottom: 40px;
    gap: 37px;
  }
  
  @media (min-width: $lg) and (max-width: $xl) {
    padding-top: 30px;
  }

  @media (min-width: $md) and (max-width: $lg) {
    padding-top: 30px;
  }
  @media (min-width: $sm) and (max-width: $md) {
    padding-top: 31px;
  }

  @media (max-width: $sm) {
    padding-top: 23px;
  }
  @media (max-width: $xs) {
    padding-top: 20px;
    padding-bottom: 0;
    // margin-top: 147px;
    margin-top: 0px;
    // margin-top: 2px;
  }
  
  .footerInner {
    // margin: 0 5%;
    // width: 86.4% !important;
    width: 86.5% !important;
    margin: 0 auto;

    // border-color: red;
    // background-color: rgb(242, 75, 75);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  
    // border-radius: 72px;
    // box-shadow: 0px 64.6363677979px 143.6363677979px 0px rgba(0, 0, 0, 0.1019607843);
    width: 100%;
  
    @media (min-width: $xl) {
      height: 140px;
      width: 92.45% !important;
      // margin: 0 3.9%;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      height: 140px;
    }
    @media (min-width: $sm) and (max-width: $lg) {
      height: 80px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      width: 90.4% !important;
    }
    @media (min-width: $xs) and (max-width: $sm) {
      width: 91.7% !important;
    }

    @media (max-width: $sm) {
      flex-direction: column;
      gap: 29px;
    }
    @media (max-width: $xs) {
      width: 92.2% !important;
      gap: 13px;
    }
  
    &__navLinkActive {
      color: $green !important;
    }
  }

  .secondRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // margin: 0 5%;
    // width: 86.4% !important;
    width: 86.5% !important;
    margin: 0 auto;
    margin-bottom: 25px;

    @media (min-width: $xl) {
      // margin: 0 4%;
      width: 92.4% !important;

      .years {  
        font-size: 32px;
        line-height: 42px;
      }

      .linza-svg {
  
        svg {
          width: 300px;
          height: 40px;
        }
      }
    }
    @media (min-width: $md) and (max-width: $lg) {
      margin-top: 5px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      width: 90.4% !important;
      margin-top: 3px;

    }
    @media (min-width: $xs) and (max-width: $sm) {
      width: 91.7% !important;
    }
    @media (max-width: $xs) {
      width: 90.5% !important;
      gap: 6px !important;
    }

    // @media (max-width: $xs) {
    @media (max-width: 650px) {
      flex-direction: column;
      align-items: start;
      gap: 10px;
      margin-top: 15px;

      .linza-svg {
        width: 100%;
        display: flex;
        flex-direction: row;
        
        display: flex;
        justify-content: center;

        @media (max-width: $xs) {
          margin-top: 38px;
          justify-content: end;

        }

      }
    }

    .footer-logo {
      @media (min-width: $sm) {
        display: none;
      }

      svg {
        // @media (max-width: $sm) {
        //   width: 89px;
        //   height: 32px;
        // }
        @media (max-width: $sm) {
          width: 105px;
          height: 38px;
        }
        
      }
    }
  }
}
