@use "../../shared/EventModal/eventInfo";

.event-details-wrapper {
    [data-event-details="info"] {
        @extend %eventInfo;
        color: black;
        padding: 0;
        [data-info="constraints"] {
            font-size: 16px;
        }
    }   
}
