@use "../../assets/scss/vars" as *;

section[data-section="events"] {
    margin-inline: 100px;
    @media(max-width: $MYlg) {
        padding-block-start: 100px; 
    }
    @media(max-width: $MYmd) {
        margin-inline: 50px;
        padding-block-start: 80px;
    }
    @media(max-width: $MYsm) {
        margin-inline: 32px;
        padding-block-start: 0;
        border-top: 1px solid #D9D9D9;
    }
    @media(max-width: $MYxs) {
        margin-inline: 16px;
        padding-block-end: 32px;
        border-bottom: 1px solid #D9D9D9;
    }
    .custom-swiper-1 {
        @media(max-width: $MYsm) {
            margin-block-start: 32px;
        }
    }
    button {
        width: 100%;
        margin-top: 100px;
        @media(max-width: $MYlg) {
            margin-top: 50px;
        }
        @media(max-width: $MYmd) {
            margin-top: 32px;
        }
        @media (max-width: $MYsm) {
            display: none;
        }
    }
}
