@use "../../assets/scss/vars" as *;
@use "../../assets/scss/components1";

.hero-bar-dance {
    border-radius: 10px;
    background: url("../../assets/img/bar/dance.jpeg") center/cover no-repeat;
    padding: 112px;
    @media(max-width: 1440px) {
        padding: 60px;
    }
    @media(max-width: 1024px) {
        padding: 30px;
    }
    @media(max-width: 768px) {}
    @media(max-width: 376px) {
        padding-inline: 15px;
    }
    p:first-of-type {
        color: white;
        margin-block-end: 244px;
        @media(max-width: 1440px) {
            margin-block-end: 130px;
        }
        @media(max-width: 1024px) {}
        @media(max-width: 768px) {
            margin-block-end: 150px;
        }
        @media(max-width: 376px) {
            margin-block-end: 270px;
        }
    }
}
