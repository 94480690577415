// $dark: #303846;
// $light: #f7f7f7;
// $primary: #09d3ac;
// $secondary: #2196f3;
$pink: #F14B95;
$green: #41B64D;
$light-green: #64c96e;
$gray: rgba(166, 166, 166, 1);
$dark-blue: rgba(28, 39, 76, 1);




// $black: 
// $xxl: 2560px;
// $xl: 2000px;
// $lg: 1440px;
// $md: 1024px;
// $sm: 768px;
// $xs: 375px;

$xxl: 2561px;
$xl: 2001px;
$lg: 1441px;
$md: 1025px;
$sm: 769px;
$xs: 376px;

$containerXxl: 100px; //xl 95
$containerXl: 100px; //lg-xl
$containerLg: 60px; //md-lg
$containerMd: 50px; //sm-md
$containerSm: 32px; //xs-sm
$containerXs: 16.5px; //xs

  // @media (min-width: $xl) {}
  // @media (min-width: $lg) and (max-width: $xl) {}
  // @media (min-width: $md) and (max-width: $lg) {}
  // @media (min-width: $sm) and (max-width: $md) {}
  // @media (min-width: $xs) and (max-width: $sm) {}
  // @media (max-width: $xs) {}
