@use "../../../assets/scss/vars" as *;
@use "./eventInfo";

.modal-wrapper {
    :global(.ant-modal-content) {
        background-color: transparent;
        padding: 0;
    }
}

.event-details {
    width: 100%;
    max-width: 1440px;

    @media(max-width: $MYlg) {
        width: auto; margin-inline: auto;
        max-width: 80%;
    }
    .content {
        padding-block: 50px 112px;
        @media (min-width: $MYxs) {
            padding-inline: 60px;
        }
        margin-inline: auto;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;

        background-color: black;

        gap: 114px;
        @media(max-width: $MYlg) {
            gap: 60px;
            padding-block: 32px 0;
        }
        @media(max-width: $MYmd) {
            gap: 30px;
            padding-block: 16px;
        }
        @media(max-width: $MYsm) {
            flex-direction: column;
            align-items: stretch;
        }
        @media(max-width: $MYxs) {
            padding-block: 0;
            background-color: white;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
        }
        img {
            border-radius: 10px;
            @media(max-width: $MYmd) {
                width: 50%; height: auto;
            }
            @media(max-width: $MYsm) {
                width: 100%;
                max-height: 40vh;
                min-height: 500px;
                object-fit: contain;
            }
            @media(max-width: $MYxs) {
                width: 100%;
                max-height: unset;
                min-height: unset;
                object-fit: cover;
            }            
        }
        .info {
            @extend %eventInfo;
        }
    }
}
