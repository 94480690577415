@use "./vars" as *;
@use "scrollTop";

button, .button {
	border: none;

	font-weight: 600;
	border-radius: 72px;
	padding: 34px 54px;
	font-size: 32px;
	line-height: 32px;

	@media (max-width: 1440px) {
		padding: 20px 30px;
		font-size: 16px;
		line-height: 16px;
	}
	&.primary {
		background-color: var(--color-button-primary);
		color: white;
	}
	&.secondary {
		border: 2px solid var(--color-button-primary);
		background-color: transparent;
		color: var(--color-button-primary);
		padding-block: 32px;
		@media (max-width: 1440px) {
			border-width: 1px;
			padding-block: 18px;
		}
	}
	&.text {
		padding: 0;
		font-weight: 400;
		border: none;
		background-color: transparent;
		color: var(--color-button-primary);
		text-align: center;
	}
}
fieldset {
	--padding: 32px;
	position: relative;
	z-index: 10;
	padding: var(--padding);
	border: 2px solid var(--color-separator);
	border-radius: 20px;
	font-size: 24px;
	@media (max-width: 1440px) {
		--padding: 24px;
		font-size: 12px;
	}
	@media (max-width: 768px) {
		--padding: 12px;
	}
	&.number {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		input {
			width: 18px;
		}
		i.icon {
			position: initial;
			margin: 0 !important;
		}
	}
	legend {
		padding-inline: 10px;
		@media (max-width: 1440px) {
			padding-inline: 5px;
		}
	}
	input, textarea {
		width: 100%;
		border: none;
		font-family: inherit;
		font-size: 32px;
		background-color: transparent;
		@media (max-width: 1440px) {
			font-size: 16px;
		}
	}
	i.icon {
		position: absolute;
		z-index: 20;
		&.right {
			right: 0;
			margin-inline-end: 24px;
			@media(max-width: 1440px) {
				margin-inline-end: 16px;
			}
			@media(max-width: 1024px) {}
			@media(max-width: 768px) {}
			@media(max-width: 376px) {}
		}
		&.left {
			left: 0;
			margin-inline-start: 24px;
			@media(max-width: 1440px) {
				margin-inline-start: 16px;
			}
			@media(max-width: 1024px) {}
			@media(max-width: 768px) {}
			@media(max-width: 376px) {}
		}
		top: 24px;
		@media(max-width: 1440px) {
			top: var(--padding);
		}
		@media(max-width: 1024px) {}
		@media(max-width: 768px) {}
		@media(max-width: 376px) {}
		svg {
			width: 50px; height: 50px;
			@media(max-width: 1440px) {
				width: 24px; height: 24px;
			}
			@media(max-width: 1024px) {}
			@media(max-width: 768px) {}
			@media(max-width: 376px) {}
		}
	}
}
input[type="checkbox"] {
	appearance: none;
	-webkit-appearance: none;
	width: 46px; height: 46px;
	border: 1px solid var(--color-separator);
	border-radius: 10px;
	@media (max-width: 1440px) {
		width: 24px; height: 24px;
		border-radius: 5px;
	}
	flex-shrink: 0;
	&:checked {
		background: url("../../assets/img/checkmark.svg") center/14px 12px no-repeat, var(--color-button-primary);
	}
}

hr {
	height: 1px;
	background-color: var(--color-separator);
}

.chips {
	display: flex;
	width: max-content;
	white-space: nowrap;
	padding: 15px 25px;
	justify-content: space-between;
	align-items: flex-start;
	flex-shrink: 0;
	border-radius: 38px;
	background-color: var(--color);
	color: white;
	font-weight: 500;
	font-size: 24px;
	@media (max-width: 1440px) {
		padding: 10px 15px;
		font-size: 12px;
	}
}

.logo {
	display: contents;
}
.logo svg {
	width: 150px;
	height: 54px;
	flex-shrink: 0;
	@media screen and (max-width: 1440px) {
		width: 83px;
		height: 30px;
		min-width: 82px;
		max-height: 39px;
	}
}
.phone {
	flex-shrink: 0;
	@media (max-width: 1440px) {
		margin-inline-end: 8px;
	}
}
.social {
	flex-shrink: 0;

	width: 54px; height: 54px;
	@media(max-width: 1440px) {
		width: 30px; height: 30px;
	}
	// @media(max-width: 1024px) {}
	@media(max-width: 768px) {
		width: 15px; height: 15px;
	}
	@media (max-width: 488px) {}
	@media(max-width: 376px) {}
}

header, footer .menu {
	font-weight: 600;
	.phone {
		margin-inline: 16px;
	}
}

p.full-screen {
	margin-inline: -100px;
	@media (max-width: 1024px) {
		margin-inline: -50px;
	}
	@media (max-width: 768px) {
		margin-inline: -32px;
	}
	@media (max-width: 376px) {
		margin-inline: -16px;
	}
	max-width: 100vw;
	height: max-content;
	line-height: 120%;
	white-space: nowrap;
	overflow-x: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
}

.date-time {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	fieldset {
		&.date {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		&.time {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}

.hero-general {
	position: relative;
    z-index: 2;
    overflow-x: hidden;
	article {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: space-between;

		margin-bottom: 5%;

		border-radius: 20px;
        background: linear-gradient(268deg, #181C26 0.65%, #2F3649 96.08%);

		> :first-child {  // cool
			// padding: 180px 90px 90px;
			// @media(max-width: 1440px) {
			// 	padding: 74px 45px 84px;
			// }
			// @media(max-width: 1024px) {
			// 	padding: 60px 30px 30px;
			// }
			// @media(max-width: 768px) {
			// 	padding: 16px 32px;
			// }
			// @media(max-width: 376px) {
			// 	padding: 30px 16px 160px;
			// }
			p:first-of-type {
				color: var(--color-button-primary);
			}
			button {
                margin-block: 4px;
				white-space: nowrap;
            }
		}
		p {
            color: var(--color-button-primary);
		}
		img {
            width: 100%;
            object-fit: contain;
			position: relative;
			z-index: 3;
        }
	}
}

.slider-cards {
	img {
		object-fit: cover;
	}
	.button-prev {
		position: absolute;
		top: 45%;
		left: -30px;
		z-index: 1;
		flex-shrink: 0;
		max-height: initial;
		border-radius: 50%;
		box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.09), 0px 10px 6px 0px rgba(0, 0, 0, 0.05), 0px 18px 7px 0px rgba(0, 0, 0, 0.01), 0px 28px 8px 0px rgba(0, 0, 0, 0.00);
		background: no-repeat center/50% url("../../assets/img/arrow-left.svg"), white;
		width: 60px; height: 60px;
		@media(max-width: 1440px) {
			width: 40px; height: 40px;
			left: -20px;
		}
		@media(max-width: 1024px) {}
		@media(max-width: 884px) {}
		@media(max-width: 376px) {}
	}
	.button-next {
		position: absolute;
		top: 45%;
		right: -30px;
		z-index: 1;
		flex-shrink: 0;
		max-height: initial;
		border-radius: 50%;
		box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.09), 0px 10px 6px 0px rgba(0, 0, 0, 0.05), 0px 18px 7px 0px rgba(0, 0, 0, 0.01), 0px 28px 8px 0px rgba(0, 0, 0, 0.00);
		background: no-repeat center/50% url("../../assets/img/arrow-right.svg"), white;
		width: 60px; height: 60px;
		@media(max-width: 1440px) {
			width: 40px; height: 40px;
			right: -20px;
		}
		@media(max-width: 1024px) {}
		@media(max-width: 884px) {}
		@media(max-width: 376px) {}
	}
	.slick-dots {
		display: flex !important;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 16px;

		li {
			button {
				font-size: 0; line-height: 0;
				padding: 0;
				width: 8px; height: 8px;
				border-radius: 50%;
			}
			&.slick-active {
				button {
					background-color: var(--color-button-primary);
				}
			}
		}
	}
}

article > .bg {
	border-radius: 20px;
	background: linear-gradient(268deg, #181C26 0.65%, #2F3649 96.08%);
}

.modal {
	border-radius: 10px;
	overflow: visible;
	max-width: unset;

	form[method="dialog"] {
		position: absolute;
		top: -15px; right: 30px;
		button {
			display: contents;
			svg {
				width: 30px; height: 30px;
			}
		}
	}

	&.mobile-bar {
		@media (max-width: 884px) {
			form[method="dialog"] {
				top: -16px; left: calc(50% - 32px);
				button {
					padding: 0;
					display: block;
					width: 64px; height: 4px;
					border-radius: 100px;
					background-color: white;
					svg {
						display: none;
					}
				}
			}	
		}
	}
}

%hero {
	margin-block-start: 60px;
	@media(max-width: $MYlg) {
		margin-block-start: 50px;
	}
	@media(max-width: $MYsm) {
		margin-block-start: 32px;
	}
	@media(max-width: $MYxs) {
		margin-block-start: 16px;
	}
}
