@use '../../assets/scss/vars' as *;
@import '../../assets/scss/variables.scss';

.block-with-grid-images {
  @media (max-width: $MYlg) {
    margin-block-start: 30px;
  }
  @media (max-width: $MYsm) {
    margin-block-start: 32px;
  }
  @media (max-width: 768px) {
    border-top: 1px solid rgb(217, 217, 217);
    margin-top: 31px;
    padding-top: 31px;
  }
    // @media (min-width: $xs) and (max-width: $sm) {
    //   margin-top: 30px;
    //   padding-top: 32px;
    // }
    // @media (max-width: $xs) {
    //   margin-top: 31px;
    //   padding-top: 31px;
    // }
}

.gridParent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px 10px;

  @media (min-width: $xl) {
    margin-top: 134px;
    gap: 25px 25px;
  }
  @media (min-width: $md) and (max-width: $lg) {
    margin-top: -28px;
    gap: 9px 9px;
  }
  @media (min-width: $sm) and (max-width: $md) {
    // margin-top: -55px; ???
    gap: 7px 10px;
  }
  @media (min-width: $xs) and (max-width: $sm) {
    gap: 16px 16px;
  }
  @media (max-width: $xs) {
    gap: 14px 14px;
  }
  
  @media (min-width: $xs) and (max-width: $sm) {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }
  @media (max-width: $xs) {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-image-wrapper {
    position: relative;
    overflow: hidden;
    padding-top: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }

  .gridChild {
    background-color: gray;
    border-radius: 20px;
    
    @media (max-width: $lg) {
      border-radius: 10px;
    }
    &-0 {
      grid-column-start: span 2;
      grid-row-start: span 2;

      @media (max-width: $sm) {
        display: none;
      }
      @media (max-width: $xs) {
        &-7, &-8 {
          display: none;
        }
      }
    }
  }
}

.event-details-trigger {
  :global(.ant-modal-content) {
    padding: 0;
    background-color: transparent;
    :global(.ant-modal-body) {
      padding: 0;
      background-color: transparent;
    }
  }
}
.event-details {
  img {
    width: 100%; height: 100%;
    aspect-ratio: auto; object-fit: contain;
    border-radius: 100px;
  }
}