@use '../../assets/scss/vars' as *;
@import '../../assets/scss/variables.scss';
@import '../../assets/scss/components.scss';

.reservation {
  box-sizing: border-box;
  @media (min-width: $xl) {
    margin-top: 33.3px;
  }

  .container {
    @extend .container;
  }

  .bg-wrapper {
    @media (min-width: $xl) {
      height: 661px;
      margin-inline: 100px;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      height: 503.5px;
      margin: auto 100px;
    }
    @media (min-width: $md) and (max-width: $lg) {
      height: 346px;
      margin: auto 100px;
      margin-top: 14px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      margin: auto 50px;
      margin-top: 35px;
    }
    @media (min-width: $xs) and (max-width: $sm) {
      // height: 300px;
      margin: auto 32px;
      margin-top: 9px;
    }
    @media (min-width: $xs) and (max-width: $md) {
      height: 300px;
    }
    @media (max-width: $xs) {
      margin: auto 16.5px;
      margin-top: 9px;
    }
    @media (max-width: ($sm - $sm/4)) {
      height: 452px;
    }

    @media (max-width: ($xs - $xs/8)) {
      height: 440px;
    }

    &.parent.container {
      margin-block: 60px 160px;
      @media(max-width: $MYlg) {
        margin-block: 50px 120px;
      }
      @media(max-width: $MYmd) {
        margin-inline: 50px;
      }
      @media(max-width: $MYsm) {
        margin-block: 20px 60px;
        margin-inline: 32px;
      }
      @media(max-width: $MYxxs) {
        margin-block-end: 200px;
      }
    }

    .black-bg {
      background: linear-gradient(268.47deg, #181C26 0.65%, #2F3649 96.08%);
      width: 100%;
      height: inherit;

      border-radius: 20px;
    }

    .reserved-bg {
      height: inherit;
      // width: 100%;
      width: 58%;

      // width: 50%;
      @media (min-width: $xxl) {
        width: 40%;
      }
      @media (min-width: ($xxl - $xxl/8)) and (max-width: $xxl) {
        width: 50%;
      }

      @media (min-width: ($xl - $xl/8)) and (max-width: $xl) {
        width: 50%;
      }
      @media (min-width: ($lg - $lg/8)) and (max-width: $lg) {
        width: 50%;

      }
      @media (min-width: $md) and (max-width: ($lg - $lg/4)) {
        width: 63%;
      }

      @media (min-width: ($md - $md/8)) and (max-width: $md) {
        width: 55%;
      }
      @media (min-width: $sm) and (max-width: ($md - $md/8)) {
        width: 67%;
      }

      @media (min-width: ($sm - $sm/8)) and (max-width: $sm) {
        width: 72%;
      }
      @media (min-width: ($sm - $sm/6)) and (max-width: ($sm - $sm/8)) {
        width: 78%;
      }
      @media (min-width: ($sm - $sm/4)) and (max-width: ($sm - $sm/6)) {
        width: 85%;
      }
      @media (max-width: ($sm - $sm/4)) {
        width: 100%;
      }
      // @media (max-width: $xs) {}

      .reserved-bg-image-wrapper {
        height: inherit;
        width: 100%;

        left: 50vw;
  
        // width: 50vw;
        // padding: 0 0 58% 0;
        padding: 0 0 93% 0;

        position: relative;
        overflow: hidden;

        //left: 44vw;

        @media (min-width: $xl) {
          left: 49vw;
        }
        // @media (min-width: $lg) and (max-width: $xl) {}
        @media (min-width: $md) and (max-width: $lg) {
          left: 42.21vw;
          padding: 0px 0 80% 0;
        }
        @media (min-width: $md) and (max-width: ($lg - $lg/4)) {
          left: 36.21vw;
        }
        @media (max-width: $md) {
          left: 40.6vw;
        }
        @media (min-width: ($sm - $sm/8)) and (max-width: $sm) {
          left: 33vw;
        }
        @media (min-width: ($sm - $sm/4)) and (max-width: ($sm - $sm/8)) {
          left: auto; right: -27vw;
          z-index: -1;
        }
        @media (max-width: ($sm - $sm/4)) {
          top: 30vw;
          left: -5%;
          width: 100%;
        }
        @media (max-width: ($sm - $sm/4 - $sm/8)) {
          top: unset; bottom: -50%;
        }
        @media (max-width: $xs) {
          top: 60vw;
        }
        
        img {    
          object-fit: cover;
          height: 90%;
          width: auto;
          position: absolute;
          @media (max-width: ($sm - $sm/4)) {
            height: auto;
            width: 120%;
          }
        }
      }
    }

    .header-content {
      height: inherit;
      box-sizing: border-box;


      @media (max-width: ($sm - $sm/4)) {
        width: 100%;
      }

      .header-content-flex {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: start;
        gap: 20px;
        height: 100%;
        // margin: 0 10%;
        margin: 0 4vw;
        box-sizing: border-box;

        @media (min-width: $xl) {
          gap: 50px;
          margin: -11px 3.45vw
        }
        @media (min-width: $lg) and (max-width: $xl) {
          gap: 40px;
          margin: -11px 3.45vw;
        }
        @media (min-width: $md) and (max-width: $lg) {
          gap: 30px;
          margin: -11px 3.45vw;
        }
        @media (min-width: $sm) and (max-width: $md) {
          gap: 28px;
          margin: 0 3vw;
        }
        @media (min-width: $xs) and (max-width: $sm) {
          gap: 32px;
          margin: 0 5.8vw;
        }
        @media (max-width: $xs) {
          padding: 11vw 4vw !important;
          gap: 32.5px;
        }
        //
        @media (max-width: ($sm - $sm/4)) {
          justify-content: start;
          align-items: center;
          width: 100%;
          margin: 0vw;
          padding: 4vw;

          box-sizing: border-box;
        }

        .header-text {
          font-family: 'Cakra';
          font-weight: 400;
          color: rgba(65, 182, 77, 1);
  
          @media (min-width: $xl) {
            font-size: 128px;
            line-height: 102px;
          }
          @media (min-width: $lg) and (max-width: $xl) {
            font-size: 96px;
            line-height: 77px;
          }
          @media (min-width: $md) and (max-width: $lg) {
            font-size: 64px;
            line-height: 51px;
          }
          @media (min-width: $sm) and (max-width: $md) {
            font-size: 48px;
            line-height: 38px;
          }
          @media (max-width: $sm) {
            font-size: 40px;
            line-height: 32px;
          }

          @media (max-width: ($xs - $xs/8)) {
            font-size: 32px;
          }
        }

        .greenButton {
          margin-bottom: 4vw;

          @media (min-width: $sm) and (max-width: $md) {
            margin-bottom: 3vw;
          }
          @media (min-width: $xs) and (max-width: $sm) {
            margin-bottom: 4.2vw;
          }
          @media (max-width: $xs) {
            width: 100%;
          }


          button {
            @media (min-width: $xl) {
              padding: 4px 80px
            }
            @media (min-width: $sm) and (max-width: $xl) {
              padding: 10px 46px;
            }
            @media (min-width: $xs) and (max-width: $sm) {
              padding: 4px 40px;
            }
            @media (max-width: $xs) {
              padding: 4px 46px;
            }

          }
        }
      }
    }
  
    .greenButton {
      .ant-btn {
  
        @extend %greenButton;
      }
    }
  }
}

.reservation-form {
  margin-top: 100px;
  margin-bottom: 60px;
  margin-inline: auto;

  @media (min-width: $xl) {
    width: 1364px;
    margin-top: 110px;
    margin-bottom: 131px;
  }
  @media (min-width: $lg) and (max-width: $xl) {
    width: 990px;
    margin-top: 78px;
    margin-bottom: 110px;
  }
  @media (min-width: $sm) and (max-width: $lg) {
    width: 615px;
    margin-top: 56px;
    margin-bottom: 94px;
  }
  @media (min-width: $sm) and (max-width: $md) {
    margin-bottom: 13px;
    margin-top: 55px;
  }
  @media (min-width: $xs) and (max-width: $sm) {
    margin-inline: 32px;
    margin-top: 37px;
    margin-bottom: 64px;
  }
  @media (min-width: ($sm - $sm/4)) and (max-width: ($sm - $sm/8)) {
    margin-top: 55px;
  }
  @media (min-width: $xs) and (max-width: ($sm - $sm/4)) {
    margin-top: 80px;
  }
  @media (max-width: $xs) {
    margin: auto 16.5px;
    margin-top: 46px;
    margin-bottom: 64px;
  }

  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (min-width: $xl) {
      gap: 35px;
    }
    // @media (min-width: $lg) and (max-width: $xl) {}
    @media (min-width: $md) and (max-width: $lg) {
      gap: 0px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      gap: 1px;
    }
    @media (min-width: $xs) and (max-width: $sm) {
      gap: 21px;
    }
    @media (max-width: $xs) {
      gap: 21px;
    }

    .ant-form-item {
      @media (max-width: $sm) {
        margin-bottom: 0;
      }


      &:nth-child(5n+4) {
        // color: red;
        @media (min-width: $xl) {
          margin-top: -11px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          margin-top: -7px;
        }
        @media (min-width: $md) and (max-width: $lg) {
          margin-top: -3px;
        }

        @media (min-width: $sm) and (max-width: $md) {
          margin-top: -7px;
        }
      }

      &:nth-child(5n+5) {
        @media (min-width: $xl) {
          margin-top: 9px !important;
        }
        @media (min-width: $md) and (max-width: $lg) {
          margin-bottom: -19px;
        }
        @media (min-width: $sm) and (max-width: $md) {
          margin-top: 5px;
        }
      }

      &:nth-child(7n+7) {
        @media (min-width: $xs) and (max-width: $sm) {
          margin-top: -11px;

          button {
            margin-top: 31px;
            width: 100%;
          }
        }

        @media (max-width: $xs) {
          margin-top: -1px;

          button {
            width: 100%;
            margin-top: 17px;
          }
        }
      }
    }

    .reservation-rules-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: end;

      @media (min-width: 1001px) {
        display: none;
      }
      @media (min-width: $xl) {
        margin-top: -25px;
        margin-bottom: 60px;
      }
      @media (max-width: $md) {
        display: none;
      }

      .reservation-rules {
        font-family: 'Manrope';
        font-weight: 400;
        color: $green;

        @media (min-width: $xl) {
          font-size: 32px;
          line-height: 42px;
        }

        @media (min-width: $md) {
          width: calc(50% - 4px);
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  [type=submit] {
    @extend %greenButton;
  }

  @extend %label;

  //Select
  .ant-select {
    @extend %formElement;
    &-selection-item {
      @media (min-width: 2000px) {
        line-height: 100% !important;
      }
    }
    &:not(.ant-select-disabled) {
      .ant-select-selector {
        background: transparent !important;
      }
    }
  }
  @extend %select;

  .ant-space-compact {
    width: 100%;

    .ant-form-item {
      width: 50%;

      &:first-child {

        .ant-picker {
          border-radius: 10px 0 0 10px;

          @media (min-width: $xs) and (max-width: $sm) {
            padding: 4px 46px 4px 22px;

          }
        }
      }

      &:last-child {
        .ant-picker {
          border-radius: 0 10px 10px 0;
          border-left: none;

          @media (min-width: $xs) and (max-width: $sm) {
            padding: 4px 17px 4px 22px;

          }
        }
      }

      .ant-picker {
        border-radius: 10px 0 0 10px;

        @media (min-width: $xl) {
          padding: 6.5px 43px 6.5px;

        }
        @media (min-width: $lg) and (max-width: $xl) {
          padding: 11px 18.5px;
        }
        @media (min-width: $md) and (max-width: $lg) {
          padding: 11px 18.5px;
        }
        @media (min-width: $sm) and (max-width: $md) {
          padding: 6.5px 18px;
        }
        @media (max-width: $xs) {
          padding: 4px 18px;
        }

        .ant-picker-input {
          @media (min-width: $xl) {
            display: flex;
            justify-content: center;
            align-items: center;
          }

        }

        input {
          @media (min-width: $xl) {
            padding: 0px 13px !important;
          }
          @media (min-width: $sm) and (max-width: $md) {
            padding: 8px 7px !important;
          }
        }
        
        &-suffix {
          @extend %pickerIcon;

          .anticon-clock-circle {
            @extend %pickerIcon;
          }
        }
        &-clear {
          @extend %pickerIcon;

          .anticon-close-circle {
            @extend %pickerIcon;

          }
        }
      }

      .ant-row {
        width: 100%;

        .ant-form-item-control-input-content {
          .ant-picker {
            width: 100%;
          }
        }
      }
      
    }
  }

  .ant-picker {
    @extend %formElement;
    &:hover, &:active {
      border: 1px solid $green;
    }
    &:focus-visible {
      outline: none;
    }
    .ant-picker-suffix {
      color: $green;
    }
  }

  .ant-picker-focused {
    border-color: $green;
    box-shadow: 0 0 0 2px rgba(65, 182, 77, 0.1);;
    outline: 0;
  }

  //Input phone
  .ant-input {
    @extend %formElement;
  }
  .ant-input-group-addon {
    background-color: white;
    color: $gray;
    border-radius: 10px 0 0 10px;

    @media (min-width: $xl) {
      font-size: 32px !important;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      font-size: 24px !important;  
    }
  }
  #phone {
    border-radius: 0 10px 10px 0;
    border-left: none;

    @media (min-width: $xl) {
      padding-left: 5px !important;
    }
  }

  textarea {
    padding: 10px 15px;

    @media (min-width: $sm) and (max-width: $md) {
      height: 120.5px !important;
    }

  }

  //Checkbox
  @extend %checkbox;
  .ant-checkbox-wrapper {
    @media (min-width: $xl) {
      margin: -15px 0;
      padding-left: 7px;
    }
    @media (min-width: $md) and (max-width: $lg) {
      margin-top: -1px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      margin-top: -12px;
    }

    span {
      &:last-child {
        @media (min-width: $xl) {
          padding-left: 31px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          padding-left: 24px;
        }
        @media (min-width: $md) and (max-width: $lg) {
          padding-left: 16px;
        }
        @media (min-width: $sm) and (max-width: $md) {
          padding-left: 14px;
        }
      }
    }
  }
  .clock-icon {
    width: 32px; height: 32px;
    @media (min-width: 2001px) {
      width: 46px; height: 46px;
    }
    @media (max-width: $MYlg) {
      width: 26px; height: 26px;
    }
  }
}

@media (max-width: 376px) {
  [data-select="no-padding"] {
    .ant-select {
      padding-inline: 0;
    }
  } 
}
