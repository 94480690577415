@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/templates.scss';

.card {
  // background-color: rgb(232, 229, 229);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2em;

  &:nth-of-type(2n) {
    gap: 4em;
  }

  @media (min-width: $xl) {
    padding: 20px 8px;
  }
  @media (max-width: $xl) {
    gap: 1em;
    &:nth-of-type(2n) {
      gap: 2em;
    }
  } 
  @media (max-width: $sm) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    max-height: 100vh;
    &:nth-of-type(2n) {
      gap: 1em;
    }
  }

  &__right {
    @media (min-width: $sm) and (max-width: $md) {
      .body {
        padding-left: 26px;
      }
    }

    @media (min-width: $sm) {
      flex-direction: row-reverse;
    }

  }

  > img {
    object-fit: cover;
    max-width: 60%;
    border-radius: 20px;
    height: 100%;
    @media (max-width: $lg) {
      max-width: 50%;
      border-radius: 10px;
    }
    @media (max-width: $sm) {
      max-width: 100%; height: 100%;
      min-height: 240px;
      max-height: 300px;
    }
  }

  .body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #131313;

    @media (min-width: $xl) {
      justify-content: start;
      gap: 80px;
    } 
    @media (max-width: $sm) {
      gap: 27px;
    }
    @media (max-width: $xs) {
      gap: 24px;
    }
    @media (max-width: ($xs - $xs/8)) {
      gap: 15px;
      margin-top: 15px;
    }

    .tag {
      @media (min-width: $xs) and (max-width: $sm) {
        padding: 2px 10px;
      }
      @media (max-width: $xs) {
        padding: 1.5px 10.5px;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 10px;
      height: 100%;

      @media (min-width: ($xl + $xl/4)) {
        gap: 38px;
      }
      @media (min-width: ($xl + $xl/8)) and (max-width: ($xl + $xl/4)) {
        gap: 32px;
      }
      @media (min-width: $xl) and (max-width: ($xl + $xl/8)) {
        gap: 25px;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        gap: 13px;
      }
      @media (min-width: $md) and (max-width: $lg) {
        gap: 18px;
      }
      @media (min-width: $sm) and (max-width: $md) {
        gap: 20px;
      }

      .title {
        font-family: Cakra;
        font-size: 64px;
        font-weight: 400;
        line-height: 51px;
        letter-spacing: 0px;
        text-align: left;
        text-transform: uppercase;
        color: inherit;
      }
  
      .text {
        font-family: Manrope;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
        // max-width: 300px;
        max-width: 90%;

        @media (min-width: ($xl + $xl/4)) {
          font-size: 40px;
          line-height: 52px;
        }
        @media (min-width: ($xl + $xl/8)) and (max-width: ($xl + $xl/4)) {
          font-size: 36px;
          line-height: 52px;
        }
        @media (min-width: $xl) and (max-width: ($xl + $xl/8)) {
          font-size: 32px;
          line-height: 52px;
        }
        @media (min-width: ($xl - $xl/8)) and (max-width: $xl) {
          font-size: 28px;
          line-height: 34px;
        }
        @media (min-width: ($xl - $xl/4)) and (max-width: ($xl - $xl/8)) {
          font-size: 24px;
          line-height: 34px;
        }
        @media (min-width: $lg) and (max-width: ($xl - $xl/4)) {
          // font-size: 28px;
          font-size: 20px;

          line-height: 34px;
        }
        @media (min-width: $sm) and (max-width: $md) {
          max-width: 98%;
        }


        @media (max-width: $sm) {
          width: 270px;
          font-size: 14px;
        }

      }

      .link {
        @extend %link;
        @media (min-width: 539px) {
          margin-top: auto !important;
        }
      }
      .more-details {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        
        font-family: 'Manrope';
        font-weight: 400;

        background-color: transparent;
        color: black;
        border-radius: 100px;
        height: 40px;
        transition: all 0.3s ease-in-out;

        &:hover, &:active {
          background-color: #daf9dd;
          color: black;
        }

        @media (min-width: $xl) {
          justify-content: start;
          gap: 52px;
          padding: 0;

          .link-text {
            font-size: 32px !important;
            line-height: 42px !important;
          }
        }

        @media (min-width: $lg) and (max-width: $xl) {
          justify-content: start;
          gap: 41px;
        }
        @media (min-width: ($xl - $xl/3.58)) and (max-width: $xl) {
          margin-left: -13px;
        }
        @media (min-width: $lg) and (max-width: ($xl - $xl/8)) {
          .link-text {
            font-size: 22px;
          }
        }

        @media (min-width: $sm) and (max-width: $lg) {
          justify-content: start;
          gap: 31px;
        }

        @media (min-width: $sm) {
          border: 1px solid transparent;
          text-transform: none;
        }

        @media(max-width: $sm) {
          height: 52px;
          text-transform: uppercase;

          &:hover {
            background-color: #64c96e;
            color: white;
            border: 1px solid transparent;
          }

          .ant-btn {
            border: 1px solid $green !important;   
          }
        }
      }
    }
  }
}

