@use '../../assets/scss/vars' as *;
@import '../../assets/scss/variables.scss';
@import '../../assets/scss/components.scss';
@import '../../assets/scss/templates.scss';

.play-video-button {
	background: url("../../assets/img/jobs/play-video.svg") center/contain no-repeat;
}
.hero.career {
	display: flex;
	flex-direction: row;
	// align-items: center;
	justify-content: space-between;

	position: relative;
	overflow-x: clip;

    background: url("../../assets/img/bg-jobs.png") center/cover no-repeat, #1C274C;

	border-radius: 10px;

	margin-top: 60px;
	margin-inline: 100px;

	@media(max-width: $MYlg) {
		border-radius: 10px;
		padding-bottom: 2px;
		margin-top: 50px;
	}
	@media(max-width: $MYmd) {
		margin-inline: 50px;
	}
	@media(max-width: $MYsm) {
		margin-top: 20px;
		margin-inline: 32px;
	}
	@media(max-width: $MYxs) {
		flex-direction: column;
		align-items: start;
		justify-content: start;
		margin-inline: 16px;
	}
	
	.text {
		padding-inline-start: 90px;
		@media (min-width: $xl) {
			padding: 140px 0 179px 89px;
		}
		@media (max-width: $xl) {
			padding: 115px 0 135px 75px;
		}
		@media(max-width: $MYlg) {
			padding: 100px 0 70px 50px;
			// padding: 6.95vw 0 4.87vw 3.45vw;
		}
		@media(max-width: $MYmd) {
			padding: 26px 0 116px 30px;
		}
		@media(max-width: 884px) {
			z-index: 1;
			padding: 16px 32px;
		}
		@media (min-width: $xs) and (max-width: $sm) {
			padding: 28px 32px 30px 32px;

		}
		@media (max-width: ($sm - $sm/8)) {
			padding: 28px 32px 200px 32px
		}

		@media(max-width: 448px) {
			// padding: 27px 16px 291.5px;
			// padding: 27px 16px 52vw;
			padding: 27px 16px 57vw;
		}
		@media (max-width: $xs) {
			padding: 27px 16px 77.7vw;
		}
		@media(max-width: 320px) {
			padding: 27px 16px 68vw;
		}

		p {
			margin: 0;
		}
		.title {
			color: var(--color-button-primary);
			font-size: 128px;

			@media(min-width: 2440px) {
				margin-block-start: 0;
			}
			@media (max-width: ($xl + $xl/6)) {
				font-size: 110px;
			}
			@media (min-width: $xl) {
				margin: 0 0 28px 0;
			}
			@media (max-width: $xl) {
				font-size: 92px;
				margin-block-start: 0;
				margin: 0 0 26px 0;
			}
			@media (max-width: ($xl - $xl/6)) {
				font-size: 74px;
			}
			@media(max-width: 1440px) {
				font-size: 64px;
				margin-block-start: 0;
				margin: 0 0 25px 0;
			}
			@media (max-width: ($lg - $lg/8)) {
				font-size: 60px;
			}
			@media (max-width: ($lg - $lg/6)) {
				font-size: 58px;
				width: 97%;

			}

			@media(max-width: 1024px) {
				font-size: 48px;
				// margin-block-start: unset;
				margin: 0 0 15px 0;
				width: 97%;


			}
			@media(max-width: 884px) {
				font-size: 40px;
				margin: 0 0 11px 0;
			}
			@media(max-width: 768px) {
				width: 86%;

			}
			@media (max-width: 448px) {
				margin: 0 0 26px 0;
			}
			@media(max-width: 376px) {}
		}
		.appeal {
			color: white;
			font-size: 48px;
			font-weight: 600;
			// margin-block: 50px;

			@media (min-width: $xl) {
				width: 68% !important;
				margin: 0 0 30px 0;
			}
			@media (max-width: ($xl + $xl/6)) {
				font-size: 36px;
			}

			@media (max-width: $xl) {
				font-size: 22px;
				font-weight: 400;
				margin: 0 0 44px 0;
			}
			@media (max-width: ($xl - $xl/6)) {
				font-size: 18px;
			}
			@media(max-width: 1440px) {
				font-size: 14px;
				font-weight: 400;
				margin: 0 0 54px 0;

			}
			@media (max-width: ($lg - $lg/6)) {
				width: 70%;

			}
			@media(max-width: 1024px) {
				// margin-block: 0 1px;
				margin: 0 0 1px 0;
				width: 80%;

			}
			@media(max-width: 884px) {
				margin: 0 0 8px 0;
			}
			@media(max-width: 768px) {}
			@media (max-width: 448px) {
				margin: 0 0 24px 0;
				width: 97%;

			}
			@media(max-width: 376px) {}
		}
		.phone {
			color: white;
			font-size: 64px;
			@media (max-width: ($xl + $xl/6)) {
				font-size: 58px;
			}
			@media (max-width: ($xl - $xl/6)) {
				font-size: 48px;
			}
			@media(max-width: 1440px) {
				font-size: 32px;
			}
			@media(max-width: 1024px) {}
			@media(max-width: 884px) {}
			@media(max-width: 768px) {}
			@media (max-width: 448px) {}
			@media(max-width: 376px) {}
		}
		.play-video {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: start;
			color: white;
			margin-block-start: 24px;

			@media (min-width: 884px) {
				display: none;
			}
			@media (max-width: 448px) {
				margin-block-start: 32px;
			}

			span {
				&:nth-of-type(2n+2) {
					@media (max-width: 884px) {
						font-size: 14px;
						line-height: 18.2px;
					}
				}
			}
				

			&-button {
				width: 70px; height: 70px;
				margin-inline-end: 32px;
			}
		}
	}

	.workersImgWrapper {
		z-index: 0;
		@media(max-width: 1440px) {
			position: relative;
		}
		@media (min-width: $xs) and (max-width: $sm) {
			width: 47vw;
		}
		@media (max-width: ($sm - $sm/8)) {
			width: 104vw;
		}
		@media (max-width: 448px)  {
			width: 98vw;
		}
	}
	img {
		align-self: flex-end;
		position: absolute;
		z-index: 3;
		bottom: 0;

		@media (min-width: $xl) {
			right: -47px;
			max-width: 48.89vw;
		}
		@media (max-width: $xl) {
			right: -28px;
			max-width: 44.5vw;
		}
		@media (max-width: ($xl - $xl/6)) {
			right: -28px;
			max-width: 48.89vw;
		} 
		@media(max-width: 1440px) {
			right: -28px;
			max-width: 46.78vw;
		}
		@media (max-width: ($lg - $lg/6)) {
			max-width: 53.78vw;
		}
		@media(max-width: 1024px) {
			right: 3px;
			max-width: 56vw;
		}
		@media(max-width: 884px) {
			max-width: 50.5vw;
		}
		@media(max-width: 768px) {
			right: -23px;
		}
		@media (max-width: ($sm - $sm/8)) {
			align-self: flex-start;
			max-width: unset;

			width: 80vw;
			right: 5vw;
		}
		@media (max-width: ($sm - $sm/6)) {
			width: 90vw;
		}
		@media (max-width: ($sm - $sm/4)) {
			width: 95vw;
		}

		@media (max-width: 448px) {
			width: 100vw;
			right: calc((100vw - 100%)/4);
		}
		@media(max-width: 376px) {
			width: 110vw;
			right: calc((100vw - 100%)/4);
		}
	}
}

.job {
  @extend %container;

  margin-top: 100px;
  margin-bottom: 60px;

  @media (min-width: $xl) {
    margin-top: 111px;
    margin-bottom: 199px;
  }
  @media (min-width: $lg) and (max-width: $xl) {
    margin-top: 55px;
    margin-bottom: 135px;
  }
  @media (min-width: $md) and (max-width: $lg) {
    margin-top: 30px;
    margin-bottom: 100px;
  }
  @media (min-width: $sm) and (max-width: $md) {
    margin-top: 58px;
    margin-bottom: 120px;
  }
  @media (min-width: $xs) and (max-width: $sm) {
    margin-top: 34px;
    margin-bottom: 63px;
  }
  @media (max-width: $xs) {
    margin-top: 32px;
    margin-bottom: 64px;
  }

  .header-all-bg {
    box-sizing: border-box;
    display: block;

    @media (min-width: $xl) {
      // width: 2360px;
      width: 100%;
      height: 661px;
    }
    @media (min-width: $lg) and (max-width: $xl) {
      width: 100%;
      height: 503px;
    }
    @media (min-width: $md) and (max-width: $lg) {
      // width: 1240px;
      width: 100%;
      height: 346px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      // width: 924px;
      width: 100%;
      height: 300px;
    }
    @media (min-width: $xs) and (max-width: $sm) {
      // width: 704px;
      width: 100%;
      height: 300px;

    }
    @media (max-width: $xs) {
      // width: 343px;
      width: 100%;
      height: 600px;
    }

    .dark-blue-bg {
      // background-color: $dark-blue;
      background: url("../../assets/img/bg-jobs.png") center/cover no-repeat, #1C274C;
      z-index: 0;
      box-sizing: border-box;

    }
    .furniture-opacity-bg {
      z-index: 1;
      box-sizing: border-box;

    }
    .dark-blue-bg, .furniture-opacity-bg {
    box-sizing: border-box;

      position: absolute;
      border-radius: 10px;
      height: inherit;
      width: inherit;
    }
    // 
    .peoples-bg {
    box-sizing: border-box;

      position: absolute;
      z-index: 2;
    }
    .content-bg {
      position: absolute;
      z-index: 2;
    }
  }
  .content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    @media(min-width: 2561px) {
      gap: 218px;
    }
    @media(min-width: 1881px) {
      gap: 104px;
    }
    @media (min-width: 1175px) {
      gap: 56px;
    }
    @media(max-width: 1174px) {
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      gap: 44px;
    }
    @media (min-width: $sm) and (max-width: $md) {
      gap: 49px;

    }

    .job-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: max-content;

      @media (min-width: $xl) {
        width: 58%;
      }
      @media (min-width: $lg) and (max-width: $xl) {
        width: 58%;
      }
      @media (min-width: $md) and (max-width: $lg) {
        // width: 50%;
        width: 49.7%;

      }
      @media (min-width: $sm) and (max-width: $md) {
        width: 75%;
      }
      // @media (min-width: $xs) and (max-width: $sm) {}
      // @media (max-width: $xs) {}

      form {//.ant-form
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 25px;
        
        
        @media (min-width: $xl) {
          gap: 58px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
          gap: 38px;
        }
        @media (min-width: $md) and (max-width: $lg) {
          gap: 25px;

        }
        // @media (min-width: $sm) and (max-width: $md) {}
        @media (max-width: $sm) {
          gap: 21px;
        }
        // @media (max-width: $xs) {}

        .ant-form-item {
          margin-bottom: 0;

          @extend %label;
      
          @extend %checkbox;

          @extend %prefixPhone;

          &:nth-of-type(6n+5) {
            @media (min-width: $xl) {
              margin-top: -34px;
            }
            // из пакета правок от 28-12-2023
            // @media (min-width: $xs) and (max-width: $sm) {
            //   width: 50%;
            // }
          }

          &:nth-of-type(6n+6) {
            @media (min-width: $xl) {
              margin-top: -57px;
            }
            @media (min-width: $lg) and (max-width: $xl) {
              margin-top: -25px;
            }
            @media (min-width: $md) and (max-width: $lg) {
              margin-top: -14px;
            }
            @media (min-width: $sm) and (max-width: $md) {
              margin-top: -12px;

            }
            @media (min-width: $xs) and (max-width: $sm) {
              margin-top: -35px;              
            }
            @media (max-width: $xs) {
              margin-top: -27px;
            }
          }

          .ant-input, .ant-select {
            @extend %formElement;
          }
          .ant-select-selector {
            background-color: transparent !important;
          }

          textarea {
            padding: 10px 15px;
          }
          @extend %select;
        }

        [type=submit] {
          @extend %greenButton;
          width: 49%;

          @media (min-width: $xl) {
            margin-top: -20px;
          }
          @media (min-width: $lg) and (max-width: $xl) {
            margin-top: 1px;
          }
          @media (min-width: $md) and (max-width: $lg) {
            // width: 49%;
            margin-top: 1px;
          }
          @media (min-width: $sm) and (max-width: $md) {
            width: 43.5%;
            margin-top: -1px;
          }
          @media (min-width: $xs) and (max-width: $sm) {
            margin-top: -1px;
          }
          @media (max-width: 448px) {
            width: 100%;
          }
          @media (max-width: $xs) {
            margin-top: 0.5px;
          }
        }

        // это вот копание в коде ради выискивания какого-то там очередного класса
        // ради переопределения ради узкого экрана - ну я замотался уже, если честно,
        // это реально утомительно
        .ant-form-item .ant-select {
          padding-inline: 0;
		  &-selection-item {
			line-height: 100%;
		  }
        }
      }
    }
  }
}

.video {
	width: 100%;
	// max-width: 767px; max-height: 432px;

	@media (min-width: $xl) {
		margin-top: -11px;
		width: 30vw;
		height: 17vw;
	}
	@media (max-width: $xl) {
		width: 36vw;
		height: 20.4vw;
	}

	@media(max-width: $MYlg) {
		max-width: 513px; max-height: 289px;
		width: 36vw;
		height: 20.05vw;
	}
	@media(max-width: 1174px) {
		max-width: 691px; max-height: unset;
	}
	@media (min-width: $sm) and (max-width: $md) {
		width: 75%;
		// height: 388px;
		height: 38vw;
	}

	@media(max-width: $MYsm) {
		display: none;
	}
	.wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-radius: 10px;

		@media (min-width: $xl) {
			border-radius: 20px;
		}

		img {
			border-radius: 20px;
			object-fit: cover;
			width: 100%;

			@media(max-width: $MYxl) {
				border-radius: 10px;
			}
		}
		.play-video-button {
			--size: 100px; --center: calc(50% - var(--size) / 2);
			width: var(--size); height: var(--size);
			position: absolute;
			top: var(--center); left: var(--center);
			@media(max-width: $MYxl) {
				--size: 70px;
			}
		}
	}
	p {
		font-family: Manrope;
		font-weight: 400;
			margin-block-start: 32px;

		@media (min-width: $xl) {
			font-size: 32px;
			line-height: 42px;
			margin-block-start: 40px;
		}
		@media (max-width: $xl) {
			margin-block-start: 32px;
			font-size: 20px;
			line-height: 27px;
		}

		@media(max-width: $MYxl) {
      		margin-block-start: 26px;
      		font-size: 14px;
      		line-height: 18px;
		}
	}
}

.phone-try-123 {
  @extend %prefixPhone;
  #phone {
      border-bottom-left-radius: 0;
      border-left: none;
  }
}

.about-work-video-modal {
	width: 85%;
	@media (max-width: $MYmd) {
		width: 95%;
		video {
			height: auto;
			width: 100%;
		}
		img {
			// display: block;
			width: 100%;
		}
	}
}
