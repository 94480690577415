@use "../../../assets/scss/aux";
@use "../../../assets/scss/vars" as *;

.menu {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;

    --categories-gap: 8em;
    gap: var(--categories-gap, 16px);

    margin-inline: 100px;

    position: relative;
    z-index: 1;

    @media (min-width: calc($MYlg + 1px)) {
        margin-block: 100px;
        --item-width: calc(100% / 6);
    }
    @media (max-width: $MYxl) {
        --item-width: 20%;
    }
    @media(max-width: $MYlg) {
        --categories-gap: 4em;
        margin-block: 50px;
    }
    @media(max-width: $MYmd) {
        --item-width: 20%;
        margin-inline: 50px;
    }
    @media(max-width: $MYsm) {
        gap: 0;
        --item-width: 25%;
        flex-direction: column;
        align-items: stretch;
        justify-content: start;
        margin-block: 0;
        margin-inline: 32px;
        padding-block-end: 30px;
    }
    @media(max-width: $MYxs) {
        --item-width: 50%;
        margin-inline: 16px;
        padding-block-end: 32px;
    }

    .cats-wrapper {
        h4 {
            &[data-view^="view-"] {
                @media (min-width: 1441px) {
                    margin-bottom: 50px;
                }
                @media (max-width: 1440px) {
                    margin-bottom: 30px;
                }
                @media (max-width: 1024px) {
                    margin-bottom: 31.5px;
                }
                @media (min-width: 769px) {
                    margin-top: 0;
                }
                @media (max-width: 768px) {
                    margin-top: 32px;
                }
            }
        }
    }
    > button:first-of-type {
        @media(min-width: 885px) {
            display: none;
        }
    }
    .categories {
        &-wrapper {
            // не работает :(
            // position: sticky;
            // top: 0;
            width: 100%;
            background-color: #FAFAFA;
            border-radius: 10px;
            padding-block: 25px;
            &-outer {
                min-width: var(--item-width);
                width: var(--item-width);
                @media (max-width: $MYsm) {
                    display: none;
                }
                > h4 { // надпись над категориями на страницах бар/ланч/...
                    margin-block: 0 50px;
                    font-family: Cakra;
                    font-size: 96px;
                    font-weight: 400;
                    line-height: 80%;
                    @media(max-width: $MYxl) {
                        font-size: 72px;
                    }
                    @media(max-width: $MYlg) {
                        margin-block-end: 30px;
                        font-size: 56px;
                    }
                    @media(max-width: $MYmd) {
                        margin-block-end: 31.5px; // ...
                        font-size: 42px;
                    }
                    @media(max-width: $MYsm) {
                        display: none;
                    }
                }
                & + button {
                    width: 100%;
                    @media(max-width: $MYsm) {
                        margin-block: 32px;
                    }
                    & + hr {
                        margin-block: 0 31px;
                        width: 100%;
                        height: 2px;
                        background-color: var(--color-separator);
                        @media (min-width: calc($MYsm + 1px)) {
                            display: none;
                        }
                    }
                }
            }
        }
        min-width: min-content;
        padding-inline: 47px;

        @media(max-width: $MYlg) {
            padding-inline: 20px;
        }
        @media(max-width: 884px) {
            display: none;
        }
        @media (max-width: 488px) {}
        @media(max-width: 376px) {}
        ul {
            margin: 0;
            padding-inline-start: 0;
            a {
                text-decoration: none;
                color: #A6A6A6;
                transition: color .2s ease-out, background-color .2s ease-out;
                &.parent-category {
                    font-weight: 600;
                    color: black;
                    & li {
                        line-height: 100%;
                    }
                }
                &.active {
                    color: var(--color-button-primary);
                }
                &:hover {
                    color: var(--color-button-primary);
                    background-color: #A6A6A6;
                }
                li {
                    list-style-type: none;
                    padding-block: 25px;
                    line-height: 130%;
                    font-size: 32px;
                    @media(max-width: $MYxxl) {
                        font-size: 24px;
                    }
                    @media(max-width: $MYxl) {
                        padding-block: 10px;
                        font-size: 16px;
                    }
                    @media (max-width: $MYlg) {
                        padding-block: 20px;
                    }
                }
            }
        }
        hr {
            width: 100%;
            height: 2px;
            border: none;
            background-color: #D9D9D9;
            margin: 25px 0;
            @media(max-width: $MYxl) {}
                margin-block: 10px;
            @media(max-width: $MYlg) {
                
            }
        }
    }
    .mobile-subcat-active {
        font-size: 40px;
        margin-block: 0 32px;
        @media (max-width: $MYsm) {
            
        }
    }
    > h3 {
        margin-block-end: 32px;
        @media(min-width: 885px) {
            display: none;
        }
    }
    &-grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        @media(max-width: $MYxl) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media(max-width: $MYlg) {
            grid-template-columns: repeat(5, 1fr);
        }
        @media(max-width: $MYmd) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media(max-width: $MYsm) {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: auto;
        }
        @media (max-width: $MYxs) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
        }

        gap: 2em;

        .item {
            min-width: var(--item-width);
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: start;
            // height: 100%;
            user-select: none;

            // @media (max-width: $MYxs) {
            //     text-align: center;
            // }
            img {
                border-radius: 20px;
                --mh: 296;
                --v1: 448;
                --ar: calc(var(--v1) / var(--mh));
                aspect-ratio: var(--ar);
                max-height: 296px;
                width: 100%;
                // height: 100%;
                cursor: pointer;
                margin-block-end: 12px;
                @media (max-width: $MYlg) {
                    border-radius: 10px;
                    --mh: 158;
                    max-height: 158px;
                    --v1: 240;
                    margin-block-end: 8px;
                }
                @media (max-width: $MYmd) {
                    --mh: 147;
                    max-height: 147px;
                    --v1: 223;
                }
                @media (max-width: $MYsm) {
                    --mh: 109;
                    max-height: 109px;
                    --v1: 163;
                    margin-block-end: 4px;
                }
                object-fit: cover;
            }
            p {
                margin-block: 0;
                color: #131313;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .title {
                font-weight: 500;
                line-height: 120%;
                margin-block-end: .25em;
                @media (min-width: calc($MYxl + 1px)) {
                    font-size: 32px;
                }
                @media(max-width: $MYxl) {
                    font-size: 24px;
                }
                @media (max-width: $MYsm) {
                    font-size: 16px;
                    margin-block-end: 4px;
                }
            }
            .weight {
                color: #A6A6A6;
                margin-block-start: auto;
                font-weight: 400;
                line-height: 130%;
                font-size: 17px;
                margin-block-end: .5em;
                @media(max-width: $MYxl) {
                    font-size: 14px;
                }
                @media(max-width: $MYmd) {
                    font-size: 11px;
                }
                @media(max-width: $MYsm) {
                    margin-block-end: 4px;
                    font-size: 9px;
                }
            }
            .row {
                display: flex;
                flex-direction: row;
                align-items: end;
                justify-content: space-between;
                flex-wrap: wrap;
                .menu-item-count-wrapper {
                    margin-block-start: .5em;
                }
                .zero-items {
                    margin-block-start: .5em;
                    width: 50px; height: 50px;
                    @media(max-width: $MYxl) {
                        width: 30px; height: 30px;
                    }
                    @media(max-width: $MYsm) {
                        width: 24px; height: 24px;
                    }
                }
            }
            .price {
                font-family: 'Cakra';
                font-weight: 400;
                line-height: 80%;
                font-size: 64px;
                max-width: 448px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: max-content;
                @media(max-width: $MYxl) {
                    font-size: 32px;
                }
                @media(max-width: $MYmd) {
                    font-size: 24px;
                }
            }
        }
        button:last-of-type {
            width: 100%;
            grid-column: 1 / 4;
            @media(min-width: calc($MYxl + 1px)) {
                grid-column: 1 / 5;
            }
            @media(max-width: $MYlg) {
                grid-column: 1 / 5;
            }
            @media(max-width: $MYmd) {
                grid-column: 1 / 4;
            }
            @media(max-width: $MYsm) {
                grid-column: 1 / 5;
            }
            @media (max-width: $MYxs) {
                grid-column: 1 / 3;
            }
            @media(max-width: 376px) {}
        }
    }
    .expand {
        margin-block: 32px 64px;
        @media(min-width: 885px) {
            display: none;
        }
    }
    .subcategory-title {
        font: 800 48px/100% "Cakra";
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: flex-start;
        &:first-of-type {
            margin-block-start: 0;
        }
        &::after {
            content: "";
            flex-grow: 1;
            margin-inline-start: 1em;
            border-bottom: 1px solid black;
        }
        @media (max-width: $MYlg) {
            font-size: 32px;
        }
        @media (max-width: $MYsm) {
            font-size: 24px;
        }
    }
}

#menu-item-details {
    padding: 0;
    .img-wrapper {
        height: max-content;
        // overflow-y: auto;
        img {
            border-radius: 10px;
            width: 100%;
            object-fit: cover;
        }
        @media (max-width: $MYxxs) {
            height: 251px;
        }
    }
    .text-wrapper {
        padding: 30px;
        @media (max-width: $MYsm) {
            padding: 32px 17px 31px 32px;
        }
        .head {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-block-end: 20px;
            p {
                margin-block: 0;
            }
            .title {
                font-size: 24px;
            }
            .grams {
                font-size: 16px;
                color: #A6A6A6;
            }
            @media (max-width: $MYsm) {
                flex-direction: column;
                align-items: start;
                justify-content: start;
                gap: 16px;
                margin-block-end: 16px;
            }
        }
        .description {
            font-size: 14px;
        }
        .price {
            font-size: 32px;
            margin-block: 30px 20px;
            @media (max-width: $MYsm) {
                margin-block: 32px 117px;
            }
        }
        button {
            @media (max-width: $MYxl) {
                font-size: 16px;
                line-height: 100%;
                min-width: 302px;
                padding: 20px 30px;
            }
        }
    }
    @media (max-width: 884px) {
        margin-block-end: 0;
    }
}

#mobile-cats {
    font-size: 16px;
    .category-group {
        .head {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            font-weight: 600;

            &::after {
                content: "";
                margin-inline-start: auto;
                width: 24px; height: 24px;
                background: url("../../../assets/img/bold-arrow-down.png") center/contain no-repeat;
            }
        }
        ul {
            display: none;
            padding-inline-start: 0;
            li {
                display: contents;
                label {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: start;
                    color: #A6A6A6;
                    input {
                        width: 24px; height: 24px;
                        flex: 0 0 auto;
                        appearance: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;

                        margin: 0 24px 0 0;

                        border-radius: 50%;
                        border: 1px solid var(--color-separator);
                        padding: 2px;
                        &:checked {
                            background: var(--color-button-primary) content-box;
                        }
                    }
                }
            }
        }
        &._active {
            .head {
                color: var(--color-button-primary);
                &::after {
                    background-image: url("../../../assets/img/arrow-up.svg");
                }
            }
            ul {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                gap: 24px;
                margin-block-start: 24px;
            }
        }
    }
    hr {
        margin-block: 24px;
    }
    @media (min-width: calc($MYmd + 1px)) {
        display: none;
    }
}
