@import '../../../assets/scss/variables.scss';

.marquee-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  @media (min-width: 768px) {
    margin-top: 100px;
  }
  @media (min-width: 1440px) {
    margin-top: 64px;
  }
  margin-top: 3em;
}

@keyframes marquee{
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.marquee {
  // margin-top: 63px;
  margin: 0;
  // padding: 24px 0;
  font-family: 'Cakra';
  font-weight: 400;
  color: black;

  @media (min-width: $xl) {
    font-size: 128px;
    line-height: 102px;
  }
  @media (min-width: $lg) and (max-width: $xl) {
    font-size: 96px;
    line-height: 102px;
  }
  @media (min-width: $md) and (max-width: $lg) {
    font-size: 64px;
    line-height: 100%;
  }
  @media (min-width: $sm) and (max-width: $md) {
    font-size: 48px;
  }
  @media (max-width: $sm) {
    font-size: 36px;
  }

  & > span {
    color: green; // Изменен цвет звездочки
  }

  white-space: nowrap;
  animation: marquee 10.5s infinite linear; /* notice the infinite */
}

// .marquee:after{
//   content: " ✦ НОВОСТИ И АКЦИИ ✦ НОВОСТИ И АКЦИИ ✦ НОВОСТИ И АКЦИИ ";
// }