@use "../../assets/scss/vars" as *;
@use "../../assets/scss/components1";

.banner {
    border-radius: 10px;
    background: url("../../assets/img/bar/lunch.jpeg") center/cover no-repeat;
    padding: 112px;
    @media(max-width: $MYlg) {
        padding: 60px;
    }
    @media(max-width: $MYmd) {
        padding: 30px;
    }
    @media(max-width: $MYxxs) {
        padding-inline: 15px;
    }
    p:first-of-type {
        color: white;
        margin-block-end: 244px;
        @media(max-width: $MYlg) {
            margin-block-end: 130px;
        }
        @media(max-width: $MYsm) {
            margin-block-end: 150px;
        }
        @media(max-width: $MYxxs) {
            margin-block-end: 270px;
        }
    }
}
// .lunch-title-shadow {
//     p:first-of-type {
//         text-shadow: 1px 0 4px black;
//     }
// }
.menu {
    .menu-grid + button {
        margin-block-start: 100px;
        @media(max-width: $MYlg) {
            margin-block-start: 50px;
        }
        @media(max-width: $MYsm) {
            margin-block-start: 32px;
        }
    }
}
