@use '../../assets/scss/vars' as *;
@import '../../assets/scss/variables.scss';
@import '../../assets/scss/components.scss';

.class-body {
    position: relative;
    background: url("../../assets/img/partner/hero.png") no-repeat center center fixed, linear-gradient(to top, rgba(19, 19, 19, 1) 5%, rgba(19, 19, 19, 0) 10%);
}

.header .social {
    svg {
        @media (max-width: 768px) {
            width: 15px !important;
            height: 15px !important;
        }
    }
}
.hero-partner {
    padding-block: 60px;
    padding-inline: 100px;
    @media(max-width: $MYlg) {
        padding-block: 50px;
    }
    @media(max-width: $MYmd) {
        padding: 50px;
        position: relative;
    }
    @media (min-width: $xs) and (max-width: $sm) {
        padding: 17px 35px;
    }
    @media (max-width: $xs) {
        padding: 18px 17px;
    }
    p {
        &:first-of-type {
            color: white;
            text-wrap: wrap;
            // margin-block-end: 60px;
            margin-block: 0;
            // я решил сделать то же самое, что и на "Событиях" -
            // чтобы надпись занимала весь экран за учётом отступов
            // да простят меня коллеги
            font-size: calc(100vw / 6.8);
            line-height: 80%;
            br:first-of-type {
                @media (min-width: $MYxs) {
                    display: none;
                }
            }
            br:last-of-type {
                @media (min-width: $MYsm) {
                    display: none;
                }
            }
            @media (max-width: $MYlg) {
                font-size: calc(100vw / 7.3);
            }
            @media (max-width: $MYmd) {
                font-size: calc(100vw / 6.9);
            }
            @media (min-width: $sm) and (max-width: $md) {
                margin-top: -33px;
                margin-bottom: 0;
            }
            @media (min-width: $xs) and (max-width: $sm) {
                margin-top: -13px;
                // overflow-wrap: break-word;
                width: 82%;
                // margin-bottom: 0;
            }
            @media (max-width: $MYxs) {
                margin-top: -13px;
                width: 90%;
                margin-bottom: 0;
                font-size: 64px;
            }
            @media (max-width: calc($MYxs - 20px)) {
                font-size: calc(100vw / 7);
            }
        }
        &.with-brand {
            color: var(--color-button-primary);
            margin-inline-start: auto;
            text-align: right;
            font: 400 120px/80% "Cakra";

            @media (min-width: $xl) {
               margin: 55px 0;
            }
            @media (min-width: calc($MYlg + 1px)) {
                margin-block-end: 0;
            }
            @media(max-width: $MYlg) {
                font-size: 64px;
                margin: 34px 0 0;
            }
            @media(max-width: $MYmd) {
                font-size: 32px;
                margin-block-start: 25px;
                position: absolute;
                right: 50px;
            }
            @media (min-width: calc($MYsm + 1px)) {
                br {
                    display: none;
                }   
            }
            @media (max-width: $MYsm) {
                margin-block-start: 0;
                width: 50%;
                top: calc(50% - 24px);
                line-height: 95%;
                text-align: left;
            }
            // @media (min-width: ($sm - $sm/7)) and (max-width: ($sm - 8px)) {
            //     margin-left: 53vw;
            //     margin-top: -51px;
            // }
            // @media (min-width: ($sm - $sm/4.15)) and (max-width: ($sm - $sm/7)) {
            //     margin-top: 30px;
            //     margin-left: 47.5vw;
            // }
            // @media (min-width: ($sm - $sm/3)) and (max-width: ($sm - $sm/4.15)) {
            //     top: 50%;
            // }
            // @media (min-width: ($sm - $sm/2.7)) and (max-width: ($sm - $sm/3)) {
            //     margin-top: -73px;
            //     margin-left: 47.5vw;
            // }
            @media (max-width: ($sm - $sm/2.7)) {
                margin-top: 16px;
                // margin-left: 27.5vw;
                position: initial;
                text-align: left;
                width: 75%;
            }
            @media (max-width: ($sm - $sm/2.248)) {
                width: 100%;
            }
            @media (max-width: $xs) {
                margin-top: 15px;
            }
        }
        svg {
            width: 314px; height: 84px;
            margin-inline-start: 58px;
            @media(max-width: 1440px) {
                width: 175px; height: 46px;
                margin-inline-start: 18px;
            }
            @media(max-width: $MYmd) {
                height: 32px; width: auto;
            }
            @media (max-width: $sm) {
                width: 89.17px;
                height: 23.87px;
                margin-left: 6px;
            }
        }
    }
    > button {
        margin-block-start: 60px;
        width: 100%;
        margin-inline: 0;

        @media(max-width: $MYlg) {
            margin-block-start: 50px;
            width: 100%;
        }
        @media(max-width: $MYsm) {
            margin-block-start: 32px;
        }
        // @media (max-width: $xs) {
        //     margin-block-start: 48px;
        // }
    }
}

hr {
    background-color: var(--color-separator);
    margin-block: 10%;
}

.benefits {
    p.full-screen {
        color: white;
        span {
            color: var(--color-button-primary);
        }
    }
    .marquee-container {
        margin-top: 1em;
        // @media (min-width: $xl) {
            
        // }
        // @media (min-width: $lg) and (max-width: $xl) {
        //     margin-top: -38px;
        // }

        // @media (min-width: $md) and (max-width: $lg) {
        //     margin-top: -34px;
        // }
        // @media (min-width: $sm) and (max-width: $md) {
        //    margin-top: -20px;
        // }
        // @media (min-width: $xs) and (max-width: $sm) {
        //     margin-top: 1px;
        // }
        // @media (max-width: $xs) {
        //     margin-top: 15px;
        //     margin-bottom: 0;
        // }

        .marquee {
            @media (max-width: $md) {
                font-size: 48px;
            }
            @media (max-width: $xs) {
                margin-bottom: 39px;
            }

        }

    }
    .slider-cards {
        margin-block: 120px 100px;

        @media (min-width: $xl) {
            margin: 14px 0 100px 0;
        }
        @media (min-width: $lg) and (max-width: $xl) {
            margin-block: 30px 100px;
        }

        @media(max-width: 1440px) {
            margin-block: 50px;
            margin: 0 0 50px 0;
        }
        @media(max-width: 1024px) {}
        @media(max-width: 884px) {
            margin-block: 48px 32px;
        }
        @media(max-width: 768px) {
            margin-block: 20px 32px;
        }
        @media (max-width: 448px) {}
        @media(max-width: 376px) {}

        .swiper-slide {
            &:first-of-type {
                .card {

                    margin-left: 0 !important;
                }
            }
        }

        .card {
            position: relative;
            background: var(--color-button-primary);
            border-radius: 10px;

            // height: 460px;

            margin-inline: 15px;
            @media (min-width: $xl) {
                // height: 765px;
                height: 760px;
                padding: 0;
                border-radius: 20px;

            }
            @media (min-width: $lg) and (max-width: $xl) {
                height: 550px;
                border-radius: 15px;
            }

            @media (min-width: $md) and (max-width: $lg) {
                height: 400px;
            }
            @media (min-width: 884px) and (max-width: $md) {
                height: 364px;
            }
            // @media (min-width: $xs) and (max-width: 884px) {
            @media (max-width: 884px) {

                height: 287px;
            }
            @media (max-width: $xs) {
                // height: 287px;
                height: 76.6vw;
            }

            // @media(max-width: 768px) {}
            // @media (max-width: 448px) {}
            // @media(max-width: 376px) {}

            .card-picture {
                @media (min-width: $sm) and (max-width: $md) {
                    top: 35% !important;
                }
                @media (min-width: $xs) and (max-width: $sm) {
                    top: 20.5% !important;
                }
                @media (max-width: $xs) {
                    top: 20.5% !important;
                }
            }


            img:first-of-type {
                // width: 100%;
                aspect-ratio: 2/1;
                object-fit: cover;
                border-radius: 20px;
            }
            .text-container {
                height: 100%;
                padding-top: 0;

                .icon {
                    border-radius: 10px;
                    position: absolute;
                    width: 150px; height: 150px;
                    // top: -35%; 
                    top: -44%;
                    left: 98px;

                    @media (min-width: $lg) and (max-width: $xl) {
                        width: 110px;
                        height: 110px;
                    }

                    @media(max-width: 1440px) {
                        width: 75px; 
                        height: 75px;
                        // top: -30%; 
                        top: -43%;
                        left: 50px;
                    }
                    @media(max-width: 1024px) {
                        top: -35%;
                        left: 30px;
                        
                    }
                    @media(max-width: 884px) {
                        width: 48px; height: 48px;
                        top: 96px; left: 30px;
                        top: -40%;
                    }
                    @media(max-width: 768px) {
                        top: -48%;
                    }
                    @media (max-width: 448px) {}
                    @media(max-width: 376px) {}
                }
                p {
                    color: white;
                    padding-inline: 100px;
                    padding-block-start: 60px;
                    line-height: 20px;
                    font-weight: 400;
                    padding-bottom: 0;


                    @media (min-width: $xl) {
                        font-size: 64px;
                        line-height: 58px;
                        // padding-bottom: 0;
                        margin: -36px 0 0 0;
                    }
                    @media (min-width: $lg) and (max-width: $xl) {
                        font-size: 42px;
                        // width: 90%;
                        margin: -45px 0 0 0;
                        line-height: 48px;
                        width: 95%;
                        padding-inline: 80px;

                    }


                    @media(max-width: 1440px) {
                        padding-block-start: 30px;
                        padding-inline: 50px;
    
                        font-size: 32px;
                        line-height: 29px;
                        width: 85%;
                        // padding-bottom: 15%;
                        // padding-bottom: 0;
                        margin: -15px 0 0 0;
    
                    }
                    @media (max-width: $md) {
                        line-height: 22px;
                        font-size: 24px;
                    }
                    @media(max-width: 1024px) {
                        padding-inline: 30px;
                        margin: 18px 0 0 0;
                        width: 100%;

                    }
                    @media(max-width: 884px) {
                        padding-block-start: 0px;
                    }
                    // @media(max-width: 376px) {}

                    @media (min-width: $xs) and (max-width: $sm) {
                        padding-inline: 27px;
                        // margin: -16px 0 0 0;
                        margin: 13px 0 0 0;
                        width: 75%;                    
                    }
                    @media (max-width: $xs) {
                        padding-inline: 27px;
                        margin: 13px 0 0 0;
                        width: 75%;     
                    }
                }
            }

        }
    }
    .counter-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        width: 90%;
        margin-inline: auto;
        margin-block-start: 32px;

        border-bottom: 1px solid $gray;
        padding-bottom: 95px;


        @media (min-width: $xl) {
            margin-top: 85px;
            width: 92%;
            padding-bottom: 185px;

        }
        @media (min-width: $lg) and (max-width: $xl) {
            margin-top: 65px;
            width: 92%;
            padding-bottom: 130px;
        }
        @media (min-width: $md) and (max-width: $lg) {
            margin-block-start: 45px;
            padding-bottom: 95px;
            width: 86%;
        }
        @media (min-width: $sm) and (max-width: $md) {
            margin-block-start: 47px;
            padding-bottom: 88px;
        }
        @media(max-width: 884px) {
            flex-direction: column;
            align-items: stretch;
            justify-content: start;
            gap: 20px;
            margin-block-start: 5px;

        }
        @media (min-width: $xs) and (max-width: $sm) {
            margin-block-start: 17px;

            width: 92%;
            gap: 14px;
            padding-bottom: 63px;
        }
        @media (max-width: $xs) {
            margin-block-start: -71px;

            gap: 14px;
            padding-bottom: 62px;

        }


        .counter {
            flex-shrink: 0;
            width: auto;
            border: none;

            font-family: 'Cakra';
            font-weight: 400;

            @media (min-width: $xl) {
                font-size: 96px;
                line-height: 77px;
                padding: 0;
            }
            @media (min-width: $lg) and (max-width: $xl) {
                font-size: 80px;
                line-height: 63px;
            }
            @media (min-width: $md) and (max-width: $lg) {
                font-size: 64px;
                line-height: 51px;
                padding: 0;
                margin-top: -6px;
            }
            @media (min-width: $sm) and (max-width: $md) {
                font-size: 48px;
                line-height: 38px;
                padding: 0;

            }
            @media (max-width: $sm) {
                font-size: 32px;
                line-height: 26px;
            }
            @media (min-width: $xs) and (max-width: $sm) {
                margin-top: -7px;
            }


            @media(max-width: 884px) {
                display: flex;
                justify-content: center;
            }
            // @media (max-width: $sm) {}
            // @media (max-width: $xs) {}

            p {
                color: white;
                span:nth-of-type(1) {
                    color: var(--color-button-primary);
                }
                span:nth-of-type(2) {
                    margin-inline: 150px;
                    @media(max-width: 1440px) {
                        margin-inline: 100px;
                    }
                    @media(max-width: 1024px) {
                        margin-inline: 80px;
                    }
                    @media(max-width: 884px) {
                        margin-inline: 60px;
                    }
                    @media (max-width: $xs) {
                        margin-inline: 15vw 27vw;
                    }
                }
                @media(max-width: 884px) {
                    text-align: center;
                }
                @media (min-width: $xs) and (max-width: $sm) {
                    letter-spacing: 30px;
                    margin: 20px 0;
                }
                // @media (max-width: $xs) {}
            }
        }
        button {
            background-color: white;
            flex-grow: 1; 
            max-width: 66.6%;


            @media(max-width: 884px) {
                max-width: unset;
            }
        }
    }
}

.slider-cards-partner {
  @media (min-width: $xl) {
      margin-top: -20px;
  }
}

.support {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    gap: 64px;
    margin-block-end: 200px;

    @media (min-width: $xl) {
        margin: auto 90px;
        margin-top: 180px;
        padding-bottom: 175px;
        gap: 235px;

    }
    @media (min-width: $xl) and (max-width: ($xl + $xl/6)) {
        gap: 155px;
    }

    @media(max-width: 2000px) {
        margin: auto 95px;
        margin-top: 125px;
        gap: 115px;

    }
    @media (min-width: $lg) and (max-width: ($xl - $xl/6)) {
        gap: 85px !important;
    }


    @media(max-width: 1440px) {
        margin: auto 100px;
        margin-top: 100px;
        gap: 113px;

    }
    @media (min-width: ($lg - $lg/6)) and (max-width: ($lg - $lg/8)) {
        gap: 90px !important; 
    }
    @media (min-width: ($lg - $lg/4)) and (max-width: ($lg - $lg/6)) {
        gap: 75px !important; 
        margin: auto 72px;
        margin-top: 100px;
    }
    @media (min-width: $md) and (max-width: ($lg - $lg/4)) {
        gap: 50px !important; 
        margin: auto 72px;
        margin-top: 100px;
    }
    @media(max-width: 1024px) {
        flex-direction: column;
        align-items: stretch;
        justify-content: start;
        gap: 47px;

        margin: auto 50px;
        margin-top: 69px;

    }

    @media (min-width: $xs) and (max-width: $sm) {
        margin: auto 32px;
        margin-top: 61px;
    }

    @media(max-width: 884px) {
        gap: 32px;
    }
    @media(max-width: 376px) {
        margin: auto 16px;
        margin-top: 53px;

    }

    aside {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: start;
        gap: 32px;

        max-width: 30%;
        @media (min-width: $xl) {
            gap: 48px;
            width: 27%;
        }
        @media (min-width: $lg) and (max-width: $xl) {
            max-width: 28%;

        }




        @media(max-width: 1440px) {
            max-width: 24.5%;
            gap: 23px;
        }

        @media(max-width: 1024px) {
            max-width: unset;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            // margin-block-end: 5%;
            margin-block-end: 0%;
            gap: unset;
        }
        @media(max-width: 884px) {
        // @media(max-width: 768px) {
            flex-direction: column;
            align-items: start;
            justify-content: start;
            // gap: 16px;
            gap: 29px;
        }
        @media (max-width: $xs) {
            gap: 30px;
        }


        p {
            color: white;

            //title
            @extend %title;

            word-wrap: break-word;
            margin: 0;
            width: 81%;

            @media (min-width: $xl) {
                font-size: 96px;
                line-height: 76.8px;
                margin-top: 12px;
                width: 100%;
            }
            


            @media (min-width: $md) and (max-width: $lg) {
                width: 81%;
            }
            @media (min-width: $xs) and (max-width: $sm) {
                width: 49%;
            }
            @media (max-width: 448px) {
                width: 100%;
            }
            // @media (max-width: $xs) {}

        }
        button {
            width: 100%;

            @media (min-width: $sm) and (max-width: $md) {
                width: 38%;
            }
            @media (min-width: $xs) and (max-width: $sm) {
                width: 49%;
            }
            @media (max-width: 448px) {
                width: 100%;
            }

            //

        }
    }
    .cards {
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        gap: 100px;

        @media (min-width: $xl) {
            gap: 61px;
        }
        @media (min-width: $lg) and (max-width: $xl) {
            gap: 55px;
        }



        @media(max-width: 1440px) {
            gap: 50px;
        }
        @media(max-width: 1024px) {}
        @media(max-width: 884px) {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 16px;
        }
        @media(max-width: 884px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: max-content;
            gap: 20px 18px;
            margin-top: -5px;
        }
        @media (max-width: 448px) {
            grid-template-columns: 1fr;
            gap: 24px 18px

        }
        @media(max-width: 376px) {
            margin-top: -2px;
            gap: 27px 18px
        }


        .card {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: space-between;
            width: 100%;

            @media (min-width: $xl) {
                // height: 570px;
                height: 610px;
            }
            @media (min-width: $lg) and (max-width: $xl) {
                height: 500px !important;
            }
            @media (min-width: $sm) and (max-width: $lg) {
                height: 300px;
            }
            @media (min-width: ($lg - $lg/5)) and (max-width: ($lg - $lg/7)) {
                height: 380px;
            }
            @media (min-width: $md) and (max-width: ($lg - $lg/5)) {
                height: 400px;
            }

            @media(max-width: 884px) {
                flex-direction: column;
                width: 48%;
                gap: 31px !important;

                height: 100%;
                justify-self: start;
                align-items: start;
                justify-content: start;
                width: 100%
            }

            @media(max-width: 448px) {
                width: 100%;
            }

            &:nth-of-type(2n) {
                @media (min-width: $xl) {
                    gap: 206px;

                    li {
                        max-width: 505px !important;
                    }
                }
                @media (min-width: $xl) and (max-width: ($xl + $xl/6)) {
                    gap: 6vw;
                }
                @media (min-width: ($xl - $xl/4)) and (max-width: $xl) {
                    // gap: 155px;
                    gap: 6vw;
                }
                @media (min-width: $lg) and (max-width: ($xl - $xl/4)) {
                    // gap: 155px;
                    gap: 5vw !important;
                }
                @media (min-width: $md) and (max-width: $lg) {
                    gap: 110px;
                    // gap: 7.65vw;

                }
                @media (min-width: ($lg - $lg/6)) and (max-width: ($lg - $lg/12)) {
                    gap: 80px !important; 
                }
                @media (min-width: ($lg - $lg/4)) and (max-width: ($lg - $lg/5)) {
                    gap: 75px !important; 
                }
                @media (min-width: $md) and (max-width: ($lg - $lg/4)) {
                    gap: 55px !important; 
                }
                @media (min-width: $sm) and (max-width: $md) {
                    gap: 84px;
                    li {
                        padding-right: 45px;

                    }
                }
                // @media (min-width: $xs) and (max-width: $sm) {}
                // @media (max-width: $xs) {}
            }
            &:nth-of-type(2n+1) {
                @media(max-width: 884px) {
                    flex-flow: column-reverse;

                }

                // @media (min-width: $xs) and (max-width: $sm) {
                //     flex-flow: column-reverse;
                // }

                //
            }
    
            .details {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: space-between;

                @media(max-width: 884px) {
                    justify-content: start;
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: start;

                    // @media (min-width: $xl) {
                    //     gap: 7px;
                    // }
                    @media (min-width: $md) and (max-width: $lg) {
                        margin-top: 1px;
                    }
                    @media(max-width: 884px) {
                        justify-content: start;
                    }
                }
                img {
                    width: 67px; height: 72px;
                    margin-block-end: 200px;

                    @media (min-width: $xl) {
                        margin-block-end: 25px;
                    }
                    @media(max-width: 2000px) {
                        // margin-block-end: 40px;
                        margin-block-end: 31px;
                        width: 54px; height: 57px;
                    }
                    @media(max-width: $MYlg) {
                        width: 38px; height: 38px;
                        object-fit: contain;
                    }
                    @media(max-width: $MYsm) {
                        margin-block-end: 32px;
                    }
                }
                p {
                    font-family: 'Cakra';
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 26px;

                    color: var(--color-button-primary);
                    margin-block-end: 50px;
                    margin-top: 0;


                    @media (min-width: $xl) {
                        font-size: 64px;
                        line-height: 51px;
                    }
                    @media (min-width: $xl) and (max-width: ($xl + $xl/6)) {
                        font-size: 58px;
                        line-height: 45px;
                    }

                    @media (min-width: $lg) and (max-width: $xl) {
                        font-size: 42px;
                        line-height: 39px;
                        width: 85%;
                    }
                    @media(max-width: 1440px) {
                        margin-top: 0;
                        margin-block-end: 25px;
                        font-size: 32px;
                        line-height: 26px;

                    }
                    @media(max-width: 1024px) {
                        margin-block-end: 32px;
                    }
                    @media(max-width: 884px) {
                        margin-block-end: 25px;
                    }
                    @media(max-width: 768px) {
                        margin-block-end: 25px;

                    }
                    @media (max-width: 448px) {}
                    @media(max-width: 376px) {}
                }
                ul {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    margin-top: -10px;
                    list-style-position: outside;

                    @media (min-width: $sm) and (max-width: $md) {
                        margin-top: 1px;
                    }

                    li {
                        margin-block: 16px;
                        color: #FFF;
                        margin-top: -3px;

                        font-family: Manrope;
                        font-weight: 400;
                        margin-left: 32px;

                        @media (min-width: $xl) {
                            font-size: 32px;
                            line-height: 42px;
                            margin-top: 4px;
                            max-width: 670px;
                            margin-left: 60px;

                        }
                        @media (min-width: $xl) and (max-width: ($xl + $xl/6)) {
                            font-size: 24px;
                        }
                        @media (min-width: $lg) and (max-width: $xl) {
                            margin-left: 45px;
                            font-size: 20px;

                        }




                        @media (min-width: $md) and (max-width: $lg) {
                            margin: 8px 0;
                            margin-left: 32px;

                            line-height: 23px;
                        }
                        @media (min-width: $sm) and (max-width: $md) {
                            margin-bottom: 11px;

                            max-width: 347px;
                            margin-left: 31px;

                        }


                        @media(max-width: 884px) {
                            margin-block: 8px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }

                        // &::marker {

                        position: relative;



                        &::before {

                            position: absolute;
                            // text-align: center;


                            content: "+";
                            color: var(--color-button-primary);
                            margin-inline-end: 32px;

                            margin-inline-end: 23px;
                            margin-inline-end: 18px;
                            font-size: 24px;

                            left: -32px;

                            @media (min-width: $xl) {
                                margin-inline-end: 34px;
                                font-size: 44px;
                                line-height: 30px;
                                top: 9px;
                                left: -60px;
                                
                                // top: calc(50% - 17px);
                            }
                            
                            @media (min-width: $lg) and (max-width: $xl) {
                                margin-inline-end: 32px;
                                left: -45px;
                            }



                            @media (min-width: $md) and (max-width: $lg) {
                                margin-inline-end: 23px;
                                margin-inline-end: 18px;
                                font-size: 24px;
                                left: -32px;
                            }
                            @media (min-width: $sm) and (max-width: $md) {
                                font-size: 20px;
                                left: -31px;
                            }
                            @media (max-width: 448px) {
                                top: -5px;
                            }


                            // @media (min-width: $xs) and (max-width: $sm) {}
                            // @media (max-width: $xs) {}


                        }
                    }
                }
            }
            img {
                border-radius: 10px;
                width: 50%;
                object-fit: cover;

                @media (min-width: $xl) {
                    border-radius: 20px;
                }
                @media (min-width: $lg) and (max-width: $xl) {
                    border-radius: 15px;
                    // margin-block-end: 55px;

                }


                @media(max-width: 884px) {
                    width: 100%;
                }
                @media(max-width: 884px) {
                    // margin-block-end: 32px;
                    margin-block-end: 0;
                    height: 252px;

                }
                @media (max-width: 448px) {}
                @media(max-width: 376px) {}
            }
        }
    }
}
.supportWindow {
    background-color: rgba(19, 19, 19, 1);
    margin: auto 100px;
    margin-top: 100px;
    padding-bottom: 100px;

    @media (min-width: $xl) {
        margin-top: 0;
        padding-bottom: 0;
    }
    @media (min-width: $md) and (max-width: ($lg - $lg/6)) {
        margin: auto 72px;
        margin-top: 100px;
    }
    
    @media (min-width: $sm) and (max-width: $md) {
        margin: auto 50px;
        margin-top: 81px;
        padding-bottom: 80px;
    }
    @media(max-width: 884px) {
        margin: auto 32px;
        margin-top: 57px;
        padding-bottom: 30px;


    }
    @media (max-width: 448px) {
        margin: auto 16px;
        margin-top: 59px;
        padding-bottom: 31px;

    }
    // @media (min-width: $xs) and (max-width: $sm) {}
    // @media (max-width: $xs) {}


    .supportFormWrapper {
    
        display: flex;
        flex-direction: row;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 10px;
        padding: 30px 52px 53px 52px;
        gap: 20px;

        // ну ведь это откуда-то же взялось (привет предыдущим разработчикам), и это я ещё наркоман...
        // @media (min-width: $lg) {
        //     display: none;
        // }
        @media (min-width: $xs) and (max-width: $sm) {
            padding: 14px 32px 33px 32px;
        }
        @media (max-width: 448px) {
            flex-direction: column;
            padding: 25px 17px 32px 17px;

        }
        // @media (max-width: $xs) {}

        .firstColumn {
            display: flex;
            flex-direction: column;
            gap: 9px;

            @media(max-width: 884px) {
                gap: 11px;
            }
            // @media (max-width: 448px)
            @media (min-width: 448px) and (max-width: ($sm - $sm/4)) {
                max-width: 28vw;
            }
            @media (max-width: 448px) {
                padding: 0 15px;

            }
    
            p {
                @extend %title;
                margin: 15px 0;

                @media (min-width: $sm) and (max-width: $md) {
                    font-size: 64px;
                    line-height: 51px;
                }
                @media(max-width: 884px) {
                    margin: 15px 0 0 0;

                }
                @media (min-width: 448px) and (max-width: 884px) {
                    width: 100%;
                    overflow-wrap: break-word;
                }
                // @media (max-width: 448px) {}
                // @media (min-width: $xs) and (max-width: $sm) {}
                // @media (max-width: $xs) {}


            }
            .text {
                @media (min-width: $md) and (max-width: $lg) {
                    width: 40%;
                }
                @media (min-width: $sm) and (max-width: $md) {
                    width: 50%;
                }
                // @media(max-width: 884px)
                @media (max-width: 448px) {
                    width: 90%;
                }


                // @media (min-width: $xs) and (max-width: $sm) {}
                // @media (max-width: $xs) {}
            }
    
        }
    
    
        .supportForm {
            margin-top: 25px;
            width: 56%;

            @media (min-width: $md) and (max-width: $lg) {
                width: 56%;
            }
            @media (min-width: $sm) and (max-width: $md) {
                width: 68%;
            }
            @media (min-width: $xs) and (max-width: $sm) {
                width: 91%;

            }
            @media (max-width: 448px) {
                width: 100%;
                margin-top: 6px;

            }

            .ant-form {
                display: flex;
                flex-direction: column;
                gap: 21px;

                .ant-input {
                    @extend %formElement;
                }
                @extend %label;
                @extend %checkbox;
                .ant-checkbox-wrapper {
                    span {
                        @media (min-width: $md) and (max-width: $lg) {
                            padding-inline-start: 10px;
                        }
                    }
                }

                [data-id="heal-me"] {
                    @extend %prefixPhone;
                    #phone {
                        border-bottom-left-radius: 0;
                        border-left: 0;
                    }
                }

                .ant-form-item {
                    margin-bottom: 0;

                    &:nth-of-type(4n) {
                        @media (min-width: $sm) and (max-width: $md) {
                            margin-top: -1px;
                        }
                        @media (min-width: $xs) and (max-width: $sm) {
                            margin-top: -9.5px;
                        }
                        @media(max-width: 884px) {
                            margin-top: -9.5px;
                        }
                        @media (max-width: 448px) {
                            margin-top: 2px;
                        }

                        .ant-checkbox-wrapper span {
                            @media (min-width: $sm) and (max-width: $md) {
                                line-height: 18px;
                            }
                        }
                    }
                }
            }
            button.ant-btn {
                @extend %greenButton;
                width: 50%;
                margin-top: 9px;

                @media (min-width: $sm) and (max-width: $md) {
                    width: 75%;
                    margin-top: -6px;
                }
                @media (min-width: $xs) and (max-width: $sm) {
                    width: 82%;
                    margin-top: -17px;
                }
                @media (max-width: 448px) {
                    width: 100%;
                    margin-top: 14px;
                }
            }
        }
    }
}

[data-id="partner-modal"] {
    form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        gap: 17px;

        .ant-form-item {
            margin-bottom: 0;
    
            @extend %label;
    
            @extend %checkbox;
    
            @extend %prefixPhone;
    
            &:nth-of-type(6n+5) {
                @media (min-width: $xl) {
                    margin-top: -34px;
                }
                @media (min-width: $xs) and (max-width: $sm) {
                    width: 50%;
                }
            }
    
            &:nth-of-type(6n+6) {
                @media (min-width: $xl) {
                    margin-top: -57px;
                }
                @media (min-width: $lg) and (max-width: $xl) {
                    margin-top: -25px;
                }
                @media (min-width: $md) and (max-width: $lg) {
                    margin-top: -14px;
                }
                @media (min-width: $sm) and (max-width: $md) {
                    margin-top: -12px;
                }
                @media (min-width: $xs) and (max-width: $sm) {
                    margin-top: -35px;
                }
                @media (max-width: $xs) {
                    margin-top: -27px;
                }
            }
    
            .ant-input, .ant-select {
                @extend %formElement;
            }
    
            textarea {
                padding: 10px 15px;
            }
            @extend %select;
        }

        #phone {
            border-left: none;
            border-bottom-left-radius: 0;
        }
    
        [type=submit] {
            @extend %greenButton;
            width: 49%;
    
            @media (min-width: $xl) {
                margin-top: -20px;
            }
            @media (min-width: $lg) and (max-width: $xl) {
                margin-top: 1px;
            }
            @media (min-width: $md) and (max-width: $lg) {
                margin-top: 1px;
            }
            @media (min-width: $sm) and (max-width: $md) {
                width: 43.5%;
                margin-top: -1px;
            }
            @media (min-width: $xs) and (max-width: $sm) {
                margin-top: -1px;
            }
            @media (max-width: 448px) {
                width: 100%;
            }
            @media (max-width: $xs) {
                margin-top: 0.5px;
            }
        }
    }
}
