.photo-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 16px;

	img {
		width: 100%;
		border-radius: 20px;
		@media (max-width: 1440px) {
			border-radius: 10px;
		}
	}

	@media (min-width: 884px) {
		grid-template-rows: 1fr 1fr;
		img:first-of-type {
			grid-row: 1 / 3;
			grid-column: 1 / 3;
		}
	}
	@media (max-width: 448px) {
		grid-template-rows: 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr;
	}
}
