@import './variables.scss';

@mixin flamingoPositionMedia($w) {
  // @media (min-width: $xl) {}
// @media (min-width: $lg) and (max-width: $xl) {}
@media (min-width: $md) and (max-width: $lg) {
  // left: 100vw - $containerLg - $w;
  left: 35.5vw;



  // left: $lg - $containerLg*2 - $w;
  // left: $lg - $containerLg - $w/2;

}
// @media (min-width: $sm) and (max-width: $md) {}
// @media (min-width: $xs) and (max-width: $sm) {}
// @media (max-width: $xs) {}
}

@mixin areaMedia($h) {
  // @media (min-width: $xl) {}
  // @media (min-width: $lg) and (max-width: $xl) {}
  @media (min-width: $md) and (max-width: $lg) {
    height: $h;
  }
  @media (min-width: $sm) and (max-width: $md) {
    height: $h*0.7;
  }
  @media (min-width: $xs) and (max-width: $sm) {
    height: $h*0.4;
  }
  @media (max-width: $xs) {
    height: $h*0.2;
  }
}

@mixin marginTopMedia($m) {

  @media (min-width: $xl) {
    margin-top: $m*1.2;
  }
  @media (min-width: $lg) and (max-width: $xl) {
    margin-top: $m*1.1;
  }
  @media (min-width: $md) and (max-width: $lg) {
    margin-top: $m;
  }
  @media (min-width: $sm) and (max-width: $md) {
    margin-top: $m*0.9;
  }
  @media (min-width: $xs) and (max-width: $sm) {
    margin-top: $m*0.5;
  }
  @media (max-width: $xs) {
    margin-top: $m *0.3;
  }
}

@mixin heightWidthImage($h) {
  height: $h*0.7;
  width: 1.4723*$h*0.7;
  // width: $h/0.7;
}

.tg-upper {
  text-transform: uppercase;
}
.tg-authentic {
	font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Cakra";
	font-style: normal;
    font-weight: 400;
	color: #131313;
	line-height: 80%;
}
.tg-large {
	font-size: 64px;
	@media (max-width: 1440px) {
		font-size: 32px;
	}
	@media (max-width: 1024px) {
		font-size: 24px;
	}
}
.tg-huge {
    font-size: 128px;

	@media (max-width: 1440px) {
		font-size: 64px;
	}
	@media (max-width: 1024px) {
		font-size: 48px;
	}
	@media (max-width: 884px) {
		font-size: 40px;
	}
	@media (max-width: 448px) {
		font-size: 32px;
	}
}
.tg-gigantic {
	font-size: 256px;

	@media (max-width: 1440px) {
		font-size: 128px;
	}
	@media (max-width: 1024px) {
		font-size: 64px;
	}
}
.tg-center {
	text-align: center;
}
.tg-align-right {
	text-align: right;
}

.tg-link {
	color: var(--color-button-primary);
}
